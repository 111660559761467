import React from 'react';

const Unwatch = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M18 10.5C13 10.5 8.73 13.61 7 18C8.73 22.39 13 25.5 18 25.5C23 25.5 27.27 22.39 29 18C27.27 13.61 23 10.5 18 10.5ZM18 23C15.24 23 13 20.76 13 18C13 15.24 15.24 13 18 13C20.76 13 23 15.24 23 18C23 20.76 20.76 23 18 23ZM18 15C16.34 15 15 16.34 15 18C15 19.66 16.34 21 18 21C19.66 21 21 19.66 21 18C21 16.34 19.66 15 18 15Z"
        fill="#454550"
      />
    </svg>
  );
};

export default Unwatch;
