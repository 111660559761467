import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { HOSPITAL_TYPE_API } from './apiUrls';

import { IHospitalTypeResponse } from './interface';

export const fetchHospitalTypes = createAsyncThunk<IHospitalTypeResponse, any, { rejectValue: string }>(
  'hospitalType/fetch',
  async (thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(HOSPITAL_TYPE_API);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
