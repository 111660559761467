import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/index';

import { fetchCurrencies, fetchAllCurrencies, createCurrency } from './currenciesAPIs';
import { ICurrencyState } from './interface';

const initialState: ICurrencyState = {
  isFetching: false,
  isSuccess: false,
  isCreated: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  countryOptions: [],
  currencyOptions: [],
  currencyWithoutCountryNameOptions: [],
};

export const currenciesSlice = createSlice({
  name: 'currenciesTypes',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    requestEditHoliday(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCurrencies.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        const dataList = payload.data.map((item: any, index: number) => ({ index, ...item }));
        state.dataList = dataList;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchCurrencies.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createCurrency.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createCurrency.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createCurrency.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchAllCurrencies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.countryOptions = payload.Data.map((v) => ({ name: v.CountryCode, value: v.Id }));
        state.countryOptions.unshift({ name: 'Select', value: -1 });

        state.currencyOptions = payload.Data.map((v) => ({ name: `${v.IsoCode} - ${v.CountryName}`, value: v.Id }));
        state.currencyOptions.unshift({ name: 'Select', value: -1 });

        state.currencyWithoutCountryNameOptions = payload.Data.map((v) => ({ name: `${v.IsoCode}`, value: v.Id }));
        state.currencyWithoutCountryNameOptions.unshift({ name: 'Select', value: -1 });
      })
      .addCase(fetchAllCurrencies.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditHoliday } = currenciesSlice.actions;
export const currenciesSelector = (state: RootState) => state.currencies;

export default currenciesSlice.reducer;
