import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX, DETAIL_DIAGNOSTIC } from './apiUrls';
import { IEmployeeMedicalHistoryResponse } from './interface';

export const fetchEmployeeMedicalHistoryByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/medial-history`, { params: data });
};

export const fetchEmployeeMedicalHistory = createAsyncThunk<IEmployeeMedicalHistoryResponse, any, { rejectValue: string }>(
  'EmployeeMedicalHistory/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchEmployeeMedicalHistoryByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getDetailDiagnostic = (data: any) => {
  return httpRequest.insurance.get(`${DETAIL_DIAGNOSTIC}/${data}`);
};
