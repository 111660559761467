import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  toolbarChildren: {
    '& div:first-of-type': {
      display: 'none',
    },
    '& *:not(:last-child)': {
      flex: 1,
    },
  },
  searchInput: {
    background: 'red',
  },
}));

export default useStyles;
