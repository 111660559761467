import React from 'react';

const GenderIcon = () => {
  // @ts-ignore
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M8.25 11C8.25 11.69 7.69 12.25 7 12.25C6.31 12.25 5.75 11.69 5.75 11C5.75 10.31 6.31 9.75 7 9.75C7.69 9.75 8.25 10.31 8.25 11ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10ZM8.66 2.12C10.06 4.44 12.6 6 15.5 6C15.96 6 16.41 5.95 16.84 5.88C15.44 3.56 12.9 2 10 2C9.54 2 9.09 2.05 8.66 2.12ZM2.42 7.47C4.13 6.5 5.45 4.92 6.08 3.03C4.37 4 3.05 5.58 2.42 7.47ZM18 10C18 9.22 17.88 8.47 17.67 7.76C16.97 7.91 16.25 8 15.5 8C12.37 8 9.58 6.56 7.74 4.31C6.69 6.87 4.6 8.88 2 9.86C2.01 9.9 2 9.95 2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default GenderIcon;
