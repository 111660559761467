import React from 'react';

const NIBIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19H3V5H21V19ZM4 6H13V13H4V6Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default NIBIcon;
