import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { loginUser } from './authAPIs';
import { IAuthState } from './interface';

const initialState: IAuthState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = authSlice.actions;
export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
