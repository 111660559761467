import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/index';
import { IFamiliesState } from './interface';
import { fetchInsurers, updateInsurer } from './insurerAPI';

const initialState: IFamiliesState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
};

export const insurer = createSlice({
  name: 'insurers',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditInsurer(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsurers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchInsurers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchInsurers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(updateInsurer.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateInsurer.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateInsurer.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditInsurer } = insurer.actions;
export const usersSelector = (state: RootState) => state.users;

export default insurer.reducer;
