import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IEmployeesState } from './interface';
import {
  fetchEmployeeDetail,
  applyImportEmployee,
  createEmployee,
  fetchEmployeeTemplate,
  fetchEmployees,
  undoImportEmployee,
  updateEmployee,
  updateEmployeeTemplate,
} from './employeesAPIs';

const initialState: IEmployeesState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  isCreated: false,
  isDeleted: false,
  employeeDetail: undefined,
  employeeTemplate: undefined,
  isBulkFetching: false,
};

export const employees = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditEmployee(state, action) {
      state.isUpdated = false;
    },
    requestCreateEmployee(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployees.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchEmployeeDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.employeeDetail = payload;
      })
      .addCase(fetchEmployeeDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createEmployee.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createEmployee.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createEmployee.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateEmployee.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateEmployee.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchEmployeeTemplate.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(fetchEmployeeTemplate.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isSuccess = true;
        state.employeeTemplate = payload.Data;
      })
      .addCase(fetchEmployeeTemplate.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateEmployeeTemplate.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(updateEmployeeTemplate.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(updateEmployeeTemplate.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(applyImportEmployee.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(applyImportEmployee.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(applyImportEmployee.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(undoImportEmployee.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(undoImportEmployee.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(undoImportEmployee.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateEmployee, requestEditEmployee, requestClearError } = employees.actions;
export const employeesSelector = (state: RootState) => state.employees;

export default employees.reducer;
