import React from 'react';

const RoundedDeleteIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="29.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M34.6668 26.1852V37.8518H25.3335V26.1852H34.6668ZM32.9168 19.1852H27.0835L25.9168 20.3518H21.8335V22.6852H38.1668V20.3518H34.0835L32.9168 19.1852ZM37.0002 23.8518H23.0002V37.8518C23.0002 39.1352 24.0502 40.1852 25.3335 40.1852H34.6668C35.9502 40.1852 37.0002 39.1352 37.0002 37.8518V23.8518Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default RoundedDeleteIcon;
