import { API_VERSION_2 } from 'configs/constants';

export const PREFIX = `${API_VERSION_2}/Setting/Hospital`;

export const NOT_READ_API = `${PREFIX}Detail/NotRead`;

export const FETCH_HOSPITAL_DETAIL_API = `${API_VERSION_2}/Hospital/detail`;

export const FETCH_ALL_HOSPITAL_API = `${API_VERSION_2}/Hospital/getAllHospital`;
export const FETCH_ALL_HOSPITAL_DEPARTMENT_API = `${API_VERSION_2}/Hospital/getAllDepartment`;
export const FETCH_ALL_HOSPITAL_PERSONNEL_API = `${API_VERSION_2}/Hospital/getAllPersonnel`;

export const MERGE_HOSPITAL_API = `${API_VERSION_2}/Hospital/merge`;
export const HOSPITAL_API = `${API_VERSION_2}/Hospital`;
