import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTableColumn, DataTypes } from './DataTable';
import FilterCellRange from './FilterCellRange';
import FilterCellDateSelect from './FilterCellDateSelect';
import FilterCellAutocomplete from './FilterCellAutocomplete';

export type DataOption = {
  key: any;
  items: DataOption[] | null;
  count?: number;
};

export type FetchOptionProps = {
  group?: string;
  groupSummary?: string;
  totalSummary?: string;
  skip?: number;
  take?: number;
  requireTotalCount?: boolean;
};

export type DataOptionProps = {
  data: DataOption[];
  totalCount?: number;
  groupCount?: number;
  summary?: any[];
};

export type FilterCellProps = Exclude<TableFilterRow.CellProps, 'filter' | 'onFilter' | 'column'> & {
  column: DataTableColumn;
  style?: React.CSSProperties;
  className?: string;
  fetchOption?: (columnName: string, dataTypes: DataTypes, filter: string, option: FetchOptionProps) => Promise<DataOptionProps>;
};

const FilterCell = (props: FilterCellProps) => {
  const {
    column: { name: columnName, dataTypes, hoverAction, optionSource },
    tableColumn: { width: widthColumn },
    filter,
    onFilter,
    filteringEnabled,
    style,
    className,
    fetchOption,
  } = props;

  if (hoverAction) {
    return (
      <TableFilterRow.Cell
        {...props}
        className={`${className} MuiTableCell-hidden`}
        style={{
          display: 'none',
          ...style,
        }}
      />
    );
  }

  if (!filteringEnabled) {
    return <TableFilterRow.Cell {...props} className="MuiTableCell-hidden" />;
  }

  if (fetchOption) {
    if (dataTypes && dataTypes === 'date') {
      return (
        <TableFilterRow.Cell {...props}>
          <FilterCellDateSelect
            columnName={columnName}
            dataTypes={dataTypes}
            width={widthColumn}
            filter={filter}
            onFilter={onFilter}
            fetchOption={fetchOption}
          />
        </TableFilterRow.Cell>
      );
    }
    if (dataTypes && dataTypes === 'price') {
      return (
        <TableFilterRow.Cell {...props}>
          <FilterCellRange
            columnName={columnName}
            dataTypes={dataTypes}
            width={widthColumn}
            filter={filter}
            onFilter={onFilter}
            fetchOption={fetchOption}
          />
        </TableFilterRow.Cell>
      );
    }
    return (
      <TableFilterRow.Cell {...props}>
        <FilterCellAutocomplete
          columnName={columnName}
          dataTypes={dataTypes}
          optionSource={optionSource}
          width={widthColumn}
          filter={filter}
          onFilter={onFilter}
          fetchOption={fetchOption}
        />
      </TableFilterRow.Cell>
    );
  }

  return <TableFilterRow.Cell {...props} />;
};

export default FilterCell;
