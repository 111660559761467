import { createTheme } from '@mui/material/styles';

import light from './light';
import dark from './dark';
import typography from './typography';
import Button from './components/Button';
import { palette } from './colors';

const typeTheme = [light, dark];

const themes = (type: number) =>
  createTheme({
    ...typeTheme[type],
    typography: { ...typography },
    palette: palette,
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.25rem',
          },
        },
      },
      // MuiTypography: {
      //   styleOverrides: {
      //     root: {
      //       color: typeTheme[type].palette.text.primary,
      //     }
      //   }
      // },
      ...Button,
    },
  });

export default themes;
