import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeFamilyResponse, IFamily } from './interface';

export const fetchEmployeeFamilyByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchEmployeeFamily = createAsyncThunk<IEmployeeFamilyResponse, any, { rejectValue: string }>(
  'EmployeeFamily/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchEmployeeFamilyByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createEmployeeFamily = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'EmployeeFamily/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployeeFamily = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'EmployeeFamily/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteEmployeeFamily = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);

export const getAllRelationship = () => {
  return httpRequest.insurance.get(`${PREFIX}/GetAllRelationship`);
};

export const getAllEmployee = () => {
  return httpRequest.insurance.get(`${PREFIX}/GetAllEmployee`);
};
