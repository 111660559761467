import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_COUNTRY_NAMES, PREFIX } from './apiUrls';
import { IFetchArgs, IResponseBrokers, IRequestFetchBrokers, IResponseCountryNames, IRequestUpdateBroker } from './interface';

export const fetchBrokersByFilter = (id: number, data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/?brokerId=${id}`, { params: data });
};

export const fetchBrokers = createAsyncThunk<IResponseBrokers, IRequestFetchBrokers, { rejectValue: string }>(
  'brokers/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchBrokersByFilter(id, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateBroker = createAsyncThunk<IResponseBrokers, IRequestUpdateBroker, { rejectValue: string }>(
  'brokers/update',
  async ({ id, value }, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append('key', id);
      formData.append('values', value);

      const { data } = await httpRequest.insurance.put(`${PREFIX}?brokerId=${id}`, formData, {
        headers: {
          'Content-Type': ' multipart/form-data',
        },
      });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchCountryNames = createAsyncThunk<IResponseCountryNames, IFetchArgs, { rejectValue: string }>(
  'country-names/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_COUNTRY_NAMES);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
