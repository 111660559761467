import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IMerchantState } from './interface';
import { fetchMerchantGrid, createMerchant, updateMerchant, fetchMerchantDetail, changeStateMerchant } from './merchantsAPI';

const initialState: IMerchantState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
  merchantDetail: null,
  // hospitalDetail: null,
};

export const merchants = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditHospital(state, action) {
      state.isUpdated = false;
    },
    requestCreateMerchant(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchAllHospital.pending, (state) => {
    //     state.isFetching = true;
    //   })
    //   .addCase(fetchAllHospital.fulfilled, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isSuccess = true;
    //     state.dataListAll = payload;
    //   })
    //   .addCase(fetchAllHospital.rejected, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isError = true;
    //     state.errMsg = payload || '';
    //   });

    // builder
    //   .addCase(fetchAllHospitalDepartment.pending, (state) => {
    //     state.isFetching = true;
    //   })
    //   .addCase(fetchAllHospitalDepartment.fulfilled, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isSuccess = true;
    //     state.dataListAllDepartment = payload;
    //   })
    //   .addCase(fetchAllHospitalDepartment.rejected, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isError = true;
    //     state.errMsg = payload || '';
    //   });

    // builder
    //   .addCase(fetchAllHospitalPersonnel.pending, (state) => {
    //     state.isFetching = true;
    //   })
    //   .addCase(fetchAllHospitalPersonnel.fulfilled, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isSuccess = true;
    //     state.dataListAllPersonnel = payload;
    //   })
    //   .addCase(fetchAllHospitalPersonnel.rejected, (state, { payload }) => {
    //     state.isFetching = false;
    //     state.isError = true;
    //     state.errMsg = payload || '';
    //   });

    builder
      .addCase(fetchMerchantGrid.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMerchantGrid.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchMerchantGrid.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createMerchant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createMerchant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createMerchant.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateMerchant.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateMerchant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateMerchant.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(changeStateMerchant.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(changeStateMerchant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(changeStateMerchant.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchMerchantDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMerchantDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.merchantDetail = payload;
      })
      .addCase(fetchMerchantDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError, requestCreateMerchant, requestEditHospital } = merchants.actions;
export const usersSelector = (state: RootState) => state.amanshopMerchants;

export default merchants.reducer;
