import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from 'components/Button';
import { DataTable as DataTableComponent, DataTableColumn } from 'components/DataTable';
import { DEFAULT_PAGE_SIZE } from 'configs/constants';
import { fetchAllHospital } from 'state/hospitals/hospitalsAPI';
import { useAppSelector } from 'state/hooks';
import { locationSelector } from 'state/location/locationSlice';
import { fetchCountry } from 'state/location/locationAPIs';
import { getCompaniesByType } from 'state/employers/employersAPIs';
import { IHospitalTypeState } from 'state/hospital-types/interface';
import { hospitalTypesSelector } from 'state/hospital-types/hospitalTypesSlice';
import { fetchHospitalTypes } from 'state/hospital-types/hospitalTypesAPIs';
import { hospitalSelector } from 'state/hospitals/hospitalsSelector';
import AutocompleteHospital from './AutocompleteHospital';

interface IProp {
  dataListError: any[];
}

const ImportHospitalDataTable = forwardRef<any, IProp>(({ dataListError }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countryList } = useAppSelector(locationSelector);
  const { dataListAll } = useAppSelector(hospitalSelector);
  const hospitalTypeSelectors: IHospitalTypeState = useAppSelector(hospitalTypesSelector);
  const hospitalTypes = hospitalTypeSelectors.dataList || [];

  const [dataList, setDataList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [searchValue, setSearchValue] = useState<string>('');
  // const [sortValue, setSortValue] = useState<Sorting[]>([
  //   {
  //     columnName: 'RowIndex',
  //     direction: 'asc',
  //   },
  // ]);
  const [insurers, setInsurers] = useState<any[]>([]);
  const rowChangesRef = React.useRef<object>();
  // const rowAddOrMergeRef = React.useRef<any[]>([]);

  React.useImperativeHandle(ref, () => ({
    getDataChange() {
      const dataChanges: any[] = [];
      const changed: { [key: string]: any } = rowChangesRef.current || {};
      const allDataChange = Object.keys(changed).map((key) => {
        return {
          Id: key,
          ...changed[key],
        };
      });
      dataList.forEach((item) => {
        const data = allDataChange.find((t) => t.Id === item.Id) || {};
        if (data.HospitalName) {
          item.HospitalName = data.HospitalName;
        }
        if (data.HospitalTypeId) {
          item.HospitalTypeId = data.HospitalTypeId;
        }
        if (data.HospitalAddress) {
          item.HospitalAddress = data.HospitalAddress;
        }
        if (data.HospitalCountryId) {
          item.HospitalCountryId = data.HospitalCountryId;
        }
        if (data.InsurerId) {
          item.InsurerId = data.InsurerId;
        }
        if (data.CashlessInpatient !== undefined && data.CashlessInpatient !== null) {
          item.CashlessInpatient = data.CashlessInpatient;
        }
        if (data.CashlessOutpatient !== undefined && data.CashlessOutpatient !== null) {
          item.CashlessOutpatient = data.CashlessOutpatient;
        }
        // item.IsMerge = data.IsMerge;
        // item.MergeHospitalId = data.MergeHospitalId;
        dataChanges.push(item);
      });
      return dataChanges;
    },
  }));

  useEffect(() => {
    dispatch(fetchCountry({}));
    dispatch(fetchAllHospital({}));
    dispatch(fetchHospitalTypes({}));
    getCompaniesByType(1).then(({ data }) => {
      setInsurers(data.Data);
    });
    setCurrentPage(0);
    if (dataListError.length > 0) {
      setDataList(dataListError.filter((item) => item.IsExisted === false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListError]);

  const [tableColumnExtensions] = useState<Table.ColumnExtension[]>([
    {
      columnName: 'Index',
      width: 50,
    },
    {
      columnName: 'HospitalName',
      width: 300,
    },
    {
      columnName: 'HospitalTypeId',
      width: 200,
    },
    {
      columnName: 'HospitalAddress',
      width: 300,
    },
    {
      columnName: 'HospitalCountryId',
      width: 200,
    },
    {
      columnName: 'InsurerId',
      width: 300,
    },
    {
      columnName: 'CashlessInpatient',
      width: 200,
    },
    {
      columnName: 'CashlessOutpatient',
      width: 200,
    },
    {
      columnName: 'actions',
      width: 300,
      align: 'right',
    },
  ]);

  const columns: DataTableColumn[] = [
    {
      name: 'Index',
      title: '',
    },
    {
      name: 'HospitalName',
      title: t('settings.hospitals.facility'),
      dataTypes: 'string',
      required: true,
    },
    {
      name: 'HospitalTypeId',
      title: t('settings.hospitals.type'),
      dataTypes: 'number',
      required: true,
      optionSource: hospitalTypes.map((item) => ({ value: item.Id, label: item.Name, data: item })),
    },
    {
      name: 'HospitalAddress',
      title: t('settings.hospitals.address'),
      dataTypes: 'string',
      required: true,
    },
    {
      name: 'HospitalCountryId',
      title: t('settings.hospitals.country'),
      dataTypes: 'number',
      required: true,
      optionSource: countryList.map((item) => ({ value: item.Id, label: item.CountryName, data: item })),
    },
    {
      name: 'InsurerId',
      title: t('settings.hospitals.insurance_network_insurer'),
      dataTypes: 'number',
      required: true,
      optionSource: insurers.map((item) => ({ value: item.CompanyId, label: item.CompanyName, data: item })),
    },
    {
      name: 'CashlessInpatient',
      title: t('settings.hospitals.insurance_network_cashless_inpatient'),
      dataTypes: 'boolean',
    },
    {
      name: 'CashlessOutpatient',
      title: t('settings.hospitals.insurance_network_cashless_outpatient'),
      dataTypes: 'boolean',
    },
    {
      name: 'actions',
      // title: t('common.actions'),
      title: '  ',
      rightAction: {
        width: 300,
        component: (props) => {
          let defaultValue = dataListAll.find((item) => item.Id === props?.row?.MergeHospitalId);
          return (
            <div>
              <RadioGroup defaultValue={props?.row?.IsMerge ? '2' : '1'} onChange={(e) => handleChangeRadio(e, props?.row)}>
                <FormControlLabel
                  value="1"
                  control={<Radio style={{ padding: '2px 10px' }} />}
                  label={t('settings.hospitals.tableActions.add_new')}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio style={{ padding: '2px 10px' }} />}
                  label={t('settings.hospitals.tableActions.merge_with')}
                />
                {props?.row?.IsMerge && (
                  <div style={{ marginLeft: '22px' }}>
                    <AutocompleteHospital
                      defaultValue={defaultValue}
                      options={dataListAll}
                      onChange={(event: any, newValue: any | null) => {
                        handleChangeHospitalMerge(newValue, props?.row);
                      }}
                      variant="outlined"
                    />
                  </div>
                )}
              </RadioGroup>
            </div>
          );
        },
      },
    },
  ];

  const handleChangeRadio = (e: any, rowData: any) => {
    const isMerge = e.target.value === '2' ? true : false;
    // const listData = rowAddOrMergeRef.current.filter(item => item.Id !== rowData.Id);
    // listData.push(rowData);
    // rowAddOrMergeRef.current = listData;
    const listData: any[] = [];
    dataList.forEach((item) => {
      if (item.Id === rowData.Id) {
        item.IsMerge = isMerge;
      }
      listData.push(item);
    });
    setDataList(listData);
  };

  const handleChangeHospitalMerge = (newValue: any | null, rowData: any) => {
    const mergeHospitalId = newValue ? newValue.Id : null;
    // const listData = rowAddOrMergeRef.current.filter(item => item.Id !== rowData.Id);
    // listData.push(rowData);
    // rowAddOrMergeRef.current = listData;
    const listData: any[] = [];
    dataList.forEach((item) => {
      if (item.Id === rowData.Id) {
        item.MergeHospitalId = mergeHospitalId;
      }
      listData.push(item);
    });
    setDataList(listData);
  };

  const handlePageSize = (e: number) => {
    setPageSize(e);
  };

  const getRowId = (row: any) => row.Id;

  const commitChanges = ({ added, changed, deleted }: ChangeSet) => {};

  const countAdd = dataList.filter((item) => item.IsMerge === false).length;
  const countMerge = dataList.filter((item) => item.IsMerge === true).length;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => {
            const listData: any[] = [];
            dataList.forEach((item) => {
              item.IsMerge = false;
              listData.push(item);
            });
            setDataList(listData);
          }}
          variant="contained"
          style={{ marginRight: '20px', textTransform: 'capitalize' }}
        >
          {t('settings.hospitals.tableActions.add_new')} {`(${countAdd})`}
        </Button>
        <Button
          onClick={() => {
            const listData: any[] = [];
            dataList.forEach((item) => {
              item.IsMerge = true;
              listData.push(item);
            });
            setDataList(listData);
          }}
          variant="contained"
          style={{ textTransform: 'capitalize' }}
        >
          {t('settings.hospitals.tableActions.merge')} {`(${countMerge})`}
        </Button>
      </div>
      <DataTableComponent
        getRowId={getRowId}
        rows={dataList}
        columns={columns}
        isRemotePaging={false}
        totalCount={dataList.length}
        currentPage={currentPage}
        onCurrentPageChange={setCurrentPage}
        pageSize={pageSize}
        onPageSizeChange={handlePageSize}
        loading={false}
        showSearchPanel={false}
        showColumnChooser={false}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        columnExtensions={tableColumnExtensions}
        // sorting={sortValue}
        // onSortingChange={setSortValue}
        // isRemoteSorting
        // Editing
        showAddCommand={false}
        showEditCommand={false}
        showDeleteCommand={false}
        editColumnCustom={() => {
          return <></>;
        }}
        editColumnCustomCss={{ display: 'none' }}
        editingRowIds={dataList.map((val) => val.Id)}
        onRowChangesChange={(rowChanges) => {
          rowChangesRef.current = rowChanges;
        }}
        onCommitChanges={commitChanges}
        widthTableEditingColumn={0}
        editingStateColumnExtensions={[
          { columnName: 'Index', editingEnabled: false },
          { columnName: 'actions', editingEnabled: false },
        ]}
        editRowHighlight={false}
      />
    </>
  );
});

export default ImportHospitalDataTable;
