import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX, PREFIX_ACCOUNT } from './apiUrls';
import { IUserDetailData, IResponseUsers, IUserByRoleData } from './interface';
import cloneDeep from 'lodash/cloneDeep';

export const fetchUsersByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/searchV2`, { params: data });
};

export const fetchUsers = createAsyncThunk<IResponseUsers, any, { rejectValue: string }>(
  'Users/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchUsersByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createUser = createAsyncThunk<IUserDetailData, any, { rejectValue: string }>(
  'Users/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.auth.post(`${PREFIX_ACCOUNT}`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const exportUser = (payload: any) => {
  return httpRequest.insurance.post(`${PREFIX}/extract`, payload, { responseType: 'blob' });
};

export const getUserByRole = createAsyncThunk<IUserByRoleData[], any, { rejectValue: string }>(
  'Users/get-by-role',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.auth.get(`${PREFIX_ACCOUNT}/get-user-by-role/${payload}`);
      const users = data?.Data;
      return users;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
