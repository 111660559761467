import { FC } from 'react';
import { KeycloakService } from 'services';

const AuthGuard: FC = ({ children }) => {
  const isAuth = KeycloakService.isLoggedIn();
  if (!isAuth) return null;
  return <>{children}</>;
};

export default AuthGuard;
