import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// components
import MainLayout from 'components/layout/MainLayout';
import { BackdropDialog } from 'components/BackdropDialog';
import GuestGuard from './GuestGuard';
import AuthGuard from './AuthGuard';
import AuthIFrameLoading from '../fullPage/authLoading';

import guestRoutes from './guestRoutes';
import authRoutes from './authRoutes';
import { IRoute } from './interface';
import { PATH_NAME } from './pathName';
import { isAuthorized } from 'utils/tokenUtils';

const FullPage = React.lazy(() => import('fullPage'));
const Dashboard = React.lazy(() => import('features/dashboard'));
// const Login = React.lazy(() => import('features/login'));
const RoleSelect = React.lazy(() => import('features/role-select'));
// const ResetPassword = React.lazy(() => import('features/reset-password'));
const NotAllowed = React.lazy(() => import('features/not-allowed'));
const NotFound = React.lazy(() => import('features/not-found'));

function RenderPage(route: IRoute) {
  if (route.isPermit) {
    return React.createElement(route.element);
  }
  return React.createElement(isAuthorized(route.resource || '', route.permission || '') ? route.element : NotAllowed);
}

const RoutesMain = () => (
  <Router>
    <React.Suspense fallback={<BackdropDialog />}>
      <Routes>
        <Route path={`${PATH_NAME.FULL_PAGE}`} element={<AuthIFrameLoading>{React.createElement(FullPage)}</AuthIFrameLoading>} />
        {guestRoutes.map((route: IRoute, routeIdx: number) => (
          <Route
            key={`route-${routeIdx}`}
            path={route.path}
            element={<GuestGuard>{React.createElement(route.element)}</GuestGuard>}
          />
        ))}
        <Route element={<MainLayout />}>
          <Route path="/" element={<AuthGuard>{React.createElement(Dashboard)}</AuthGuard>} />
        </Route>
        {/* <Route path={`${PATH_NAME.LOGIN}`} element={<GuestGuard>{React.createElement(Login)}</GuestGuard>} /> */}
        <Route path={`${PATH_NAME.ROLE_SELECT}`} element={<AuthGuard>{React.createElement(RoleSelect)}</AuthGuard>} />
        {/* <Route path={`${PATH_NAME.RESET_PASSWORD}`} element={<AuthGuard>{React.createElement(ResetPassword)}</AuthGuard>} /> */}
        <Route element={<MainLayout />}>
          {authRoutes.map((route: IRoute, routeIdx: number) => (
            <Route
              key={`route-${routeIdx}`}
              path={`${route.path}/*`}
              element={
                <AuthGuard>
                  <RenderPage
                    path={route.path}
                    element={route.element}
                    isPermit={route.isPermit}
                    resource={route.resource}
                    permission={route.permission}
                  />
                </AuthGuard>
              }
            />
          ))}
        </Route>
        <Route path={`${PATH_NAME.NOT_ALLOWED}`} element={<NotAllowed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  </Router>
);

export default RoutesMain;
