import React from 'react';

const RoundedDeleteIcon = () => {
  return (
    <svg width="60" height="62" viewBox="0 0 60 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.5 30.9861C59.5 47.8385 46.2773 61.4722 30 61.4722C13.7227 61.4722 0.5 47.8385 0.5 30.9861C0.5 14.1337 13.7227 0.5 30 0.5C46.2773 0.5 59.5 14.1337 59.5 30.9861Z"
        fill="white"
        stroke="#E5E5E5"
      />
      <path
        d="M40 31.5H32.5V39C32.5 39.825 31.825 40.5 31 40.5C30.175 40.5 29.5 39.825 29.5 39V31.5H22C21.175 31.5 20.5 30.825 20.5 30C20.5 29.175 21.175 28.5 22 28.5H29.5V21C29.5 20.175 30.175 19.5 31 19.5C31.825 19.5 32.5 20.175 32.5 21V28.5H40C40.825 28.5 41.5 29.175 41.5 30C41.5 30.825 40.825 31.5 40 31.5Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default RoundedDeleteIcon;
