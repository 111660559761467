import React, { memo } from 'react';
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { ArrangeIcon } from 'components/Svg';
import { makeId } from 'helpers/string';
import TCheckBox from 'components/DataTable/Common/TCheckBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './styles.css';

export type MoreAction = {
  title: React.ReactNode;
  onClick: (e: React.SyntheticEvent<any, Event>, props: Table.DataCellProps) => void;
};

export type Action = {
  title: React.ReactNode;
  icon?: JSX.Element;
  onClick: (e: React.SyntheticEvent<any, Event>, props: Table.DataCellProps) => void;
  moreButton?: MoreAction[];
};

export type RightActionProps = {
  width?: number;
  padding?: string;
  component: (props: any) => React.ReactNode;
};

export type HoverActionProps = {
  width?: number;
  padding?: string;
  actions: (props: any) => Action[];
};

const TableCell = ({ ...restProps }: any) => {
  const { onToggle, column, tableColumn, tableRow, style, className } = restProps;

  const { dataTypes, rightAction, hoverAction } = column ?? {};
  const { align } = tableColumn ?? {};
  const { rowId, row } = tableRow ?? {};

  const [anchorElMoreBtn, setAnchorElMoreBtn] = React.useState<null | HTMLElement>(null);
  const openMoreBtn = Boolean(anchorElMoreBtn);
  const handleClickMoreBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMoreBtn(event.currentTarget);
  };
  const handleCloseMoreBtn = () => {
    setAnchorElMoreBtn(null);
  };

  if (dataTypes === 'boolean') {
    return (
      <Table.Cell {...restProps} style={{ textAlign: align }}>
        <TCheckBox disabled initValue={restProps.value} />
      </Table.Cell>
    );
  }

  if (dataTypes === 'onoff') {
    return <Table.Cell {...restProps} style={{ textAlign: 'center', padding: '0px' }} />;
  }

  if (dataTypes === 'price') {
    return <Table.Cell {...restProps} />;
  }

  if (dataTypes === 'payment_platform') {
    if (!restProps.value || restProps.value.length < 1) return <Table.Cell {...restProps} />;
    return (
      <Table.Cell {...restProps}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {restProps.value.map((item: any) => {
            return (
              <FormControlLabel
                sx={{ paddingTop: '10px', paddingLeft: '8px' }}
                key={item.PlatformId}
                control={<TCheckBox key={item.PlatformId} disabled style={{ padding: '0px' }} initValue={item.Checked} />}
                label={<span style={{ fontSize: '16px', fontWeight: '400', marginLeft: '5px' }}>{item.PlatformName}</span>}
              />
            );
          })}
        </div>
      </Table.Cell>
    );
  }

  if (dataTypes === 'payment_fee') {
    if (!restProps.value || restProps.value.length < 1) return <Table.Cell {...restProps} />;
    return (
      <Table.Cell {...restProps}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {restProps.value.map((item: any, index: number) => {
            let fee = '';
            if (item.FeeOffset > 0 && item.FeePercent > 0) {
              fee = `${item.FeePercent}% + ${item.FeeOffset}`;
            } else if (item.FeeOffset > 0) {
              fee = `${item.FeeOffset}`;
            } else if (item.FeePercent > 0) {
              fee = `${item.FeePercent}%`;
            }
            return (
              <span key={index} style={{ fontSize: '16px', fontWeight: '400', paddingTop: '10px' }}>
                {fee}
              </span>
            );
          })}
        </div>
      </Table.Cell>
    );
  }

  if (rightAction) {
    return (
      <Table.Cell
        {...restProps}
        className={className}
        style={{
          borderLeft: '0px',
          padding: rightAction.padding ? rightAction.padding : '2px 4px',
          // background: 'transparent',
          width: rightAction.width ? `${rightAction.width}px` : `200px`,
          ...style,
        }}
      >
        {rightAction.component(restProps)}
      </Table.Cell>
    );
  }

  if (hoverAction) {
    return (
      <Table.Cell
        {...restProps}
        className={`${className} ${openMoreBtn ? '' : 'MuiTableCell-custom-hover'}`}
        style={{
          borderLeft: '0px',
          padding: hoverAction.padding ? hoverAction.padding : '2px 4px',
          background: 'transparent',
          width: hoverAction.width ? `${hoverAction.width}px` : `${hoverAction.actions.length * 60}px`,
          ...style,
        }}
      >
        {hoverAction.actions(restProps).map((action: any) => (
          <>
            {!action.moreButton && action.title && action.icon && (
              <Tooltip title={action.title}>
                <IconButton
                  key={makeId()}
                  size={'small'}
                  sx={{ width: 48, height: 48 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    action.onClick(e, restProps);
                  }}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            )}
            {action.moreButton && action.title && action.icon && (
              <>
                <Tooltip title={action.title}>
                  <IconButton
                    id={`more-button-${rowId}`}
                    aria-controls={openMoreBtn ? `more-menu-${rowId}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMoreBtn ? 'true' : undefined}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickMoreBtn(e);
                      action.onClick(e, restProps);
                    }}
                  >
                    <ArrangeIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id={`more-menu-${rowId}`}
                  anchorEl={anchorElMoreBtn}
                  open={openMoreBtn}
                  onClose={handleCloseMoreBtn}
                  MenuListProps={{
                    'aria-labelledby': `more-button-${rowId}`,
                  }}
                >
                  {action.moreButton.map((item: any) => (
                    <MenuItem
                      key={makeId()}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseMoreBtn();
                        item.onClick(e, restProps);
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </>
        ))}
      </Table.Cell>
    );
  }

  if (onToggle) {
    if (row && row.IsSendToLead) {
      return <Table.Cell {...restProps} />;
    }
    return <TableSelection.Cell {...restProps} />;
  }
  return <Table.Cell {...restProps} />;
};

export default memo(TableCell);
