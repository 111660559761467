import React from 'react';

const ConfigHospital = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <g clipPath="url(#clip0_36_1462)">
        <path
          d="M20.1705 19.71L21.5705 17.29C21.6605 17.14 21.6205 16.95 21.4905 16.84L20.0105 15.68C20.0405 15.46 20.0605 15.23 20.0605 15C20.0605 14.77 20.0405 14.54 20.0105 14.31L21.4905 13.15C21.6205 13.04 21.6605 12.85 21.5705 12.7L20.1705 10.28C20.0805 10.13 19.9005 10.07 19.7405 10.13L18.0005 10.83C17.6405 10.55 17.2505 10.32 16.8205 10.14L16.5605 8.29C16.5305 8.13 16.3805 8 16.2105 8H13.4105C13.2405 8 13.0905 8.13 13.0605 8.3L12.8005 10.15C12.3805 10.33 11.9805 10.56 11.6205 10.84L9.88049 10.14C9.72049 10.08 9.54049 10.14 9.45049 10.29L8.05049 12.71C7.96049 12.86 8.00049 13.05 8.13049 13.16L9.61049 14.32C9.58049 14.54 9.56049 14.77 9.56049 15C9.56049 15.23 9.58049 15.46 9.61049 15.69L8.13049 16.85C8.00049 16.96 7.96049 17.15 8.05049 17.3L9.45049 19.72C9.54049 19.87 9.72049 19.93 9.88049 19.87L11.6205 19.17C11.9805 19.45 12.3705 19.68 12.8005 19.86L13.0605 21.71C13.0905 21.87 13.2405 22 13.4105 22H16.2105C16.3805 22 16.5305 21.87 16.5605 21.7L16.8205 19.85C17.2405 19.67 17.6405 19.44 18.0005 19.16L19.7405 19.86C19.9005 19.92 20.0805 19.86 20.1705 19.71ZM14.8105 17C13.7105 17 12.8105 16.1 12.8105 15C12.8105 13.9 13.7105 13 14.8105 13C15.9105 13 16.8105 13.9 16.8105 15C16.8105 16.1 15.9105 17 14.8105 17Z"
          fill="#454550"
        />
        <path
          d="M27.9205 24.67L26.9605 23.93C26.9805 23.79 27.0005 23.64 27.0005 23.49C27.0005 23.34 26.9905 23.19 26.9605 23.05L27.9105 22.31C27.9905 22.24 28.0205 22.12 27.9605 22.02L27.0605 20.47C27.0105 20.37 26.8905 20.34 26.7805 20.37L25.6705 20.82C25.4405 20.64 25.1905 20.49 24.9105 20.38L24.7405 19.2C24.7305 19.08 24.6305 19 24.5305 19H22.7405C22.6305 19 22.5305 19.08 22.5205 19.19L22.3505 20.37C22.0805 20.49 21.8205 20.63 21.5905 20.81L20.4805 20.36C20.3805 20.32 20.2605 20.36 20.2005 20.46L19.3005 22.01C19.2505 22.11 19.2605 22.23 19.3505 22.3L20.3005 23.04C20.2805 23.18 20.2705 23.33 20.2705 23.48C20.2705 23.63 20.2805 23.78 20.3005 23.92L19.3505 24.66C19.2705 24.73 19.2405 24.85 19.3005 24.95L20.2005 26.5C20.2505 26.6 20.3705 26.63 20.4805 26.6L21.5905 26.15C21.8205 26.33 22.0705 26.48 22.3505 26.59L22.5205 27.77C22.5405 27.88 22.6305 27.96 22.7405 27.96H24.5305C24.6405 27.96 24.7405 27.88 24.7505 27.77L24.9205 26.59C25.1905 26.47 25.4505 26.33 25.6705 26.15L26.7905 26.6C26.8905 26.64 27.0105 26.6 27.0705 26.5L27.9705 24.95C28.0305 24.86 28.0005 24.74 27.9205 24.67ZM23.6305 24.83C22.8905 24.83 22.2805 24.23 22.2805 23.48C22.2805 22.73 22.8805 22.13 23.6305 22.13C24.3805 22.13 24.9805 22.73 24.9805 23.48C24.9805 24.23 24.3705 24.83 23.6305 24.83Z"
          fill="#454550"
        />
      </g>
      <defs>
        <clipPath id="clip0_36_1462">
          <rect width="24" height="24" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfigHospital;
