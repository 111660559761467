import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IGoldmineState } from './interface';
import { fetchGoldmine, detailGoldmine } from './goldmineAPI';

const initialState: IGoldmineState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  isCreated: false,
  isDeleted: false,
  goldmineDetail: null,
};

export const goldmine = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoldmine.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchGoldmine.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchGoldmine.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(detailGoldmine.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(detailGoldmine.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.goldmineDetail = payload || null;
      })
      .addCase(detailGoldmine.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = goldmine.actions;
export const goldmineSelector = (state: RootState) => state.goldmine;

export default goldmine.reducer;
