import { useEffect, useState, useCallback, Fragment, useLayoutEffect } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText, Icon, ListItemButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { routes, childRoutes } from 'routes/routes';
import NavBarCollapse from './NavBarCollapse';
import { getClaims } from 'apis/claimAPIs';

import useStyles from './styles';
import { getUserPermission } from 'utils/tokenUtils';

type NavBarProps = {
  drawerOpen: boolean;
};

const NavBar = ({ drawerOpen = false }: NavBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const [totalPending, setTotalPending] = useState(0);
  const [open, setOpen] = useState<any>(false);

  const handleGoTo = (path: string) => {
    navigate(path);
  };

  useLayoutEffect(() => {
    if (location && location.pathname) {
      let menu = routes.find((item) => item.path && location.pathname.includes(item.path));
      if (!menu) {
        menu = childRoutes.find((item) => item.path && location.pathname.includes(item.path));
      }
      if (menu) {
        document.title = t(`menu.${menu.name}`);
      }
    }
  }, [location, t]);

  useEffect(() => {
    const fetchData = async () => {
      const result: { TotalPending: number } = await getClaims();
      setTotalPending(result.TotalPending);
    };
    fetchData();
  }, []);

  const checkIsMatchLocationPath = useCallback(
    (path?: string) => {
      const match = matchPath(path ?? '', location.pathname);
      return !!match;
    },
    [location.pathname],
  );

  const getMenuByUserRole = () => {
    const menuByRoles: any[] = [];
    const objectMenu: any[] = [];
    const permissions = getUserPermission();
    permissions.forEach((item: any) => {
      const rsnames = item.rsname?.split('-');
      if (rsnames && rsnames.length > 1) {
        objectMenu.push(rsnames[0]);
      }
      objectMenu.push(item.rsname);
    });
    routes.forEach((menu: any) => {
      if (objectMenu.includes(menu.id)) {
        if (menu.isGroup) {
          const children: any[] = [];
          childRoutes.forEach((childMenu: any) => {
            if (objectMenu.includes(childMenu.id) && childMenu.id.startsWith(`${menu.id}-`)) {
              children.push(childMenu);
            }
          });
          Object.assign(menu, { children });
        }
        menuByRoles.push(menu);
      }
    });
    return menuByRoles;
  };

  const renderChildrenRoutes = (childrenMenus: any[]) =>
    childrenMenus.map(({ id, name, path, icon }, i) => {
      const key = `${id}_${i}`;
      const isMatched = checkIsMatchLocationPath(path);

      return (
        <ListItem
          key={key}
          disablePadding
          sx={{
            display: 'block',
            backgroundColor: isMatched ? '#F7F7F7' : '#00C2CB',
            boxShadow: isMatched ? 'inset 5px 0 0 0 #766EC8' : 'none',
          }}
          onClick={() => handleGoTo(path)}
        >
          <ListItemButton
            sx={{
              minHeight: 60,
              justifyContent: drawerOpen ? 'initial' : 'center',
              px: 2.5,
              pl: drawerOpen ? 4 : 'auto',
            }}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                  color: isMatched ? '#00C2CB' : '#FFF',
                }}
              >
                <Icon>{icon}</Icon>
              </ListItemIcon>
            )}
            {drawerOpen && (
              <ListItemText
                primary={t(`menu.${name}`)}
                sx={{
                  whiteSpace: 'pre-line',
                  color: isMatched ? '#00C2CB' : '#FFF',
                  '& .MuiTypography-root': {
                    fontSize: '18px',
                    fontWeight: isMatched ? '600' : '400',
                  },
                }}
              />
            )}
          </ListItemButton>
        </ListItem>
      );
    });

  const renderRoutes = () => {
    const menus = getMenuByUserRole();
    return menus.map(({ id, name, path, icon, isGroup, children }, i) => {
      const key = `${id}_${i}`;
      const isMatched = checkIsMatchLocationPath(path);
      const isOpen = open === key;
      return (
        <Fragment key={key}>
          <ListItem
            disablePadding
            sx={{
              display: 'block',
              backgroundColor: isMatched ? '#F7F7F7' : '#00C2CB',
              boxShadow: isMatched ? 'inset 5px 0 0 0 #766EC8' : 'none',
            }}
            onClick={() => (isGroup ? setOpen(isOpen ? false : key) : handleGoTo(path))}
          >
            <ListItemButton
              sx={{
                minHeight: 60,
                justifyContent: drawerOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              {icon && (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                    color: isMatched ? '#00C2CB' : '#FFF',
                  }}
                >
                  <Icon>{icon}</Icon>
                </ListItemIcon>
              )}
              {drawerOpen && (
                <ListItemText
                  primary={t(`menu.${name}`)}
                  sx={{
                    color: isMatched ? '#00C2CB' : '#FFF',
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                      fontWeight: isMatched ? '600' : '400',
                    },
                  }}
                />
              )}
              {drawerOpen && id === 'Claims' && (
                <Typography className={`${totalPending ? (isMatched ? classes.budgetActive : classes.budget) : ''}`}>
                  {totalPending > 90 ? '99+' : totalPending || ''}
                </Typography>
              )}

              {drawerOpen && isGroup && (isOpen ? <ExpandLess sx={{ color: '#FFF' }} /> : <ExpandMore sx={{ color: '#FFF' }} />)}
            </ListItemButton>
          </ListItem>

          {isGroup && children && <NavBarCollapse key={key} open={isOpen} children={renderChildrenRoutes(children)} />}
        </Fragment>
      );
    });
  };

  return <List>{renderRoutes()}</List>;
};

export default NavBar;
