import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import {
  FETCH_DETAIL_BANNERS_API,
  GET_STREAM_DETAIL_API,
  PREFIX,
  STREAM_HISTORY_API,
  STREAM_RE_ORDER_INDEX_API,
} from './apiUrls';
import { IResponseBannerStreams, IResponseHistoryStream, IResponseStreams, IStream, IStreamData } from './interface';

export const fetchStreamHistoryByFilter = (data: any) => {
  return httpRequest.insurance.get(STREAM_HISTORY_API, { params: data });
};

export const fetchStreamHistory = createAsyncThunk<IResponseHistoryStream, any, { rejectValue: string }>(
  'Streams/fetchHistory',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchStreamHistoryByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchStreamByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchStream = createAsyncThunk<IResponseStreams, any, { rejectValue: string }>(
  'Streams/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchStreamByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createStream = createAsyncThunk<IStream, any, { rejectValue: string }>(
  'Streams/create',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      delete payload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, payload);
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateStream = createAsyncThunk<IStreamData, any, { rejectValue: string }>(
  'Streams/update',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      delete payload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, payload);
      if (onSuccess) {
        onSuccess(data);
      }
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const detailStream = createAsyncThunk<IStreamData, any, { rejectValue: string }>(
  'Streams/detail',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(`${GET_STREAM_DETAIL_API}/${payload}`);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchDetailBannerByFilter = (data: any) => {
  return httpRequest.insurance.get(`${FETCH_DETAIL_BANNERS_API}/${data.streamId}`, { params: data });
};

export const fetchDetailBanners = createAsyncThunk<IResponseBannerStreams, any, { rejectValue: string }>(
  'Streams/detailBanner',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchDetailBannerByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createBanner = createAsyncThunk<IStream, any, { rejectValue: string }>(
  'Streams/createBanner',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      delete payload.onSuccess;
      const { data } = await httpRequest.insurance.post(FETCH_DETAIL_BANNERS_API, payload);
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const deleteBanner = createAsyncThunk<IStream, any, { rejectValue: string }>(
  'Streams/deleteBanner',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      delete payload.onSuccess;
      const { data } = await httpRequest.insurance.post(FETCH_DETAIL_BANNERS_API, payload);
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const reOrderStreamBanner = createAsyncThunk<IStream, any, { rejectValue: string }>(
  'Streams/reOrderStreamBanner',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      delete payload.onSuccess;
      const { data } = await httpRequest.insurance.post(STREAM_RE_ORDER_INDEX_API, payload);
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteStream = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
