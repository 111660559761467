import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeEmploymentInformationResponse, IEmploymentInformation } from './interface';

export const fetchEmployeeEmploymentInformationsByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchEmployeeEmploymentInformations = createAsyncThunk<
  IEmployeeEmploymentInformationResponse,
  any,
  { rejectValue: string }
>('EmployeeEmploymentInformation/fetch', async (payload, thunkAPI) => {
  try {
    const { data } = await fetchEmployeeEmploymentInformationsByFilter(payload);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createEmployeeEmploymentInformation = createAsyncThunk<IEmploymentInformation, any, { rejectValue: string }>(
  'EmployeeEmploymentInformation/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployeeEmploymentInformation = createAsyncThunk<IEmploymentInformation, any, { rejectValue: string }>(
  'EmployeeEmploymentInformation/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteEmployeeEmploymentInformation = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
