import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseProductRatings, IProductRating } from './interface';

export const fetchProductRatingsByFilter = (data: any) => {
  return httpRequest.amanShop.get(PREFIX, { params: data });
};

export const fetchProductRatings = createAsyncThunk<IResponseProductRatings, any, { rejectValue: string }>(
  'ProductRating/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchProductRatingsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createProductRating = createAsyncThunk<IProductRating, any, { rejectValue: string }>(
  'ProductRating/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateProductRating = createAsyncThunk<any, any, { rejectValue: string }>(
  'ProductRating/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteProductRating = (data: any) => httpRequest.amanShop.delete(`${PREFIX}/${data}`);
