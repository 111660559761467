import React from 'react';

const PersonelIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M12.5002 9.99977C12.5002 8.34977 11.1502 6.99977 9.50023 6.99977C7.85023 6.99977 6.50023 8.34977 6.50023 9.99977C6.50023 11.6498 7.85023 12.9998 9.50023 12.9998C11.1502 12.9998 12.5002 11.6498 12.5002 9.99977ZM9.50023 10.9998C8.95023 10.9998 8.50023 10.5498 8.50023 9.99977C8.50023 9.44977 8.95023 8.99977 9.50023 8.99977C10.0502 8.99977 10.5002 9.44977 10.5002 9.99977C10.5002 10.5498 10.0502 10.9998 9.50023 10.9998ZM16.0002 12.9998C17.1102 12.9998 18.0002 12.1098 18.0002 10.9998C18.0002 9.88977 17.1102 8.99977 16.0002 8.99977C14.8902 8.99977 13.9902 9.88977 14.0002 10.9998C14.0002 12.1098 14.8902 12.9998 16.0002 12.9998ZM11.9902 2.00977C6.47023 2.00977 1.99023 6.48977 1.99023 12.0098C1.99023 17.5298 6.47023 22.0098 11.9902 22.0098C17.5102 22.0098 21.9902 17.5298 21.9902 12.0098C21.9902 6.48977 17.5102 2.00977 11.9902 2.00977ZM5.84023 17.1198C6.52023 16.5798 8.11023 16.0098 9.50023 16.0098C9.57023 16.0098 9.65023 16.0198 9.73023 16.0198C9.97023 15.3798 10.4002 14.7298 11.0302 14.1598C10.4702 14.0598 9.94023 13.9998 9.50023 13.9998C8.20023 13.9998 6.11023 14.4498 4.77023 15.4298C4.27023 14.3898 3.99023 13.2298 3.99023 11.9998C3.99023 7.58977 7.58023 3.99977 11.9902 3.99977C16.4002 3.99977 19.9902 7.58977 19.9902 11.9998C19.9902 13.1998 19.7202 14.3398 19.2402 15.3698C18.2402 14.7798 16.8802 14.4998 16.0002 14.4998C14.4802 14.4998 11.5002 15.3098 11.5002 17.1998V19.9798C9.23023 19.8498 7.21023 18.7698 5.84023 17.1198Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PersonelIcon;
