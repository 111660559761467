import { Filter, FilteringState } from '@devexpress/dx-react-grid';
import { DataTableColumn } from './DataTable';
import dayjs from 'dayjs';
import { matchSorter } from 'match-sorter';

export const calculateDateFilter = (values: any[]) => {
  const arrFilter: string[] = [];
  // Add filter year
  values.forEach((item: any) => {
    const arrValue = item?.value?.split('-');
    if (arrValue.length === 1) {
      arrFilter.push(arrValue[0]);
    }
  });
  // Add filter month
  values.forEach((item: any) => {
    const arrValue = item?.value?.split('-');
    if (arrValue.length === 2 && !arrFilter.includes(arrValue[0])) {
      arrFilter.push(item?.value);
    }
  });
  // Add filter day
  values.forEach((item: any) => {
    const arrValue = item?.value?.split('-');
    if (arrValue.length === 3 && !arrFilter.includes(arrValue[0]) && !arrFilter.includes(`${arrValue[0]}-${arrValue[1]}`)) {
      arrFilter.push(item?.value);
    }
  });
  return values.filter((item) => arrFilter.includes(item.value));
};

export const getSearchFilter = (
  columns: DataTableColumn[],
  filteringStateColumnExtensions: FilteringState.ColumnExtension[],
  searchValue: string,
  filterStr: string,
) => {
  if (filterStr === null || filterStr === undefined) filterStr = '';
  if (!!searchValue) {
    const excludeFilter = filteringStateColumnExtensions.filter((item) => !item.filteringEnabled).map((item) => item.columnName);
    excludeFilter.push('ChangeDate');
    excludeFilter.push('MemberDateOfBirth');
    const filtersWithSearchValue: string[] = [];
    columns.forEach((item) => {
      if (!excludeFilter.includes(item.name)) {
        if (item.optionSource && item.optionSource.length > 0) {
          const filterOptionSource = matchSorter(item.optionSource, searchValue, { keys: ['label'] });
          if (filterOptionSource.length > 0) {
            filterOptionSource.forEach((filterItem) => {
              filtersWithSearchValue.push(JSON.stringify([item.name, 'contains', filterItem.value]));
            });
          }
        } else {
          filtersWithSearchValue.push(JSON.stringify([item.name, 'contains', searchValue]));
        }
      }
    });
    if (filterStr !== '') {
      filterStr += `,"and",`;
    }
    filterStr += `[${filtersWithSearchValue.join(',"or",')}]`;
  }
  if (filterStr === '') {
    return null;
  }
  return {
    filter: `[${filterStr}]`,
  };
};

export const getMonthByMMMM = (monthName: string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames.indexOf(monthName) + 1;
};

export const getFilterAllColumn = (filters: Filter[]) => {
  let filterStr = '';
  if (filters && filters.length > 0) {
    let filterCols: string[] = [];
    filters.forEach((f) => {
      const filterCol = [];
      if (typeof f.value === 'string') {
        filterCol.push(JSON.stringify([f.columnName, f.operation, f.value]));
      } else if (Array.isArray(f.value)) {
        if (f.value.length > 0) {
          if (f.operation && f.operation.startsWith('!')) {
            f.value.forEach((item: any) => {
              if (item.value === null) {
                filterCol.push(JSON.stringify([f.columnName, f.operation?.substring(1), null]));
                filterCol.push(JSON.stringify([f.columnName, f.operation?.substring(1), '']));
              } else {
                filterCol.push(JSON.stringify([f.columnName, f.operation?.substring(1), item.value]));
              }
            });
          } else if (f.operation === '&') {
            calculateDateFilter(f.value).forEach((item: any) => {
              const arrDate = item.value?.split('-');
              if (arrDate && arrDate.length > 0) {
                let fromDate = dayjs(new Date(parseInt(arrDate[0]), 0, 1));
                let toDate = fromDate.add(1, 'year');
                if (arrDate.length === 2) {
                  fromDate = dayjs(new Date(parseInt(arrDate[0]), getMonthByMMMM(arrDate[1]) - 1, 1));
                  toDate = fromDate.add(1, 'month');
                }
                if (arrDate.length === 3) {
                  fromDate = dayjs(new Date(parseInt(arrDate[0]), getMonthByMMMM(arrDate[1]) - 1, parseInt(arrDate[2])));
                  toDate = fromDate.add(1, 'day');
                }
                // Fix timezone +7
                filterCol.push(
                  `[${JSON.stringify([f.columnName, '>=', fromDate.utc()])},"and",${JSON.stringify([
                    f.columnName,
                    '<',
                    toDate.utc(),
                  ])}]`,
                );
              }
            });
          } else if (f.operation === '#') {
            if (f.value?.length === 3) {
              filterCol.push(JSON.stringify([f.columnName, '>=', f.value[0]]));
              filterCol.push(JSON.stringify([f.columnName, '<=', f.value[1]]));
            }
          } else {
            f.value.forEach((item: any) => {
              if (item.value === null) {
                filterCol.push(JSON.stringify([f.columnName, f.operation, null]));
                filterCol.push(JSON.stringify([f.columnName, f.operation, '']));
              } else {
                filterCol.push(JSON.stringify([f.columnName, f.operation, item.value]));
              }
            });
          }
        }
      }
      if (filterCol.length > 0) {
        if (f.operation && f.operation.startsWith('!')) {
          filterCols.push(`["!",[${filterCol.join(',"or",')}]]`);
        } else if (f.operation === '&') {
          filterCols.push(`[${filterCol.join(',"or",')}]`);
        } else if (f.operation === '#') {
          filterCols.push(`[${filterCol.join(',"and",')}]`);
        } else {
          filterCols.push(`[${filterCol.join(',"or",')}]`);
        }
      }
    });
    if (filterCols.length > 0) {
      filterStr += `[${filterCols.join(',"and",')}]`;
    }
  }
  return filterStr;
};
