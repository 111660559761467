import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeInsurancePolicyResponse, IInsurancePolicyInformation } from './interface';
import cloneDeep from 'lodash/cloneDeep';

export const fetchEmployeeInsurancePolicyByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/account`, { params: data });
};

export const fetchEmployeeInsurancePolicy = createAsyncThunk<IEmployeeInsurancePolicyResponse, any, { rejectValue: string }>(
  'EmployeeInsurancePolicy/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchEmployeeInsurancePolicyByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createEmployeeInsurancePolicy = createAsyncThunk<IInsurancePolicyInformation, any, { rejectValue: string }>(
  'EmployeeInsurancePolicy/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(`${PREFIX}/account`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployeeInsurancePolicy = createAsyncThunk<IInsurancePolicyInformation, any, { rejectValue: string }>(
  'EmployeeInsurancePolicy/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(`${PREFIX}/account`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteEmployeeInsurancePolicy = (data: any) => httpRequest.insurance.delete(`${PREFIX}/account/${data}`);

export const updateMultiEmployeeStatus = createAsyncThunk<any, any, { rejectValue: string }>(
  'EmployeePolicy/update-status',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    try {
      const { data } = await httpRequest.insurance.post(`${PREFIX}/multiemployee/status`, payload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getAllPolicy = () => {
  return httpRequest.insurance.get(`${PREFIX}`);
};
