import React from 'react';

const DeleteIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M22 15V25H14V15H22ZM20.5 9H15.5L14.5 10H11V12H25V10H21.5L20.5 9ZM24 13H12V25C12 26.1 12.9 27 14 27H22C23.1 27 24 26.1 24 25V13Z"
        fill="#454550"
      />
    </svg>
  );
};

export default DeleteIcon;
