import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchAllLeadStatus, createLeadStatus, updateLeadStatus } from './leadStatussAPIs';
import { ILeadStatusState } from './interface';

const initialState: ILeadStatusState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  // leadDetal: undefined,
  isCreated: false,
};

export const LeadStatuss = createSlice({
  name: 'LeadStatuss',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditLeadStatus(state, action) {
      state.isUpdated = false;
    },
    requestCreateLeadStatus(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLeadStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllLeadStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.allData = payload;
      })
      .addCase(fetchAllLeadStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createLeadStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createLeadStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createLeadStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateLeadStatus.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateLeadStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateLeadStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateLeadStatus, requestEditLeadStatus, requestClearError } = LeadStatuss.actions;
export const LeadStatussSelector = (state: RootState) => state.saleLeadStatusReducer;

export default LeadStatuss.reducer;
