export type TStateDialog = {
  open: boolean;
  content?: string;
  cancelTitle?: string;
  okTitle?: string;
  dialogType: EDialogType | null;
};

export type TDialogConfirmProps = {
  onConfirm?: (dialogType?: EDialogType | null) => void;
};

export enum EDialogType {
  ACTIVE_MEMBER,
  DEACTIVE_MEMBER,
  UNLINK_SSO_LOGIN,

  DELETE_EMPLOYMENT_INFORMATION,
  DELETE_INSURANCE_POLICY,
  DELETE_FAMILY,
  DELETE_BANK_ACCOUNT,

  DELETE_STREAM,
  ACTIVE_STREAM,
  DEACTIVE_STREAM,
  DELETE_BANNER,
  ACTIVE_BANNER,
  DEACTIVE_BANNER,
  MERGE_HOSPITAL,
  UPDATE_HOSPITAL_RATING,
  DELETE_HOSPITAL_RATING,
  UPDATE_HOSPITAL_INSURANCE_NETWORK,
  DELETE_HOSPITAL_INSURANCE_NETWORK,
  DELETE_HOSPITAL_SERVICE,
  DELETE_HOSPITAL_DEPARTMENT,
  DELETE_MEDICAL_PROCEDURE,
  DELETE_MERCHANT,
  ACTIVE_MERCHANT,
  DEACTIVE_MERCHANT,
  DELETE_STORE,
  UPDATE_STORE_RATING,
  DELETE_STORE_RATING,
  UPDATE_STORE_DELIVERY,
  DELETE_STORE_DELIVERY,
  ACTIVE_STORE_VOUCHER,
  DEACTIVE_STORE_VOUCHER,
  UPDATE_STORE_VOUCHER,
  DELETE_STORE_VOUCHER,
  DELETE_PRODUCT,
  ACTIVE_PRODUCT,
  DEACTIVE_PRODUCT,
  UPDATE_DISCOUNT,
  DELETE_DISCOUNT,
  UPDATE_BANKACCOUNT,
  DELETE_BANKACCOUNT,
  CONFIRM_ORDER,
  PAYMENT_CONFIRM_ORDER,
  REFUND_CONFIRM_ORDER,
  REJECT_COMPLAINT,
  ACCEPT_COMPLAINT,
  COMPLAINT_ORDER,
  CONFIRM_COMPLAINT_ORDER,
  FINAL_SOLUTION_ORDER,
  FINAL_DECISION_ORDER,
  REQUEST_ADDITIONAL_ORDER,

  // Aman Sale -Lead
  DELETE_LEAD,
  DELETE_LEAD_STATUS,
  DELETE_EXCHANGE,
  DELETE_LEAD_MEETING,
  WARNING_SEND_MESSAGE_CONTENT,

  WITHDRAW_LEAD,

  CLOSE_IMPORT,
  SEND_GOLDMINE_COMPANY,
  DELETE_GOLDMINE_COMPANY,
}
