import React from 'react';

const CheckboxUnCheckedIcon = () => {
  // @ts-ignore
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="#E5E5E5" />
    </svg>
  );
};

export default CheckboxUnCheckedIcon;
