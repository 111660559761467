import React from 'react';

const MergeHospital = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M22.9998 26.41L24.4098 25L20.9998 21.59L19.5898 23L22.9998 26.41ZM13.4998 14H16.9998V19.59L11.5898 25L12.9998 26.41L18.9998 20.41V14H22.4998L17.9998 9.5L13.4998 14Z"
        fill="#454550"
      />
    </svg>
  );
};

export default MergeHospital;
