import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchHospitalConfig } from './hospitalConfigAPIs';
import { IHospitalConfigState } from './interface';

const initialState: IHospitalConfigState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
};

export const hospitalConfigSlice = createSlice({
  name: 'hospitalConfigs',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalConfig.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalConfig.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospitalConfig.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = hospitalConfigSlice.actions;
export const hospitalConfigSelector = (state: RootState) => state.hospitalConfigs;

export default hospitalConfigSlice.reducer;
