import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
}

export default styled(Checkbox)(({ theme }: IProps | any) => {
  // const { palette, shape } = theme;

  // // const { rgba } = functions;
  // const { borderRadius } = shape;

  // styles for the button with variant="contained"
  const containedStyles = () => {
    // color value

    return {};
  };

  return {
    ...containedStyles(),
  };
});
