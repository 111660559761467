import React, { FC, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { KeycloakService } from 'services';
import { validateFirebaseLogin } from '../firebaseAman';
import { getFirebaseToken, setFirebaseToken, setUserPermission, setUserDefaultRole } from 'utils/tokenUtils';

const AuthIFrameLoading: FC = ({ children }) => {
  const isAuth = KeycloakService.isLoggedIn() || false;
  const [isAuthorized, setIsAuthorized] = useState<boolean>(isAuth);

  React.useEffect(() => {
    window.addEventListener('message', receiveDataFromWeb, false);
    return () => {
      window.removeEventListener('message', receiveDataFromWeb, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const receiveDataFromWeb = (data: any) => {
    if (data && data.data && data.data.type === 'SENT_TO_IFRAME') {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      const i18nextLng = data.data.i18nextLng;
      const dataStorage = JSON.parse(data.data.dataStorage);
      const dataKeycloak = JSON.parse(data.data.dataKeycloak);
      localStorage.setItem('i18nextLng', i18nextLng);
      setFirebaseToken(dataStorage['firebase-token']);
      setUserPermission(JSON.parse(dataStorage.permissions));
      if (dataStorage['default-role']) {
        setUserDefaultRole(JSON.parse(dataStorage['default-role']));
      }
      KeycloakService.initToken(dataKeycloak, () => {
        validateFirebaseLogin(getFirebaseToken());
        setIsAuthorized(true);
      });
    }
  };

  if (!isAuthorized) return <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', color: '#00C2CB' }} />;

  return <>{children}</>;
};

export default AuthIFrameLoading;
