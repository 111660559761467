import React, { memo } from 'react';
import { Collapse, List } from '@mui/material';

export type NavBarCollapseProps = {
  open?: boolean;
  children?: React.ReactNode;
};

const NavBarCollapse = ({ open, children }: NavBarCollapseProps) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children}
      </List>
    </Collapse>
  );
};

export default memo(NavBarCollapse);
