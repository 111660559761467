import React from 'react';

const BidderInforIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M17 6V10.5C16.2979 10.4992 15.6035 10.6466 14.9623 10.9327C14.321 11.2188 13.7474 11.637 13.279 12.16C12.4539 13.0766 11.9982 14.2667 12 15.5C12.0003 16.385 12.2353 17.2541 12.6809 18.0187C13.1266 18.7833 13.7671 19.4161 14.537 19.8525C13.206 20.255 11.439 20.5 9.5 20.5C5.358 20.5 2 19.3805 2 18V6"
        stroke="#777777"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 15.5C22 18.2615 19.7615 20.5 17 20.5C16.105 20.5 15.264 20.265 14.537 19.8525C13.7671 19.4161 13.1266 18.7833 12.6809 18.0187C12.2353 17.2541 12.0003 16.385 12 15.5C12 14.216 12.484 13.045 13.279 12.16C13.7474 11.637 14.321 11.2188 14.9623 10.9327C15.6035 10.6466 16.2979 10.4992 17 10.5C19.7615 10.5 22 12.7385 22 15.5ZM17 6C17 7.3805 13.642 8.5 9.5 8.5C5.358 8.5 2 7.3805 2 6C2 4.6195 5.358 3.5 9.5 3.5C13.642 3.5 17 4.6195 17 6Z"
        stroke="#777777"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 14C2 15.3805 5.358 16.5 9.5 16.5C10.4035 16.5 11.27 16.447 12.072 16.349M2 10C2 11.3805 5.358 12.5 9.5 12.5C10.878 12.5 12.1695 12.376 13.279 12.16M18.5 14L15.5 17"
        stroke="#777777"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BidderInforIcon;
