import { memo } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';

const TableHeaderRowContent = ({ ...restProps }: any) => {
  const {
    column: { dataTypes },
    children,
  } = restProps;

  if (dataTypes === 'flag') {
    return (
      <TableHeaderRow.Content {...restProps}>
        <OutlinedFlagIcon />
      </TableHeaderRow.Content>
    );
  }

  return <TableHeaderRow.Content {...restProps}>{children}</TableHeaderRow.Content>;
};

export default memo(TableHeaderRowContent);
