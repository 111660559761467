import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeBankAccountResponse, IBankAccount } from './interface';

export const fetchEmployeeBankAccountByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchEmployeeBankAccount = createAsyncThunk<IEmployeeBankAccountResponse, any, { rejectValue: string }>(
  'EmployeeBankAccount/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchEmployeeBankAccountByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createEmployeeBankAccount = createAsyncThunk<IBankAccount, any, { rejectValue: string }>(
  'EmployeeBankAccount/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployeeBankAccount = createAsyncThunk<IBankAccount, any, { rejectValue: string }>(
  'EmployeeBankAccount/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteEmployeeBankAccount = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);

export const getEmployeeBankAccount = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/${data}`);
};
