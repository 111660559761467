import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX, FETCH_BATCH } from './apiUrls';
import { IResponseBatch } from './interface';

export const fetchBatchDetailByFilter = (id: number, params: any) => {
  return httpRequest.insurance.get(`${PREFIX}/${id}`, { params: params });
};

export const fetchBatchByFilter = (data: any) => {
  return httpRequest.insurance.get(FETCH_BATCH, { params: data });
};

export const fetchBatch = createAsyncThunk<IResponseBatch, any, { rejectValue: string }>(
  'batch/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchBatchByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
