import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX, PREFIX_ACCOUNT } from './apiUrls';
import { IEmployeeDetailData, IEmployeeTemplate, IMutationPayload, IResponseEmployees } from './interface';
import cloneDeep from 'lodash/cloneDeep';

export const getEmployeesTotal = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/search-total`, { params: data });
};

export const fetchEmployeesByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/search-grid`, { params: data });
};

export const fetchEmployees = createAsyncThunk<IResponseEmployees, any, { rejectValue: string }>(
  'Employees/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchEmployeesByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmployeeDetail = createAsyncThunk<IEmployeeDetailData, any, { rejectValue: string }>(
  'Employees/detail',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await httpRequest.auth.get(`${PREFIX_ACCOUNT}/${id}`, {});
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createEmployee = createAsyncThunk<IEmployeeDetailData, any, { rejectValue: string }>(
  'Employees/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.auth.post(`${PREFIX_ACCOUNT}/add-employee`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployee = createAsyncThunk<IEmployeeDetailData, any, { rejectValue: string }>(
  'Employees/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.auth.put(`${PREFIX_ACCOUNT}`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmployeeSubplanAction = (data: any) => {
  return httpRequest.insurance.post(`${PREFIX}/search/subplan`, data);
};

export const getTemplateUploadEmployees = () => {
  return httpRequest.insurance.get(`${PREFIX}/template-firestore`);
};

export const getEmployees = createAsyncThunk<IResponseEmployees, any, { rejectValue: string }>(
  'Employees/get',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await fetchEmployeesByFilter(newPayload);
      if (onSuccess) onSuccess(data);

      return data;
    } catch (err: any) {
      if (onError) onError(err);

      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmployeeTemplate = createAsyncThunk<any, any, { rejectValue: string }>(
  'Employees/fetch-employee-template',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.insurance.get(`${PREFIX}/template-firestore`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmployeeTemplate = createAsyncThunk<any, IEmployeeTemplate & IMutationPayload, { rejectValue: string }>(
  'Employees/update-employee-template',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    try {
      const { data } = await httpRequest.insurance.post(`${PREFIX}/upload/template-firestore`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const applyImportEmployee = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Employees/apply-import-employee',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { IsResume, onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importEmployeeTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.insurance.post(`${PREFIX}/applyImport`, {
          ImportTransId: transId,
          IsResume: IsResume,
        });
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const undoImportEmployee = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Employees/undo-import-employee',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importEmployeeTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.insurance.post(`${PREFIX}/undoImport`, { ImportTransId: transId });
        localStorage.removeItem('importEmployeeTransId');
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const importEmployee = (payload: FormData) => {
  return httpRequest.insurance.post(`${PREFIX}/import`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getImportEmployee = (data: any, params: any) => {
  return httpRequest.insurance.get(`${PREFIX}/transId?transId=${data}`, { params: params });
};

export const exportEmployee = (payload: any) => {
  return httpRequest.insurance.post(`${PREFIX}/extract`, payload, { responseType: 'blob' });
};

export const exportUploadFileEmployee = (payload: any) => {
  return httpRequest.insurance.post(`${PREFIX}/extractFileUpload`, payload, { responseType: 'blob' });
};
