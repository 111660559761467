import React, { FC, memo } from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AutocompleteListBox from 'components/Common/AutocompleteListBox';
import { matchSorter } from 'match-sorter';
import Box from '@mui/material/Box';

interface IProp {
  defaultValue?: unknown;
  options: any[];
  onChange: (event: any, newValue: any | null) => void;
  variant: TextFieldVariants | undefined;
}

const AutocompleteHospital: FC<IProp> = ({ defaultValue, options, onChange, variant }) => {
  const [value, setValue] = React.useState<any | null>(defaultValue);
  const [page, setPage] = React.useState<number>(1);

  const optionPaging = options.slice(0, page * 20);

  return (
    <Autocomplete
      options={optionPaging}
      getOptionLabel={(option: any) => `${option.Name || ''}`}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setValue(newValue);
        onChange(event, newValue);
      }}
      filterOptions={(optionss, { inputValue }) => matchSorter(options, inputValue, { keys: ['Name'] }).slice(0, page * 20)}
      sx={{ width: 250 }}
      forcePopupIcon={false}
      autoComplete
      includeInputInList
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.Id} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {option.Name}
        </Box>
      )}
      ListboxComponent={AutocompleteListBox}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (Math.round(listboxNode.scrollTop) + listboxNode.clientHeight === listboxNode.scrollHeight) {
            setPage((page) => page + 1);
          }
        },
        style: { border: '2px solid #00C2CB' },
      }}
      renderInput={(params) => <TextField {...params} variant={variant} size="small" />}
    />
  );
};

export default memo(AutocompleteHospital);
