import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import axiosInstance from 'services';
import {
  FETCH_PRODUCT_CATEGORIES,
  FETCH_PRODUCT_GRID_API,
  PRODUCT_API,
  PRODUCT_CHANGE_STATUS_API,
  FETCH_PRODUCT_ATTRIBUTES,
  // PREFIX,
} from './apiUrls';
import {
  IResponseProducts,
  IResponseProductDetail,
  IProductInformation,
  IProductCategory,
  IProductAttributeCategory,
} from './interface';

export const fetchProductGridByFilter = (data: any) => {
  return axiosInstance.amanShop.get(FETCH_PRODUCT_GRID_API, { params: data });
};

export const fetchProductDetailByFilter = (data: any, params: any) => {
  return axiosInstance.amanShop.get(`${PRODUCT_API}/${data}`, { params: params });
};

export const fetchAllProduct = createAsyncThunk<Array<IProductInformation>, any, { rejectValue: string }>(
  'Product/fetchAll',
  async (payload, thunkAPI) => {
    try {
      const { data } = await axiosInstance.amanShop.get(`${PRODUCT_API}/product-by-store`, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchProductGrid = createAsyncThunk<IResponseProducts, any, { rejectValue: string }>(
  'Product/fetch-grid',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchProductGridByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const fetchProductCategory = createAsyncThunk<IProductCategory[], any, { rejectValue: string }>(
  'Product-category/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await axiosInstance.amanShop.get(FETCH_PRODUCT_CATEGORIES);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAttributeCategory = createAsyncThunk<IProductAttributeCategory[], any, { rejectValue: string }>(
  'Product-attribute/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await axiosInstance.amanShop.get(FETCH_PRODUCT_ATTRIBUTES);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchProductDetail = createAsyncThunk<IResponseProductDetail, any, { rejectValue: string }>(
  'Product-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchProductDetailByFilter(id, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createProduct = createAsyncThunk<any, any, { rejectValue: string }>('Product/create', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await axiosInstance.amanShop.post(PRODUCT_API, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateProduct = createAsyncThunk<any, any, { rejectValue: string }>('Product/update', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await axiosInstance.amanShop.put(PRODUCT_API, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteProduct = (data: any) => axiosInstance.amanShop.delete(`${PRODUCT_API}/${data}`);

export const changeStateProduct = createAsyncThunk<any, any, { rejectValue: string }>(
  'Product/changeState',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await axiosInstance.amanShop.put(PRODUCT_CHANGE_STATUS_API, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
