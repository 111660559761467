import React from 'react';

const OrderHistoryIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H26C27.1 27 28 26.1 28 25V11C28 9.9 27.1 9 26 9ZM26 25H10V11H26V25Z"
        fill="#454550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.41 16.42L23.99 15L20.82 18.17L19.41 16.75L18 18.16L20.82 21L25.41 16.42Z"
        fill="#454550"
      />
      <path d="M16 13H11V15H16V13Z" fill="#454550" />
      <path d="M16 17H11V19H16V17Z" fill="#454550" />
      <path d="M16 21H11V23H16V21Z" fill="#454550" />
    </svg>
  );
};

export default OrderHistoryIcon;
