import React from 'react';

const ArrangeIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M20 26C20 25.4696 19.7893 24.9609 19.4142 24.5858C19.0391 24.2107 18.5304 24 18 24C17.4696 24 16.9609 24.2107 16.5858 24.5858C16.2107 24.9609 16 25.4696 16 26C16 26.5304 16.2107 27.0391 16.5858 27.4142C16.9609 27.7893 17.4696 28 18 28C18.5304 28 19.0391 27.7893 19.4142 27.4142C19.7893 27.0391 20 26.5304 20 26Z"
        fill="#454550"
      />
      <path
        d="M20 18C20 17.4696 19.7893 16.9609 19.4142 16.5858C19.0391 16.2107 18.5304 16 18 16C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18C16 18.5304 16.2107 19.0391 16.5858 19.4142C16.9609 19.7893 17.4696 20 18 20C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18Z"
        fill="#454550"
      />
      <path
        d="M20 10C20 9.46957 19.7893 8.96086 19.4142 8.58579C19.0391 8.21071 18.5304 8 18 8C17.4696 8 16.9609 8.21071 16.5858 8.58579C16.2107 8.96086 16 9.46957 16 10C16 10.5304 16.2107 11.0391 16.5858 11.4142C16.9609 11.7893 17.4696 12 18 12C18.5304 12 19.0391 11.7893 19.4142 11.4142C19.7893 11.0391 20 10.5304 20 10Z"
        fill="#454550"
      />
    </svg>
  );
};

export default ArrangeIcon;
