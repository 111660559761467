import React, { ForwardedRef, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from 'components/Button';
import { InputAdornment, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Iconify from '../Email/Iconify';
// import Box from '@mui/material/Box';
// import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import InputStyle from '../Toolbar/SearchInput';
import { colors } from 'themes/colors';
import { IDialogPageProps, DialogPageRef } from './interface';
import useDebouncedCallback from '../../hooks/useDebounceCallback';
import { DEBOUNCE_TIME } from 'configs/constants';

const DialogCustomize = styled(Dialog)(({ theme }) => ({
  '& div[role="dialog"]': {
    borderRadius: '16px',
  },
  '& .MuiDialogContent-root': {
    padding: '30px 30px 0 30px',
  },
  '& .MuiDialogTitle-root': {
    padding: '25px 30px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    justifyContent: 'center !important',
    marginTop: '35px',
    marginBottom: '30px',
    '& .MuiButton-outlined': {
      borderColor: `${colors._E5E5E5} !important`,
      fontSize: '18px',
      textTransform: 'capitalize',
      marginRight: '30px',
      minHeight: '40px',
      minWidth: '160px',
    },
    '& .MuiButton-contained': {
      fontSize: '18px',
      textTransform: 'capitalize',
      minHeight: '40px',
      minWidth: '160px',
    },
  },
}));

const DialogPage = forwardRef(
  (
    {
      title,
      titleNote = '',
      titleDes,
      hideBackdrop = false,
      hideFooter = false,
      renderBody,
      renderFooter,
      onConfirm,
      scroll = 'paper',
      maxWidth,
      fullHeight = false,
      hasRequiredDesc,
      onProcessClose,
      closeOnOutsideClick = false,
      enableSearch = false,
      onTextSearchChange,
      confirmText,
      cancelText,
    }: IDialogPageProps,
    ref: ForwardedRef<DialogPageRef>,
  ) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [optionParams, setOptionParams] = useState<object | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    React.useImperativeHandle(ref, () => ({
      toggle(params) {
        setOptionParams(params ?? null);
        setIsOpen(!isOpen);
        return !isOpen;
      },
    }));

    const toggleDisable = (isDisabled: boolean) => {
      setDisabled(isDisabled);
    };

    const onCloseHandle = () => {
      if (onProcessClose) onProcessClose();
      setIsOpen(false);
    };

    const onChangedDebounce = useDebouncedCallback((e: any) => {
      if (onTextSearchChange) onTextSearchChange?.(e.target.value);
    }, DEBOUNCE_TIME);

    const onCloseModal = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick' && !closeOnOutsideClick) {
        return;
      }
      onCloseHandle();
    };

    return (
      <DialogCustomize
        open={isOpen}
        onClose={onCloseModal}
        fullWidth={true}
        scroll={scroll}
        maxWidth={maxWidth}
        PaperProps={{
          style: {
            height: fullHeight ? 'inherit' : 'initial',
          },
        }}
        hideBackdrop={hideBackdrop}
        // onBackdropClick={onBackDropHandle}
      >
        {title && (
          <>
            <DialogTitle id="customized-dialog-title">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'block' }}>
                  <Typography variant="h3" component="div" sx={{ color: 'primary.main' }} fontWeight={600}>
                    {title}
                  </Typography>
                  {titleNote && (
                    <Typography variant="h6" component="div" sx={{ color: '#777777' }} fontWeight={400}>
                      {titleNote}
                    </Typography>
                  )}
                  {titleDes && titleDes()}
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {enableSearch && (
                    <InputStyle
                      onChange={onChangedDebounce}
                      stretchStart={350}
                      size="small"
                      placeholder={`${t('common.search')} ...`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={'ant-design:search-outlined'} sx={{ color: colors.primary, width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {hasRequiredDesc && (
                    <Typography variant="h6" component="div" sx={{ marginRight: 5 }}>
                      {t('common.required')}
                    </Typography>
                  )}
                  <IconButton aria-label="close" onClick={onCloseHandle}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider />
          </>
        )}
        <DialogContent sx={{ padding: 10 }}>{renderBody && renderBody({ isOpen, optionParams, toggleDisable })}</DialogContent>
        {!hideFooter && (
          <DialogActions>
            <>
              {renderFooter && renderFooter()}
              {!renderFooter && (
                <>
                  <Button className="form-button" onClick={onCloseHandle} variant="outlined">
                    {cancelText || t('common.cancel')}
                  </Button>
                  <Button className="form-button" onClick={onConfirm} variant="contained" disabled={disabled}>
                    {confirmText || t('common.save')}
                  </Button>
                </>
              )}
            </>
          </DialogActions>
        )}
      </DialogCustomize>
    );
  },
);

export default DialogPage;
