import React from 'react';

const HospitalGalleryEmptyIcon = () => {
  // @ts-ignore
  return (
    <svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="400" height="400" rx="16" fill="#F7F7F7" />
      <path
        d="M240.458 234.583C239.689 233.798 238.641 233.349 237.542 233.333H233.375V162.5C233.375 161.395 232.936 160.335 232.155 159.553C231.373 158.772 230.314 158.333 229.209 158.333H170.833C170.282 158.335 169.737 158.447 169.229 158.662C168.721 158.876 168.261 159.189 167.875 159.583C167.105 160.359 166.671 161.407 166.667 162.5V233.333H162.5C161.949 233.335 161.404 233.447 160.896 233.662C160.388 233.876 159.928 234.189 159.542 234.583C158.772 235.359 158.338 236.407 158.333 237.5C158.333 238.605 158.773 239.664 159.554 240.446C160.335 241.227 161.395 241.666 162.5 241.666H237.5C238.593 241.662 239.641 241.228 240.417 240.458C240.811 240.072 241.124 239.612 241.338 239.104C241.553 238.596 241.665 238.051 241.667 237.5C241.662 236.407 241.228 235.359 240.458 234.583ZM204.209 233.333H195.875V216.666H204.209V233.333ZM225.042 233.333H212.542V212.5C212.542 211.395 212.103 210.335 211.321 209.553C210.54 208.772 209.48 208.333 208.375 208.333H191.709C191.157 208.335 190.612 208.447 190.104 208.662C189.596 208.876 189.136 209.189 188.75 209.583C187.98 210.359 187.546 211.407 187.542 212.5V233.333H175.042V166.666H225.042V233.333Z"
        fill="#00C2CB"
      />
      <path
        d="M191.667 190.667H195.833V194.833C195.838 195.926 196.272 196.974 197.042 197.75C197.428 198.144 197.888 198.457 198.396 198.671C198.903 198.886 199.449 198.998 200 199C201.105 199 202.165 198.561 202.946 197.78C203.728 196.998 204.167 195.938 204.167 194.833V190.667H208.333C209.438 190.667 210.498 190.228 211.28 189.446C212.061 188.665 212.5 187.605 212.5 186.5C212.498 185.949 212.386 185.403 212.171 184.896C211.957 184.388 211.644 183.927 211.25 183.542C210.474 182.772 209.426 182.338 208.333 182.333H204.167V178.167C204.164 177.615 204.053 177.07 203.838 176.562C203.624 176.054 203.31 175.594 202.917 175.208C202.141 174.438 201.093 174.005 200 174C198.895 174 197.835 174.439 197.054 175.22C196.272 176.002 195.833 177.062 195.833 178.167V182.333H191.667C190.562 182.333 189.502 182.772 188.72 183.554C187.939 184.335 187.5 185.395 187.5 186.5C187.505 187.593 187.939 188.641 188.708 189.417C189.094 189.81 189.554 190.124 190.062 190.338C190.57 190.553 191.115 190.664 191.667 190.667Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default HospitalGalleryEmptyIcon;
