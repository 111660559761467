import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IProductState } from './interface';
import {
  fetchProductGrid,
  createProduct,
  updateProduct,
  fetchProductDetail,
  fetchProductCategory,
  fetchAllProduct,
  fetchAttributeCategory,
} from './productsAPI';

const initialState: IProductState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
  productDetail: null,
  dataListAllProduct: [],
  categories: [],
  attributeCategories: [],
};

export const Products = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditProduct(state, action) {
      state.isUpdated = false;
    },
    requestCreateProduct(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataListAllProduct = payload;
      })
      .addCase(fetchAllProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchProductGrid.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductGrid.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchProductGrid.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchProductCategory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.categories = payload;
      })
      .addCase(fetchProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      });
    builder
      .addCase(fetchAttributeCategory.pending, (state) => {})
      .addCase(fetchAttributeCategory.fulfilled, (state, { payload }) => {
        state.attributeCategories = payload;
      })
      .addCase(fetchAttributeCategory.rejected, (state, { payload }) => {});
    builder
      .addCase(createProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateProduct.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchProductDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productDetail = payload;
      })
      .addCase(fetchProductDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError, requestCreateProduct, requestEditProduct } = Products.actions;
export const usersSelector = (state: RootState) => state.amanshopProducts;

export default Products.reducer;
