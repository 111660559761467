import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseGoldmine, IGoldmineData } from './interface';
import cloneDeep from 'lodash/cloneDeep';

export const fetchGoldmineByFilter = (data: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/goldmine-company-grid`, { params: data });
};

export const fetchGoldmine = createAsyncThunk<IResponseGoldmine, any, { rejectValue: string }>(
  'Goldmine/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchGoldmineByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const detailGoldmine = createAsyncThunk<IGoldmineData, any, { rejectValue: string }>(
  'Goldmine/detail',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/goldmine-company/${payload}`);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteGoldmine = (data: any) => httpRequest.amanSale.delete(`${PREFIX}/goldmine-company/${data}`);

export const fetchGoldmineSource = () => httpRequest.amanSale.get(`${PREFIX}/get-goldmine-source`);

export const scrapeGoldmineCompany = createAsyncThunk<any, any, { rejectValue: string }>(
  'Goldmine/scrape',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/goldmine-company/scrape`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const sendGoldmineCompany = createAsyncThunk<any, any, { rejectValue: string }>(
  'Goldmine/send',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/goldmine-company/send`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getScrapeGoldmineCompany = (data: any, params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/goldmine-company/scrape/${data}`, { params: params });
};

export const undoScrapeGoldmineCompany = createAsyncThunk<any, any, { rejectValue: string }>(
  'Goldmine/undo',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('scrapeGoldmineTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.amanSale.post(`${PREFIX}/goldmine-company/undo`, { ScrapeTransId: transId });
        localStorage.removeItem('scrapeGoldmineTransId');
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const setOtpGoldmineCompany = createAsyncThunk<any, any, { rejectValue: string }>(
  'Goldmine/set-otp',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/goldmine-company/set-otp`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const settingGoldmineCompany = createAsyncThunk<any, any, { rejectValue: string }>(
  'Goldmine/setting',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/goldmine-company/setting`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
