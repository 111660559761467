import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appReducer from './app/appSlice';
import authReducer from './auth/authSlice';
import employersReducer from './employers/employersSlice';
import usersReducer from './users/usersSlice';
import userExtractReducer from './user-extract/userExtractSlice';
import userAccessManagementReducer from './user-access-management/userAccessManagementSlice';
import claimsReducer from './claims/claimsSlice';
import invoicesReducer from './invoices/invoicesSlice';
import employeesReducer from './employees/employeesSlice';
import employeeChangeHistoryReducer from './employee-change-history/employeeChangeHistorySlice';
import employeeExtractReducer from './employee-extract/employeeExtractSlice';
import employeeEmploymentInformationReducer from './employee-employment-information/employmentInformationSlice';
import employeePolicyReducer from './employee-policy/employeePolicySlice';
import employeeFamilyReducer from './employee-family/employeeFamilySlice';
import employeeBankAccountReducer from './employee-bank-account/employeeBankAccountSlice';
import employeeClaimReducer from './employee-claim/employeeClaimSlice';
import employeeMedicalHistoryReducer from './employee-medical-history/employeeMedicalHistorySlice';
import phoneTypesReducer from './phone-types/phoneTypesSlice';
import currenciesReducer from './settings/currencies/currenciesSlice';
import banksReducer from './banks/banksSlice';
import hospitalsReducer from './hospitals/hospitalsSlice';
import familiesReducer from './families/familySlice';
import insuranceProductReducer from './insurance-product/insuranceProductSlice';
import icdCodesReducer from './icd-codes/icdCodeSlice';
import medicalsReducer from './medical/medicalSlice';
import brokersReducer from './settings/brokers/brokersSlice';
import streamsReducer from './streams/streamsSlice';
import hospitalConfigReducer from './hospital-config/hospitalConfigSlice';
import hospitalTypesReducer from './hospital-types/hospitalTypesSlice';
import hospitalRatingsReducer from './hospital-rating/hospitalRatingsSlice';
import hospitalDepartmentsReducer from './hospital-departments/hospitalDepartmentsSlice';
import locationReducer from './location/locationSlice';
import hospitalServicesReducer from './hospital-service/hospitalServicesSlice';
import hospitalPersonnelsReducer from './hospital-personnel/hospitalPersonnelsSlice';
import historyChangeReducer from './history-change/historySlice';
import batchReducer from './batch/batchSlice';
import merchantReducer from './amanshop-merchants/merchantsSlice';
import storeReducer from './amanshop-stores/storesSlice';
import orderReducer from './amanshop-orders/ordersSlice';
import storeDeliverysReducer from './amanshop-store-delivery/storeDeliverysSlice';
import storeVouchersReducer from './amanshop-store-voucher/storeVouchersSlice';
import productReducer from './amanshop-products/productsSlice';
import productRatingsReducer from './amanshop-product-rating/productRatingsSlice';
import discountReducer from './amanshop-discount/discountsSlice';
import bankAccountReducer from './amanshop-merchant-bankaccount/bankAccountsSlice';
import paymentInfoReducer from './amanshop-merchant-payment/paymentInfoSlice';

// Aman sale
import saleLeadReducer from './amansale-lead/leadsSlice';
import saleLeadStatusReducer from './amansale-leadstatus/leadStatussSlice';
import saleLeadHistoryReducer from './amansale-lead-history/leadHistorySlice';
import saleExchangeReducer from './amansale-exchange/exchagesSlice';
import goldmineReducer from './goldmine/goldmineSlice';

// Settings
import insurerReducer from './settings/insurers/insurerSlice';
import insurerHospitalConfigReducer from './settings/hospital-config/hospitalConfigSlice';
import holidaysReducer from './settings/holidays/holidaysSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    employers: employersReducer,
    users: usersReducer,
    userExtract: userExtractReducer,
    userAccessManagement: userAccessManagementReducer,
    claims: claimsReducer,
    invoices: invoicesReducer,
    employees: employeesReducer,
    employeeChangeHistory: employeeChangeHistoryReducer,
    employeeExtract: employeeExtractReducer,
    employeeEmploymentInformation: employeeEmploymentInformationReducer,
    employeePolicy: employeePolicyReducer,
    employeeFamily: employeeFamilyReducer,
    employeeBankAccount: employeeBankAccountReducer,
    employeeClaim: employeeClaimReducer,
    employeeMedicalHistory: employeeMedicalHistoryReducer,
    phoneTypes: phoneTypesReducer,
    currencies: currenciesReducer,
    banks: banksReducer,
    families: familiesReducer,
    insuranceProduct: insuranceProductReducer,
    hospitals: hospitalsReducer,
    icdCodes: icdCodesReducer,
    medicals: medicalsReducer,
    brokers: brokersReducer,
    streams: streamsReducer,
    hospitalTypes: hospitalTypesReducer,
    hospitalRatings: hospitalRatingsReducer,
    locations: locationReducer,
    hospitalConfigs: hospitalConfigReducer,
    hospitalServices: hospitalServicesReducer,
    hospitalDepartments: hospitalDepartmentsReducer,
    hospitalPersonnels: hospitalPersonnelsReducer,
    histories: historyChangeReducer,
    batch: batchReducer,
    amanshopMerchants: merchantReducer,
    amanshopStores: storeReducer,
    amanshopOrders: orderReducer,
    amanshopStoreDeliverys: storeDeliverysReducer,
    amanshopStoreVouchers: storeVouchersReducer,
    amanshopProducts: productReducer,
    amanshopProductRatings: productRatingsReducer,
    amanshopDiscounts: discountReducer,
    amanshopBankAccount: bankAccountReducer,
    amanshopPaymentInfo: paymentInfoReducer,
    saleLeadReducer: saleLeadReducer,
    saleLeadStatusReducer: saleLeadStatusReducer,
    saleLeadHistoryReducer: saleLeadHistoryReducer,
    saleExchangeReducer: saleExchangeReducer,
    goldmine: goldmineReducer,
    insurers: insurerReducer,
    hospitalConfig: insurerHospitalConfigReducer,
    holidays: holidaysReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
