import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IMedicalState } from './interface';
import { fetchMedical, updateMedical, createMedical, fetchMedicalDetail, fetchMedicalHospital } from './medicalAPI';

const initialState: IMedicalState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
  medicalDetail: null,
  medicalHospitalList: [],
  totalCountMedicalHospital: 0,
};

export const medical = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditMedical(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedical.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMedical.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchMedical.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createMedical.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createMedical.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createMedical.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    // Update medical
    builder
      .addCase(updateMedical.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateMedical.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateMedical.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchMedicalDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMedicalDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.medicalDetail = payload.Data;
      })
      .addCase(fetchMedicalDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    // Fetch medical hospital list
    builder
      .addCase(fetchMedicalHospital.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMedicalHospital.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.medicalHospitalList = payload.data;
        state.totalCountMedicalHospital = payload.totalCount;
      })
      .addCase(fetchMedicalHospital.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditMedical } = medical.actions;
export const medicalsSelector = (state: RootState) => state.medicals;

export default medical.reducer;
