import React from 'react';

const ExchangeBid = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M24 12V16.5C23.2979 16.4992 22.6035 16.6466 21.9623 16.9327C21.321 17.2188 20.7474 17.637 20.279 18.16C19.4539 19.0766 18.9982 20.2667 19 21.5C19.0003 22.385 19.2353 23.2541 19.6809 24.0187C20.1266 24.7833 20.7671 25.4161 21.537 25.8525C20.206 26.255 18.439 26.5 16.5 26.5C12.358 26.5 9 25.3805 9 24V12"
        stroke="#454550"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29 21.5C29 24.2615 26.7615 26.5 24 26.5C23.105 26.5 22.264 26.265 21.537 25.8525C20.7671 25.4161 20.1266 24.7833 19.6809 24.0187C19.2353 23.2541 19.0003 22.385 19 21.5C19 20.216 19.484 19.045 20.279 18.16C20.7474 17.637 21.321 17.2188 21.9623 16.9327C22.6035 16.6466 23.2979 16.4992 24 16.5C26.7615 16.5 29 18.7385 29 21.5ZM24 12C24 13.3805 20.642 14.5 16.5 14.5C12.358 14.5 9 13.3805 9 12C9 10.6195 12.358 9.5 16.5 9.5C20.642 9.5 24 10.6195 24 12Z"
        stroke="#454550"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 20C9 21.3805 12.358 22.5 16.5 22.5C17.4035 22.5 18.27 22.447 19.072 22.349M9 16C9 17.3805 12.358 18.5 16.5 18.5C17.878 18.5 19.1695 18.376 20.279 18.16M25.5 20L22.5 23"
        stroke="#454550"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ExchangeBid;
