import { memo } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const TableHeaderRowCell = ({ ...restProps }: any) => {
  const {
    column: { title, rightAction, hoverAction },
    style,
    className,
  } = restProps;

  if (title === null || title === '') {
    return <TableHeaderRow.Cell {...restProps} className="MuiTableCell-hidden" />;
  }

  if (rightAction) {
    return (
      <TableHeaderRow.Cell
        {...restProps}
        className={`${className} MuiTableCell-hidden`}
        style={{
          display: 'none',
          ...style,
        }}
      />
    );
  }

  if (hoverAction) {
    return (
      <TableHeaderRow.Cell
        {...restProps}
        className={`${className} MuiTableCell-hidden`}
        style={{
          display: 'none',
          ...style,
        }}
      />
    );
  }

  return <TableHeaderRow.Cell {...restProps} />;
};

export default memo(TableHeaderRowCell);
