import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { FETCH_ORDER_GRID_API, PREFIX, PREFIX_MOBILE_INSURANCE } from './apiUrls';
import { IResponseOrders, IResponseOrderDetail } from './interface';

export const fetchOrderGridByFilter = (data: any) => {
  return httpRequest.amanShop.get(FETCH_ORDER_GRID_API, { params: data });
};

export const fetchOrderDetailByFilter = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/${data}`, { params: params });
};

export const fetchOrderGrid = createAsyncThunk<IResponseOrders, any, { rejectValue: string }>(
  'Order/fetch-grid',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchOrderGridByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchOrderDetail = createAsyncThunk<IResponseOrderDetail, any, { rejectValue: string }>(
  'Order-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchOrderDetailByFilter(id, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createOrder = createAsyncThunk<any, any, { rejectValue: string }>('Order/create', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateOrder = createAsyncThunk<any, any, { rejectValue: string }>('Order/update', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteOrder = (data: any) => httpRequest.amanShop.delete(`${PREFIX}/${data}`);

export const fetchDiagnosticsDetail = (data: any, params: any) => {
  return httpRequest.insurance.get(`${PREFIX_MOBILE_INSURANCE}/detail-diagnostics/${data}`, { params: params });
};

export const fetchOrderSumary = (params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/sumary`, { params: params });
};

export const fetchOrderHistory = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/history/${data}`, { params: params });
};

export const fetchInfoPaymentMerchant = (params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/getInfoPaymentMerchant`, { params: params });
};

export const rejectOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/reject`, data);
};

export const refundConfirmOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/refundConfirm`, data);
};

export const confirmOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/confirm`, data);
};

export const setDeliveryOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/setDelivery`, data);
};

export const resetDeliveryOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/resetDelivery`, data);
};

export const transactionDoneOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/transactionDone`, data);
};

export const paymentConfirmOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/paymentConfirm`, data);
};

export const paymentConfirmMultiOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/paymentConfirmMulti`, data);
};

export const rejectComplaintOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/rejectComplaint`, data);
};

export const acceptComplaintOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/acceptComplaint`, data);
};

export const transactionConfirmComplaintOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/transactionConfirmComplaint`, data);
};

export const complaintMerchantOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/complaintMerchant`, data);
};

export const finalSolutionAdminOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/finalSolutionAdmin`, data);
};

export const finalDecisionAdminOrder = (data: any) => {
  return httpRequest.amanShop.post(`${PREFIX}/finalDecisionAdmin`, data);
};
