import React, { memo } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { TableEditColumn, TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import Button from 'components/Button';
import { EditIcon } from 'components/Svg';
import { useTranslation } from 'react-i18next';

const TableEditColumnCell = ({ customchildren, customcss, ...restProps }: any) => {
  const { tableRow, children, style, className } = restProps;

  const { t } = useTranslation();

  const isEdit = tableRow.type === TableEditRow.EDIT_ROW_TYPE;
  const isNew = tableRow.type === TableEditRow.ADDED_ROW_TYPE;
  const isEditing = isEdit || isNew;

  const defaultChildren = [];
  if (isEditing) {
    defaultChildren.push(false);
    defaultChildren.push(false);
  } else {
    defaultChildren.push(false);
    defaultChildren.push(false);
    defaultChildren.push(false);
  }
  children.forEach((item: React.ReactElement, index: number) => {
    if (item && item.props?.id === 'edit') {
      defaultChildren.unshift(
        <Tooltip key={index} title={t('common.edit')}>
          <IconButton
            size={'small'}
            sx={{ width: 48, height: 48 }}
            style={{ marginRight: '10px' }}
            onClick={(e) => {
              e.stopPropagation();
              item.props.onExecute();
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>,
      );
    }
    if (item && item.props?.id === 'cancel') {
      defaultChildren.push(
        <Button
          variant="outlined"
          style={{ marginRight: '10px', borderColor: '#E5E5E5' }}
          onClick={(e) => {
            e.stopPropagation();
            item.props.onExecute();
          }}
        >
          {t('common.cancel')}
        </Button>,
      );
    }
    if (item && item.props?.id === 'commit') {
      defaultChildren.push(
        <Button
          style={{ marginRight: '10px' }}
          onClick={(e) => {
            e.stopPropagation();
            item.props.onExecute();
          }}
        >
          {t('common.save')}
        </Button>,
      );
    }
  });

  if (isEditing) {
    const b = defaultChildren[3];
    defaultChildren[3] = defaultChildren[2];
    defaultChildren[2] = b;
  }

  return (
    <TableEditColumn.Cell
      {...restProps}
      className={`${className} ${isEditing ? '' : 'MuiTableCell-custom-hover'}`}
      style={{
        borderLeft: '0px',
        background: 'transparent',
        textAlign: 'right',
        ...customcss,
        ...style,
      }}
    >
      {customchildren ? customchildren(defaultChildren) : defaultChildren}
    </TableEditColumn.Cell>
  );
};

export default memo(TableEditColumnCell);
