import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchEmployeeExtract } from './employeeExtractAPIs';
import { IEmployeeExtractState } from './interface';

const initialState: IEmployeeExtractState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
};

export const employeeExtractSlice = createSlice({
  name: 'employeeExtract',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeExtract.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeExtract.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployeeExtract.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = employeeExtractSlice.actions;
export const employeeExtractSelector = (state: RootState) => state.employeeExtract;

export default employeeExtractSlice.reducer;
