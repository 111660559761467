import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchEmployeeBankAccount, createEmployeeBankAccount, updateEmployeeBankAccount } from './employeeBankAccountAPIs';
import { IEmployeeBankAccountState } from './interface';

const initialState: IEmployeeBankAccountState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
};

export const employeeBankAccountSlice = createSlice({
  name: 'employeeBankAccount',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeBankAccount.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeBankAccount.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployeeBankAccount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createEmployeeBankAccount.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createEmployeeBankAccount.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createEmployeeBankAccount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateEmployeeBankAccount.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateEmployeeBankAccount.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateEmployeeBankAccount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = employeeBankAccountSlice.actions;
export const employeeBankAccountSelector = (state: RootState) => state.employeeBankAccount;

export default employeeBankAccountSlice.reducer;
