import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import {
  fetchLeads,
  createLead,
  updateLead,
  getLeadSource,
  getLeadIndustry,
  getAllLead,
  fetchLeadDetail,
  getLeadAvgAge,
  actionChangeLead,
  sendToExchange,
  fetchMergeLeadsInfo,
  fetchLeadBulkTemplate,
  updateLeadBulkTemplate,
  applyImportLeadBulk,
  undoImportLeadBulk,
  updateFollowers,
  updateWatchOrUnwatch,
} from './leadsAPIs';
import { ILeadState } from './interface';

const initialState: ILeadState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  leadDetail: undefined,
  isCreated: false,
  leadSource: [],
  leadIndustry: [],
  allLead: [],
  leadAvgAge: [],
  mergeListInfo: undefined,
  isBulkFetching: false,
  leadBulkTemplate: undefined,
};

export const Leads = createSlice({
  name: 'Leads',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditLead(state, action) {
      state.isUpdated = false;
    },
    requestCreateLead(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeads.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchLeads.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateWatchOrUnwatch.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateWatchOrUnwatch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        const findIndex = state.dataList.findIndex((i) => i.Id === payload.Id);

        console.log('payload', payload);
        console.log('findIndex', findIndex);

        if (findIndex !== -1) {
          state.dataList[findIndex] = payload;
        }
      })
      .addCase(updateWatchOrUnwatch.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder.addCase(getLeadSource.fulfilled, (state, { payload }) => {
      state.leadSource = payload || [];
    });
    builder.addCase(getLeadIndustry.fulfilled, (state, { payload }) => {
      state.leadIndustry = payload || [];
    });
    builder.addCase(getAllLead.fulfilled, (state, { payload }) => {
      state.allLead = payload || [];
    });
    builder.addCase(getLeadAvgAge.fulfilled, (state, { payload }) => {
      state.leadAvgAge = payload || [];
    });
    builder
      .addCase(fetchLeadDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.leadDetail = payload;
      })
      .addCase(fetchLeadDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createLead.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createLead.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createLead.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateLead.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateLead.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateLead.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(actionChangeLead.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(actionChangeLead.fulfilled, (state, _) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(actionChangeLead.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(sendToExchange.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(sendToExchange.fulfilled, (state, _) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(sendToExchange.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchMergeLeadsInfo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMergeLeadsInfo.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.mergeListInfo = payload.Data;
      })
      .addCase(fetchMergeLeadsInfo.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateFollowers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateFollowers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.leadDetail = payload;
      })
      .addCase(updateFollowers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchLeadBulkTemplate.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(fetchLeadBulkTemplate.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isSuccess = true;
        state.leadBulkTemplate = payload.Data;
      })
      .addCase(fetchLeadBulkTemplate.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateLeadBulkTemplate.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(updateLeadBulkTemplate.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(updateLeadBulkTemplate.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(applyImportLeadBulk.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(applyImportLeadBulk.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(applyImportLeadBulk.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(undoImportLeadBulk.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(undoImportLeadBulk.fulfilled, (state, { payload }) => {
        state.isBulkFetching = false;
      })
      .addCase(undoImportLeadBulk.rejected, (state, { payload }) => {
        state.isBulkFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateLead, requestEditLead, requestClearError } = Leads.actions;
export const LeadsSelector = (state: RootState) => state.saleLeadReducer;

export default Leads.reducer;
