import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeClaimResponse } from './interface';

export const fetchEmployeeClaimByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/account`, { params: data });
};

export const fetchEmployeeClaim = createAsyncThunk<IEmployeeClaimResponse, any, { rejectValue: string }>(
  'EmployeeClaim/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchEmployeeClaimByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
