import React from 'react';

const ExchangeWithdraw = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M25.5 18.0202V13.7077L21.5625 8.91602H12.375C11.8918 8.91602 11.5 9.34508 11.5 9.87435V27.1243C11.5 27.6536 11.8918 28.0827 12.375 28.0827H17.625"
        stroke="#454550"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5625 22.8125L19.375 25.2083L21.5625 27.6042"
        stroke="#454550"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M19.375 25.2083H26.375V21.375" stroke="#454550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.125 8.91602V13.7077H25.5" stroke="#454550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default ExchangeWithdraw;
