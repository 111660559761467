import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IInsuranceProductState } from './interface';
import { createInsuranceProduct, fetchInsuranceProducts, updateInsuranceProduct } from './insuranceProductAPI';

const initialState: IInsuranceProductState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
};

export const insuranceProducts = createSlice({
  name: 'insuranceProducts',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditInsuranceProduct(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsuranceProducts.pending, (state) => {
        state.isFetching = true;
        state.isCreated = false;
        state.isUpdated = false;
      })
      .addCase(fetchInsuranceProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchInsuranceProducts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createInsuranceProduct.pending, (state) => {
        state.isFetching = true;
        state.isCreated = false;
      })
      .addCase(createInsuranceProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createInsuranceProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateInsuranceProduct.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateInsuranceProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateInsuranceProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditInsuranceProduct } = insuranceProducts.actions;
export const usersSelector = (state: RootState) => state.users;

export default insuranceProducts.reducer;
