import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/index';
import { IBrokerState } from './interface';
import { fetchBrokers, fetchCountryNames, updateBroker } from './brokersAPI';

const initialState: IBrokerState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
  countryNames: {
    Data: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errMsg: '',
  },
};

export const icd_codes = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditBroker(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrokers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBrokers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchBrokers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchCountryNames.pending, (state) => {
        state.countryNames.isFetching = true;
      })
      .addCase(fetchCountryNames.fulfilled, (state, { payload }) => {
        state.countryNames.isFetching = false;
        state.countryNames.isSuccess = true;
        state.countryNames.Data = payload.Data;
      })
      .addCase(fetchCountryNames.rejected, (state, { payload }) => {
        state.countryNames.isFetching = false;
        state.countryNames.isError = true;
        state.countryNames.errMsg = payload || '';
      });

    builder
      .addCase(updateBroker.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateBroker.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateBroker.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditBroker } = icd_codes.actions;
export const usersSelector = (state: RootState) => state.users;

export default icd_codes.reducer;
