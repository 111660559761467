const StarIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M10 5.13L11.44 8.53L12.64 8.63L15.11 8.84L12.32 11.26L12.59 12.44L13.15 14.85L11.03 13.57L10 12.93L8.97 13.55L6.85 14.83L7.41 12.42L7.68 11.24L4.89 8.82L7.36 8.61L8.56 8.51L9.03 7.4L10 5.13ZM10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIcon;
