import React, { useCallback, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { Dialog, DialogProps, DialogContent, DialogContentText, DialogTitle, DialogActions, IconButton } from '@mui/material';
import Button from 'components/Button';
import { Close } from '@mui/icons-material';

export type OpenModal = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onConfirm?: (e: React.SyntheticEvent, close: Function) => void;
  autoClose?: boolean;
  closeOnConfirm?: boolean;
  formId?: string;
  confirmButtonText?: React.ReactNode;
  cancelButtonText?: React.ReactNode;
  onClose?: () => void;
};

const useDialogState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [openModal, setOpenModal] = useState<OpenModal | undefined>();

  const reset = useCallback(() => {
    setOpenModal(undefined);
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    setIsOpen,
    openModal,
    setOpenModal,
    reset,
  };
};

const DialogState = createContainer(useDialogState);

export const useModal = () => {
  const { setIsOpen, setOpenModal, reset } = DialogState.useContainer();

  const openModal = useCallback((config: OpenModal) => {
    setOpenModal(config);
    setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openModal, closeModal };
};

const DialogProviderView = ({ children }: any) => {
  const { isOpen, openModal, reset } = DialogState.useContainer();

  const {
    title,
    description,
    fullScreen,
    fullWidth,
    maxWidth,
    onConfirm,
    autoClose = true,
    closeOnConfirm = true,
    formId,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onClose,
  } = openModal || {};

  const hasConfirm = useMemo(() => typeof onConfirm === 'function' || !!formId, [onConfirm, formId]);

  const handleConfirm = useCallback(
    (e: React.SyntheticEvent) => {
      if (typeof onConfirm === 'function') {
        // @ts-ignore
        onConfirm(e, reset);
      }

      if (closeOnConfirm) {
        reset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onConfirm, reset],
  );

  const confirmProps = useMemo(() => {
    const props = {
      autoFocus: true,
      type: !!formId ? 'submit' : 'button',
      onClick: !!formId ? undefined : handleConfirm,
      form: formId,
    } as any;

    return props;
  }, [formId, handleConfirm]);

  const handleClose = () => {
    if (onClose) {
      // @ts-ignore
      onClose();
    }
  };

  return (
    <>
      {children}

      <Dialog
        open={isOpen}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ marginBottom: !title ? 2 : undefined }}>
          {title}

          {!hasConfirm && isOpen && (
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>

        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
        )}

        {hasConfirm && (
          <DialogActions>
            {autoClose && (
              <Button color="error" onClick={reset}>
                {cancelButtonText}
              </Button>
            )}
            <Button {...confirmProps}>{autoClose ? confirmButtonText : 'OK'}</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export const DialogProvider = (props: { children: React.ReactNode }) => (
  <DialogState.Provider>
    <DialogProviderView {...props} />
  </DialogState.Provider>
);
