import { memo } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const SortingIcon = ({ direction }: any) =>
  direction === 'asc' ? (
    <ArrowDropUp style={{ fontSize: '18px', marginLeft: '5px' }} />
  ) : (
    <ArrowDropDown style={{ fontSize: '18px', marginLeft: '5px' }} />
  );

const TableHeaderRowSortLabel = ({ ...restProps }: any) => {
  const { onSort, children, direction } = restProps;

  return (
    <div style={{ display: 'contents' }} onClick={onSort}>
      {children}
      {direction && <SortingIcon direction={direction} />}
    </div>
  );
};

export default memo(TableHeaderRowSortLabel);
