import httpRequest from 'services';
import { API_VERSION_2 } from 'configs/constants';
import { FETCH_CLAIMS } from 'state/claims/apiUrls';
import { delay } from 'utils/services';

const PREFIX = `${API_VERSION_2}/Claim`;
const API_EXTRACT_CLAIM_CSV = `${PREFIX}/extractClaimsV2`;
const GET_STATUS_URL = '/v2.0/Claim/extractClaims/getStatus/';
const URL_DOWNLOAD = '/v2.0/Claim/extractClaims/download';
const API_DOWN_CLAIM_APPLICATION = '/v1.0/Claim/downloadClaim';

export const extractClaimCsv = async (payload: { claimIds: string[] }) => {
  try {
    const processingData = await httpRequest.insurance.post(API_EXTRACT_CLAIM_CSV, payload);
    const processingDataId = processingData.data?.Data;
    let status = 1;
    while (true) {
      await delay(3000);
      const getStatus = await httpRequest.insurance.get(`${GET_STATUS_URL}${processingDataId}`);
      status = getStatus.data.Data?.Status;
      if (status === 2) {
        break;
      }
    }

    const data = await httpRequest.insurance.post(
      URL_DOWNLOAD,
      { ProcessingDataId: processingDataId },
      {
        responseType: 'arraybuffer',
        timeout: 0,
      },
    );

    return data;
  } catch (err: any) {
    return err;
  }
};

export const downloadClaimApplication = async (payload: { claimId: string }) => {
  try {
    const data = await httpRequest.insurance.get(API_DOWN_CLAIM_APPLICATION, {
      responseType: 'arraybuffer',
      timeout: 0,
      params: payload,
    });

    return data;
  } catch (err: any) {
    return err;
  }
};

export const getClaims = async () => {
  try {
    const { data } = await httpRequest.insurance.post(FETCH_CLAIMS, {});
    return data;
  } catch (err) {
    return err;
  }
};
