import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { downloadFile } from 'helpers/downloadFile';
import httpRequest from 'services';
import {
  DOWNLOAD_INVOICE,
  FETCH_INVOICES,
  IMPORT_PAYMENT_INVOICES,
  PREIVEW_IMPORT_PAYMENT_INVOICES,
  APPLY_IMPORT_PAYMENT_INVOICES,
} from './apiUrls';

import { IInvoice } from './interface';

// export async function fetchInvoices(): Promise<AxiosResponse> {
//   return httpRequest.insurance.post(FETCH_INVOICES, {});
// }

export const fetchInvoices = createAsyncThunk<any, any, { rejectValue: string }>('invoices/fetch', async (payload, thunkAPI) => {
  try {
    const { data } = await httpRequest.insurance.post(FETCH_INVOICES, { params: payload });
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export async function importPaymentInvoices(payload: FormData): Promise<AxiosResponse> {
  return httpRequest.insurance.post(IMPORT_PAYMENT_INVOICES, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function previewImportPaymentInvoices(importTransId: number): Promise<AxiosResponse> {
  return httpRequest.insurance.get(PREIVEW_IMPORT_PAYMENT_INVOICES + `/${importTransId}`);
}

export async function applyImportPaymentInvoices(importTransId: number): Promise<AxiosResponse> {
  return httpRequest.insurance.post(APPLY_IMPORT_PAYMENT_INVOICES, { importTransId });
}

export const downloadInvoice = createAsyncThunk<boolean, IInvoice, { rejectValue: string }>(
  'invoices/download',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(DOWNLOAD_INVOICE + `/${payload.Id}`);
      downloadFile(data?.Data, `${payload.InvoiceNumber}.pdf`);
      return true;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
