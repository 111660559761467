import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function BackdropDialog() {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      style={{ backgroundColor: 'transparent' }}
      open
    >
      <CircularProgress style={{ color: '#00C2CB' }} />
    </Backdrop>
  );
}

export default BackdropDialog;
