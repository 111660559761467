import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import ProgressBar from '@ramonak/react-progress-bar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'components/Button';
import DialogPage from 'components/DialogPage/DialogPage';
import { useDispatch } from 'react-redux';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { FirebaseAman } from 'firebaseAman';
import { applyImportLeadBulk, getImportLead, undoImportLeadBulk } from 'state/amansale-lead/leadsAPIs';
import { enqueueSnackbar, setImportLead } from 'state/app/appSlice';

interface IImportLeadProgress {
  importLead: any;
}

const ImportLeadProgress = ({ importLead }: IImportLeadProgress) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const unsubscribeRef = useRef<any>();
  const dialogApplyRef = useRef<any>(null);
  const dialogErrorRef = useRef<any>(null);
  const dialogResumeRef = useRef<any>(null);

  const [failedImportedCount, setFailedImportedCount] = useState(0);
  const [rejectReasons, setRejectReasons] = useState([]);
  const [isApply, setIsApply] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [dataForm, setDataForm] = useState({
    TotalRecord: 0,
    InsertedRecord: 0,
    AppliedRecord: 0,
    TotalApplyRecord: 0,
  });

  useEffect(() => {
    let transId = localStorage.getItem('importLeadTransId') || '0';
    if (importLead) {
      const { ImportTransId } = importLead;
      if (transId !== '0' && transId !== `${ImportTransId}`) {
        dispatch(undoImportLeadBulk({}));
      }
      localStorage.setItem('importLeadTransId', ImportTransId);
      transId = `${ImportTransId}`;
      setVisible(true);
      setIsProcessing(true);
    }
    // Firestore
    if (unsubscribeRef.current) unsubscribeRef.current();
    getImportLead(transId, {})
      .then((result) => {
        const data = result.data?.Data;
        // Check resume
        let isResume = false;
        if (data && transId === '0') {
          if (data.IsCompleted) {
            transId = `${data.ImportTransId}`;
            localStorage.setItem('importLeadTransId', data.ImportTransId);
            setVisible(false);
            setIsProcessing(false);
            dialogResumeRef.current?.toggle();
            isResume = true;
          } else {
            localStorage.setItem('importLeadTransId', data.ImportTransId);
            onUndoImport();
          }
        }
        if (transId !== '0') {
          const db = getFirestore(FirebaseAman);
          const unsubscribe = onSnapshot(
            doc(db, 'ImportLead', `ImportLeadId-${transId}`),
            (docSnapshot) => {
              const source = docSnapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
              console.log(source, ' data: ', docSnapshot.data());
              if (docSnapshot.data()) {
                let {
                  ImportState,
                  InsertedRecord = 0,
                  TotalRecord = 0,
                  IsCompleted,
                  IsError,
                  IsApplied,
                  AppliedRecord = 0,
                  TotalApplyRecord = 0,
                  FailedImportedCnt,
                  RejectReasons,
                  ErrorMsg,
                } = docSnapshot.data() as any;
                if (isResume) {
                  IsApplied = false;
                }
                setFailedImportedCount(FailedImportedCnt);
                if (RejectReasons) {
                  const rejectReasons = JSON.parse(RejectReasons);
                  setRejectReasons(rejectReasons);
                }
                setVisible((!IsCompleted && !IsError) || (!IsApplied && TotalApplyRecord > 0));
                setIsProcessing(false);
                setDataForm((prev) => ({
                  ...prev,
                  InsertedRecord,
                  TotalRecord,
                  AppliedRecord,
                  TotalApplyRecord,
                }));
                if (ImportState === 2 && IsCompleted && !IsApplied && !IsError) {
                  if (FailedImportedCnt > 0) {
                    dialogErrorRef.current?.toggle();
                  } else {
                    dialogApplyRef.current?.toggle();
                  }
                }
                if (IsApplied || IsError) {
                  if (IsApplied) {
                    dispatch(
                      enqueueSnackbar({
                        message: `You have successfully updated and/or added ${TotalApplyRecord} leads`,
                        variant: 'success',
                      }),
                    );
                  }
                  if (IsError) {
                    dispatch(
                      enqueueSnackbar({
                        message: ErrorMsg,
                        variant: 'error',
                      }),
                    );
                  }
                  onUndoImport();
                }
                if (ImportState === 3) {
                  setIsApply(true);
                } else {
                  setIsApply(false);
                }
              }
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            },
          );
          unsubscribeRef.current = unsubscribe;
        }
      })
      .catch((err: any) => {
        // dispatch(
        //   enqueueSnackbar({
        //     message: err,
        //     variant: 'error',
        //   }),
        // );
        onUndoImport();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importLead]);

  const onApplyImport = () => {
    dialogApplyRef.current?.toggle();
    setIsApply(true);
    setIsProcessing(true);
    dispatch(applyImportLeadBulk({ IsResume: false }));
  };

  const onResumeImport = () => {
    setIsApply(true);
    setIsProcessing(true);
    dispatch(applyImportLeadBulk({ IsResume: true }));
  };

  const onUndoImport = () => {
    dispatch(
      undoImportLeadBulk({
        onSuccess: () => {
          dispatch(setImportLead(''));
        },
      }),
    );
  };

  const renderBodyApply = () => {
    return (
      <>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>Upload all accepted and corrected records.</p>
        <p>Are you sure you save lead(s) to the database?</p>
      </>
    );
  };

  const renderBodyError = () => {
    return (
      <>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>{failedImportedCount} lead(s) Rejected</p>
        {rejectReasons.map((item: any) => {
          return (
            <div key={item.RejectId}>
              <span>{`${item.Name} (${item.Count})`}</span>
            </div>
          );
        })}
      </>
    );
  };

  const renderBodyResume = () => {
    return (
      <>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>The upload process has stopped, do you want to resume?</p>
      </>
    );
  };

  const renderFooterError = () => {
    return (
      <>
        <Button
          className="form-button"
          onClick={() => {
            dialogErrorRef.current?.toggle();
            onUndoImport();
          }}
          variant="contained"
        >
          {t('common.ok')}
        </Button>
      </>
    );
  };

  const handleMinimize = () => {
    setMinimize(true);
  };

  const handleMaximize = () => {
    setMinimize(false);
  };

  let completedCount;
  let increaseCount;
  let totalCount;
  if (dataForm.TotalApplyRecord > 0) {
    increaseCount = dataForm.AppliedRecord;
    totalCount = dataForm.TotalApplyRecord;
    completedCount = Math.floor((increaseCount / totalCount) * 100);
  } else {
    increaseCount = dataForm.InsertedRecord;
    totalCount = dataForm.TotalRecord;
    completedCount = Math.floor((increaseCount / totalCount) * 100);
  }
  let textTitle = isProcessing ? 'Uploading Leads are being uploaded' : 'Validating...';
  if (isApply) {
    textTitle = 'Saving lead to database...';
  }
  return (
    <>
      {visible && (
        <div className={classes.progress}>
          <div className={classes.title}>
            <span style={{ flex: '1' }}>{textTitle}</span>
            {!minimize && (
              <Button variant="text" onClick={handleMinimize}>
                <ExpandMoreIcon />
              </Button>
            )}
            {minimize && (
              <Button variant="text" onClick={handleMaximize}>
                <ExpandLessIcon />
              </Button>
            )}
          </div>
          {!minimize && (
            <>
              {isProcessing && (
                <>
                  <div className={classes.content}>
                    <ProgressBar completed={1} bgColor="#00C2CB" height="30px" margin="10px 0px 0px 0px" />
                  </div>
                  <div className={classes.complete}>Processing</div>
                </>
              )}
              {!isProcessing && (
                <>
                  <div className={classes.content}>
                    <ProgressBar completed={completedCount} bgColor="#00C2CB" height="30px" margin="10px 0px 0px 0px" />
                  </div>
                  <div className={classes.complete}>
                    {increaseCount} / {totalCount} Processed
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      <DialogPage
        ref={dialogApplyRef}
        title={'Uploading Lead'}
        maxWidth={'sm'}
        renderBody={renderBodyApply}
        onConfirm={onApplyImport}
        confirmText={t('common.save')}
        onProcessClose={onUndoImport}
      />
      <DialogPage
        ref={dialogErrorRef}
        title={'Upload Lead Error'}
        maxWidth={'sm'}
        renderBody={renderBodyError}
        renderFooter={renderFooterError}
      />
      <DialogPage
        ref={dialogResumeRef}
        title={''}
        maxWidth={'sm'}
        renderBody={renderBodyResume}
        renderFooter={() => (
          <>
            <Button
              onClick={() => {
                onUndoImport();
                dialogResumeRef.current?.toggle();
              }}
              variant="outlined"
            >
              {t('common.no')}
            </Button>
            <Button onClick={onResumeImport} variant="contained">
              {t('common.yes')}
            </Button>
          </>
        )}
      />
    </>
  );
};

export default ImportLeadProgress;
