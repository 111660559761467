import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import httpRequest from 'services';
import { PREFIX, PREFIX_MERCHANT } from './apiUrls';
import { IUserAccessManagementResponse, IAccessManagement } from './interface';

export const fetchUserAccessManagementByFilter = (data: any) => {
  return httpRequest.auth.get(PREFIX, { params: data });
};

export const fetchUserAccessManagement = createAsyncThunk<IUserAccessManagementResponse, any, { rejectValue: string }>(
  'UserAccessManagement/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchUserAccessManagementByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createUserAccessManagement = createAsyncThunk<IAccessManagement, any, { rejectValue: string }>(
  'UserAccessManagement/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.auth.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateUserAccessManagement = createAsyncThunk<IAccessManagement, any, { rejectValue: string }>(
  'UserAccessManagement/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.auth.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteUserAccessManagement = (data: any) => httpRequest.auth.delete(`${PREFIX}/${data}`);

export const getAllRole = () => {
  return httpRequest.auth.get(`${PREFIX}/roles`);
};

export const getAllMerchant = () => {
  return httpRequest.insurance.get(`${PREFIX_MERCHANT}`);
};
