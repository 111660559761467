// @mui
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { colors } from 'themes/colors';

// ----------------------------------------------------------------------

type DivProps = {
  stretchStart: number;
};

const InputStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stretchStart',
})<DivProps>(({ stretchStart, theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      boxShadow: '0 12px 24px -4pxrgba(145, 158, 171, 0.16)',
    },
    ...(stretchStart && {
      width: stretchStart,
      maxWidth: '100%',
      '&.Mui-focused': {
        boxShadow: '0 12px 24px -4pxrgba(145, 158, 171, 0.16)',
        [theme.breakpoints.up('sm')]: {
          width: stretchStart + 60,
        },
      },
    }),
    '&:hover': {
      borderColor: `${colors.primary}`,
    },
    marginRight: '22px',
    minHeight: '46px',
    '& input': {
      fontSize: '18px',
    },
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    // borderColor: `${theme.palette.grey[500_32]} !important`,
    borderColor: `${colors._E5E5E5}`,
    '&:hover': {
      borderColor: `${colors.primary}`,
    },
  },
}));

export default InputStyle;
