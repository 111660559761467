import axios from 'axios';
import setupInterceptors from './setupInterceptors';

const defaultLangCode = 'en';
let langCode = localStorage.getItem('i18nextLng') || defaultLangCode;
if (langCode && langCode.includes('-')) {
  langCode = langCode.substring(0, 2);
  if (langCode !== 'en' && langCode !== 'id') {
    langCode = defaultLangCode;
  }
}

const keycloak = axios.create({
  baseURL: `${process.env.REACT_APP_KEYCLOAK_URL}`,
});
setupInterceptors(keycloak);

const authHealthChecks = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/identity/healthz`,
});

const auth = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/identity/${langCode}/api`,
});
setupInterceptors(auth);

const insuranceHealthChecks = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/insurance/healthz`,
});

const insurance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/insurance/${langCode}/api`,
});
setupInterceptors(insurance);

const amanShopHealthChecks = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/aman-shop/healthz`,
});

const amanShop = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/aman-shop/${langCode}/api`,
});
setupInterceptors(amanShop);

const amanSaleHealthChecks = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/aman-sale/healthz`,
});

const amanSale = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/aman-sale/${langCode}/api`,
});
setupInterceptors(amanSale);

const axiosInstance = {
  keycloak,
  authHealthChecks,
  auth,
  insuranceHealthChecks,
  insurance,
  amanShopHealthChecks,
  amanShop,
  amanSaleHealthChecks,
  amanSale,
};
export default axiosInstance;
