import React from 'react';

const Watch = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 35.5C27.665 35.5 35.5 27.665 35.5 18C35.5 8.33502 27.665 0.5 18 0.5C8.33502 0.5 0.5 8.33502 0.5 18C0.5 27.665 8.33502 35.5 18 35.5Z"
        fill="white"
        stroke="#E5E5E5"
      />
      <path
        d="M18 12.5C21.79 12.5 25.17 14.63 26.82 18C25.17 21.37 21.8 23.5 18 23.5C14.2 23.5 10.83 21.37 9.18 18C10.83 14.63 14.21 12.5 18 12.5ZM18 10.5C13 10.5 8.73 13.61 7 18C8.73 22.39 13 25.5 18 25.5C23 25.5 27.27 22.39 29 18C27.27 13.61 23 10.5 18 10.5ZM18 15.5C19.38 15.5 20.5 16.62 20.5 18C20.5 19.38 19.38 20.5 18 20.5C16.62 20.5 15.5 19.38 15.5 18C15.5 16.62 16.62 15.5 18 15.5ZM18 13.5C15.52 13.5 13.5 15.52 13.5 18C13.5 20.48 15.52 22.5 18 22.5C20.48 22.5 22.5 20.48 22.5 18C22.5 15.52 20.48 13.5 18 13.5Z"
        fill="#454550"
      />
    </svg>
  );
};

export default Watch;
