import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchUserAccessManagement, createUserAccessManagement, updateUserAccessManagement } from './userAccessManagementAPIs';
import { IUserAccessManagementState } from './interface';

const initialState: IUserAccessManagementState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
};

export const userAccessManagementSlice = createSlice({
  name: 'userAccessManagement',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAccessManagement.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserAccessManagement.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchUserAccessManagement.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createUserAccessManagement.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createUserAccessManagement.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createUserAccessManagement.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateUserAccessManagement.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateUserAccessManagement.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateUserAccessManagement.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = userAccessManagementSlice.actions;
export const userAccessManagementSelector = (state: RootState) => state.userAccessManagement;

export default userAccessManagementSlice.reducer;
