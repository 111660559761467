import React from 'react';

const ProductsIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.675 6.5375L23.9375 4.4375C23.6 4.0125 23.0875 3.75 22.5 3.75H7.5C6.9125 3.75 6.4 4.0125 6.05 4.4375L4.325 6.5375C3.9625 6.9625 3.75 7.525 3.75 8.125V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V8.125C26.25 7.525 26.0375 6.9625 25.675 6.5375ZM7.8 6.25H22.2L23.2375 7.5H6.775L7.8 6.25ZM6.25 23.75V10H23.75V23.75H6.25Z"
        fill="white"
      />
      <rect x="13.75" y="15" width="7.5" height="6.25" fill="white" />
    </svg>
  );
};

export default ProductsIcon;
