import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchCountry, fetchProvinceByCountry, fetchCityByProvince, fetchCityByProvinceName } from './locationAPIs';
import { ILocationState } from './interface';

const initialState: ILocationState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  countryList: [],
  provinceList: [],
  cityList: [],
};

export const currenciesSlice = createSlice({
  name: 'locationTypes',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProvinceByCountry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCityByProvince.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCityByProvinceName.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCountry.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.countryList = payload.Data;
      })
      .addCase(fetchProvinceByCountry.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.provinceList = payload.Data;
        state.cityList = [];
      })
      .addCase(fetchCityByProvince.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.cityList = payload.Data;
      })
      .addCase(fetchCityByProvinceName.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.cityList = payload.Data;
      })
      .addCase(fetchCountry.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      })
      .addCase(fetchProvinceByCountry.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      })
      .addCase(fetchCityByProvince.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      })
      .addCase(fetchCityByProvinceName.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = currenciesSlice.actions;
export const locationSelector = (state: RootState) => state.locations;

export default currenciesSlice.reducer;
