const THRESHOLD = 1000;
let lastClicked: number = 0;
export function checkDoubleClick() {
  const timeCurr = new Date().getTime();
  const timeResult = timeCurr - lastClicked;
  if (!(timeResult < THRESHOLD)) {
    lastClicked = new Date().getTime();
    return true;
  }
  lastClicked = new Date().getTime();
  return false;
}

export const delay = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

export function isValidHttpUrl(stringVal: string) {
  let url;
  try {
    url = new URL(stringVal);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const isValidImgUrl = (url: string) =>
  new Promise((resolve) => {
    // check that is a valid url
    // then if valid url
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };
  });

/**
 * JavaScript function to match (and return) the video Id
 * of any valid Youtube Url, given as input string.
 * @author: Stephan Schmitz <eyecatchup@gmail.com>
 * @url: https://stackoverflow.com/a/10315969/624466
 */
export function getYoutubeId(url: string) {
  const p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(p) ? RegExp.$1 : false;
}

export const getYoutubeThumbnail = (url: string, quality?: string) => {
  if (url) {
    let video_id, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }
    if (video_id) {
      if (typeof quality == 'undefined') {
        quality = 'high';
      }
      var quality_key = 'maxresdefault'; // Max quality
      if (quality === 'low') {
        quality_key = 'sddefault';
      } else if (quality === 'medium') {
        quality_key = 'mqdefault';
      } else if (quality === 'high') {
        quality_key = 'hqdefault';
      }
      return 'http://img.youtube.com/vi/' + video_id + '/' + quality_key + '.jpg';
    }
  }
  return false;
};

export const isValueEmpty = (value: string) => {
  let isInvalid = false;
  if (!value) {
    isInvalid = true;
  } else if (typeof value === 'object') {
    isInvalid = !value || Object.keys(value).length === 0;
  }
  return isInvalid;
};
