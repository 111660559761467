import ImportEmployeeProgress from './ImportEmployeeProgress';
import ImportLeadProgress from './ImportLeadProgress';
import ImportHospitalProgress from './ImportHospitalProgress';
import useStyles from './styles';

interface IProgressing {
  importEmployee: any;
  importLead: any;
  importHospital: any;
}

const Progressing = ({ importEmployee, importLead, importHospital }: IProgressing) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <ImportEmployeeProgress importEmployee={importEmployee} />
      <ImportLeadProgress importLead={importLead} />
      <ImportHospitalProgress importHospital={importHospital} />
    </div>
  );
};

export default Progressing;
