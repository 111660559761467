import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchEmployeeMedicalHistory } from './employeeMedicalHistoryAPIs';
import { IEmployeeMedicalHistoryState } from './interface';

const initialState: IEmployeeMedicalHistoryState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
};

export const employeeMedicalHistorySlice = createSlice({
  name: 'employeeMedicalHistory',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeMedicalHistory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeMedicalHistory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployeeMedicalHistory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = employeeMedicalHistorySlice.actions;
export const employeeMedicalHistorySelector = (state: RootState) => state.employeeMedicalHistory;

export default employeeMedicalHistorySlice.reducer;
