import { useState, useEffect, useCallback, useMemo, forwardRef, ReactNode } from 'react';
import { Autocomplete, Popper, Stack, ClickAwayListener, FormControlLabel, Typography, PopperProps } from '@mui/material';
import { KeyboardArrowDown, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Filter } from '@devexpress/dx-react-grid';
import { TreeItem, TreeItemProps, TreeView, useTreeItem } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { DataTypes, Option } from './DataTable';
import { DataOptionProps, FetchOptionProps, DataOption } from './FilterCell';
import Checkbox from '../CheckBox';
import TextField from '../TextField';
import Button from 'components/Button';
import Card from 'components/Card';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import clsx from 'clsx';
import dayjs from 'dayjs';

const deepdash = require('deepdash')(require('lodash'));

export type FilterCellDateSelectProps = {
  columnName: string;
  dataTypes?: DataTypes;
  width?: string | number;
  filter: Filter | null;
  onFilter: (filter: Filter | null) => void;
  fetchOption: (columnName: string, dataTypes: DataTypes, filter: string, option: FetchOptionProps) => Promise<DataOptionProps>;
};

const optionAll = { label: 'All', value: 'select-all', count: 0 };

const leafIds = (node: DataOption): DataOption[] => {
  return !node.items || node.items.length === 0 ? [node] : node.items.map(leafIds).flat();
};

const convertMonthToMMMM = (month: number) => {
  return dayjs(dayjs(new Date(1970, month - 1, 1))).format('MMMM');
};

const includesAll = (arr: string[], values: string[]) => values.every((v) => arr.includes(v));

const convertTree = (data: DataOption[]) => {
  //  Remove null
  data = data.filter((item) => item.key != null);
  const cloneData = cloneDeep(data) as any;
  const dataOptions = deepdash.mapValuesDeep(
    data,
    (val: any, key: any, parent: any, context: any) => {
      const paths = context._item.path;
      let value = `${val.key}`;
      if (paths.length === 3) {
        let parentObj = cloneData;
        value = `${parentObj[paths[0]].key}-${convertMonthToMMMM(val.key)}`;
      } else if (paths.length === 5) {
        let parentObj = cloneData;
        value = `${parentObj[paths[0]].key}-${convertMonthToMMMM(parentObj[paths[0]][paths[1]][paths[2]].key)}-${val.key}`;
      }
      return { key: value, items: val.items, count: val.count };
    },
    { childrenPath: 'items' },
  );
  return dataOptions;
};

const convertTreeToOptions = (data: DataOption[]) => {
  const options: Option[] = [];
  const cloneData = cloneDeep(data) as any;
  deepdash.eachDeep(
    data,
    (val: any, key: any, parent: any, context: any) => {
      const paths = context._item.path;
      let count = 0;
      if (paths.length === 1) {
        let parentObj = cloneData;
        deepdash.eachDeep(
          parentObj[paths[0]],
          (val: any, key: any, parent: any, context: any) => {
            count += val.count ?? 0;
          },
          { childrenPath: 'items', leavesOnly: true },
        );
      } else if (paths.length === 3) {
        let parentObj = cloneData;
        deepdash.eachDeep(
          parentObj[paths[0]][paths[1]][paths[2]],
          (val: any, key: any, parent: any, context: any) => {
            count += val.count ?? 0;
          },
          { childrenPath: 'items', leavesOnly: true },
        );
      } else if (paths.length === 5) {
        let parentObj = cloneData;
        deepdash.eachDeep(
          parentObj[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]],
          (val: any, key: any, parent: any, context: any) => {
            count += val.count ?? 0;
          },
          { childrenPath: 'items', leavesOnly: true },
        );
      }
      options.push({ value: val.key, label: val.key, count: count });
    },
    { childrenPath: 'items' },
  );
  return options;
};

const childOptionByNodeId = (data: DataOption[], nodeId: string) => {
  const cloneData = cloneDeep(data) as any;
  const findChild = deepdash.findDeep(
    cloneData,
    (val: any, key: any, parent: any, context: any) => {
      if (val && val.key === nodeId) {
        return true;
      }
    },
    { childrenPath: 'items' },
  );
  const listOptionChild: string[] = [];
  if (findChild?.value?.items?.length > 0) {
    deepdash.eachDeep(
      findChild.value.items,
      (val: any, key: any, parent: any, context: any) => {
        listOptionChild.push(val.key);
      },
      { childrenPath: 'items' },
    );
  }
  return listOptionChild;
};

const calculateCountByNodeId = (data: DataOption[], selecteds: Option[]) => {
  const cloneData = cloneDeep(data) as any;
  const listSelected = selecteds.map((item) => item.value);
  let count = 0;
  deepdash.eachDeep(
    cloneData,
    (val: any, key: any, parent: any, context: any) => {
      if (listSelected.includes(val.key)) {
        count += val.count ?? 0;
      }
    },
    { childrenPath: 'items', leavesOnly: true },
  );
  return count;
};

const filterTreeNode = (data: DataOption[], searchText: string) => {
  const filtrate = deepdash.filterDeep(
    data,
    (value: any, key: any, parent: any) => {
      if (value && value.key && `${value.key}`.indexOf(searchText) !== -1) return true;
    },
    { childrenPath: 'items' },
  );
  return filtrate ?? [];
};

const parentSelectedByNodeId = (options: Option[], selecteds: string[], nodeId: string) => {
  let result: string[] = [];
  const arrNodeId = nodeId?.split('-');
  if (arrNodeId && arrNodeId.length === 1) {
    const parentNodes: string[] = [];
    const sameLevel1Options = options.filter((item) => {
      return item.value?.split('-')?.length === 1;
    });
    parentNodes.push(optionAll.value);
    const sameSelected = sameLevel1Options.map((item) => item.value);
    if (includesAll(selecteds, sameSelected)) {
      result = [...selecteds, ...parentNodes];
    } else {
      result = selecteds.filter((item) => !parentNodes.includes(item));
    }
  }
  if (arrNodeId && arrNodeId.length === 2) {
    const parentNodes: string[] = [];
    const sameLevel2Options = options.filter((item) => {
      const arrValue = item.value?.split('-');
      return arrValue?.length === 2 && arrNodeId[0] === arrValue[0];
    });
    parentNodes.push(arrNodeId[0]);
    const sameSelected = sameLevel2Options.map((item) => item.value);
    if (includesAll(selecteds, sameSelected)) {
      result = [...selecteds, ...parentNodes];
    } else {
      result = selecteds.filter((item) => !parentNodes.includes(item));
    }
  }
  if (arrNodeId && arrNodeId.length === 3) {
    const parentNodes: string[] = [];
    const sameLevel3Options = options.filter((item) => {
      const arrValue = item.value?.split('-');
      return arrValue?.length === 3 && arrNodeId[0] === arrValue[0] && arrNodeId[1] === arrValue[1];
    });
    parentNodes.push(`${arrNodeId[0]}-${arrNodeId[1]}`);
    const sameSelected = sameLevel3Options.map((item) => item.value);
    if (includesAll(selecteds, sameSelected)) {
      result = [...selecteds, ...parentNodes];
    } else {
      result = selecteds.filter((item) => !parentNodes.includes(item));
    }
  }
  return result;
};

const CustomContent = forwardRef(function CustomContent(props: any, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, node, values } = props;
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);
  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  const isIndeterminate = useMemo(
    () => !selected && leafIds(node).some((val) => values.map((item: Option) => item.value).includes(val.key)),
    [selected, node, values],
  );

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as any}
    >
      {nodeId !== optionAll.value && (
        <div style={{ padding: '0px 10px' }} onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
      )}
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        <FormControlLabel
          label={label}
          control={<Checkbox style={{ padding: '2px' }} checked={selected} indeterminate={isIndeterminate} />}
        />
      </Typography>
    </div>
  );
});

function CustomTreeItem(props: TreeItemProps) {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}

const renderTree = (nodes: DataOption, values: Option[]) => {
  const label = nodes.key?.split('-').pop();
  return (
    <CustomTreeItem
      style={{ padding: nodes.key === optionAll.value ? '10px' : 0 }}
      key={nodes.key}
      nodeId={nodes.key}
      label={nodes.key === optionAll.value ? optionAll.label : label}
      ContentProps={
        {
          node: nodes,
          values: values,
        } as any
      }
    >
      {Array.isArray(nodes.items) ? nodes.items.map((node) => renderTree(node, values)) : null}
    </CustomTreeItem>
  );
};

type CustomPopperProps = {
  width: any;
  treeView: () => ReactNode;
  setOpen: (open: boolean) => void;
  onOkHandler: () => void;
} & PopperProps;

const PopperComponentCustom = forwardRef<any, CustomPopperProps>(function PopperComponentCustom(props, ref) {
  const { t } = useTranslation();
  const { width, treeView, setOpen, onOkHandler } = props;

  return (
    <Popper
      {...props}
      style={{
        width: width ?? 'auto',
        minWidth: 200,
      }}
      placement="bottom"
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Card>
          {treeView()}
          <Stack borderTop="1px solid #E5E5E5" direction="row" spacing={2} padding={2} justifyContent="flex-end">
            <Button style={{ fontSize: '14px' }} variant="outlined" type="button" onClick={() => setOpen(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="contained" type="button" onClick={onOkHandler}>
              {t('common.ok')}
            </Button>
          </Stack>
        </Card>
      </ClickAwayListener>
    </Popper>
  );
});

const FilterCellDateSelect = (props: FilterCellDateSelectProps) => {
  const { columnName, dataTypes, width, filter, onFilter, fetchOption } = props;

  const [expanded, setExpanded] = useState<string[]>([optionAll.value]);
  const [selected, setSelected] = useState<string[]>([]);

  const [treeOptions, setTreeOptions] = useState<DataOption[]>([]);
  const [treeFilterOptions, setTreeFilterOptions] = useState<DataOption[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [values, setValues] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [openFirst, setOpenFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchOptions = useCallback(
    (value?: string, page: number = 1) => {
      const group = JSON.stringify([
        { selector: columnName, groupInterval: 'year', isExpanded: true },
        { selector: columnName, groupInterval: 'month', isExpanded: true },
        { selector: columnName, groupInterval: 'day', isExpanded: false },
      ]);
      const filter = value ? JSON.stringify([columnName, 'contains', value]) : '';
      return fetchOption(columnName, dataTypes, filter, {
        group,
      });
    },
    [columnName, dataTypes, fetchOption],
  );

  useEffect(() => {
    if (filter?.value && filter?.value.length > 0) {
      if (options.length === 0) {
        setLoading(true);
        fetchOptions().then((response: any) => {
          if (response.data.length > 0) {
            const dataOptions = convertTree([...response.data]);
            setTreeOptions(dataOptions);
            setOptions(convertTreeToOptions(dataOptions));
          }
          setLoading(false);
        });
      }
      if (options.length > 0) {
        setSelected([...filter?.value.map((item: Option) => item.value)]);
        setValues(filter?.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.value, options]);

  useEffect(() => {
    if (inputValue && !openFirst && treeOptions.length > 0) {
      const dataFilters = filterTreeNode(treeOptions, inputValue);
      const dataFilterOptions = convertTreeToOptions(dataFilters);
      setExpanded([...expanded, ...dataFilterOptions.map((item) => item.value)]);
      setTreeFilterOptions(dataFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, openFirst]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (nodeIds.length === 1) {
      let selecteds = nodeIds;
      const selectedChilds = childOptionByNodeId(treeOptions, nodeIds[0]);
      if (selectedChilds.length > 0) {
        // Click parent node
        if (selected.includes(nodeIds[0])) {
          // Unselected
          selecteds = selected.filter((item) => item !== nodeIds[0] && !selectedChilds.includes(item));
        } else {
          // Selected
          selecteds = [...selected, ...selectedChilds, ...nodeIds];
        }
      } else {
        // Click leaf node
        if (selected.includes(nodeIds[0])) {
          // Unselected
          selecteds = selected.filter((item) => item !== nodeIds[0]);
        } else {
          // Selected
          selecteds = [...selected, ...nodeIds];
        }
      }
      selecteds = uniq(selecteds);
      // Check select All
      if (nodeIds[0] === optionAll.value) {
        if (selecteds.includes(optionAll.value)) {
          setSelected([optionAll.value, ...options.map((item) => item.value)]);
          setValues(options);
        } else {
          setSelected([]);
          setValues([]);
        }
      } else {
        selecteds = parentSelectedByNodeId(options, selecteds, nodeIds[0]);
        const arrNodeId = nodeIds[0]?.split('-');
        if (arrNodeId && arrNodeId.length === 2) {
          selecteds = parentSelectedByNodeId(options, selecteds, `${arrNodeId[0]}`);
        }
        if (arrNodeId && arrNodeId.length === 3) {
          selecteds = parentSelectedByNodeId(options, selecteds, `${arrNodeId[0]}-${arrNodeId[1]}`);
          selecteds = parentSelectedByNodeId(options, selecteds, `${arrNodeId[0]}`);
        }
        setSelected(selecteds);
        const listValues = options.filter((item) => selecteds.includes(item.value));
        setValues(listValues);
      }
    }
  };

  const handleChange = useCallback(
    (event, selectedOptions, reason) => {
      if (reason === 'clear') {
        setSelected([]);
        setValues([]);
        onFilter(null);
      }
    },
    [onFilter],
  );

  const onOkHandler = () => {
    onFilter({ columnName, operation: '&', value: values });
    setOpen(false);
  };

  const onOpenHandler = (event: React.SyntheticEvent) => {
    if (event.type === 'click') {
      setOpen(true);
      if (openFirst) {
        setLoading(true);
        fetchOptions().then((response: any) => {
          if (response.data.length > 0) {
            const dataOptions = convertTree([...response.data]);
            setTreeOptions(dataOptions);
            setOptions(convertTreeToOptions(dataOptions));
          }
          setLoading(false);
          setOpenFirst(false);
        });
      }
    }
  };

  const onInputChangeHandle = (event: React.SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
    if (value !== inputValue && (reason === 'input' || reason === 'reset')) {
      setOpen(true);
      if (openFirst) {
        setLoading(true);
        fetchOptions().then((response: any) => {
          if (response.data.length > 0) {
            const dataOptions = convertTree([...response.data]);
            setTreeOptions(dataOptions);
            setOptions(convertTreeToOptions(dataOptions));
          }
          setLoading(false);
          setOpenFirst(false);
        });
      }
    }
  };

  const getTreeView = () => (
    <TreeView
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowDropUp />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      multiSelect
      sx={{
        flexGrow: 1,
        height: (inputValue ? treeFilterOptions.length > 0 : treeOptions.length > 0) ? 300 : 'auto',
        overflowY: 'auto',
      }}
    >
      {(inputValue ? treeFilterOptions.length > 0 : treeOptions.length > 0) ? (
        renderTree({ key: optionAll.value, items: inputValue ? treeFilterOptions : treeOptions }, values)
      ) : (
        <div style={{ color: '#777777', padding: '14px 16px' }}>{loading ? 'Loading...' : 'No options'}</div>
      )}
    </TreeView>
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      open={open}
      onOpen={onOpenHandler}
      loading={loading}
      options={options}
      value={values}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={onInputChangeHandle}
      sx={{ margin: '8px' }}
      filterOptions={(options, state) => options}
      renderInput={(props: any) => (
        <TextField
          {...props}
          style={{ borderColor: values.length > 0 ? '#00C2CB' : '#E5E5E5' }}
          variant="outlined"
          placeholder="Filter"
        />
      )}
      renderTags={(selected, getTagProps, ownerState) => {
        return `+${calculateCountByNodeId(treeOptions, selected)}`;
      }}
      getOptionLabel={(option) => (option.value === null ? '' : `${option.label}`)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableCloseOnSelect
      limitTags={-1}
      popupIcon={<KeyboardArrowDown style={{ color: '#C1C1C1' }} />}
      PopperComponent={PopperComponentCustom as any}
      componentsProps={{
        popper: {
          width,
          treeView: getTreeView,
          setOpen,
          onOkHandler,
        } as CustomPopperProps,
      }}
    />
  );
};

export default FilterCellDateSelect;
