const CheckedIcon = () => {
  // @ts-ignore
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#00C2CB" />
      <path
        d="M15.9384 5.78906L18.2183 8.03075L9.84791 16.9049L4.63159 12.1413L6.74128 9.73285L9.68349 12.4226L15.9384 5.78906Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedIcon;
