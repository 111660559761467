import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/index';
import { IFamiliesState } from './interface';
import { fetchHealthcareFacilitiesConfig } from './hospitalConfigAPI';

const initialState: IFamiliesState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
};

export const hospitalConfig = createSlice({
  name: 'hospital-config',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditUser(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthcareFacilitiesConfig.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHealthcareFacilitiesConfig.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHealthcareFacilitiesConfig.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditUser } = hospitalConfig.actions;
export const usersSelector = (state: RootState) => state.users;

export default hospitalConfig.reducer;
