import { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Option } from '../DataTable';

type Props = {
  initValue: any;
  onValueChange?: (value: any) => void;
  options: Option[];
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
};

const TAutocomplete: FC<Props> = ({ initValue, onValueChange, options, disabled, readOnly, required, style }) => {
  const defaultValue = options.find((item) => item.value === initValue) || null;

  return (
    <Autocomplete
      disablePortal
      className={required && !defaultValue ? 'prevent-drag-scroll TAutocomplete-required' : 'prevent-drag-scroll'}
      style={style}
      disabled={disabled}
      readOnly={readOnly}
      options={options}
      getOptionLabel={(option) => option.label}
      defaultValue={defaultValue}
      onChange={(event: any, newValue: any | null) => {
        onValueChange?.(newValue?.value);
      }}
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
  );
};

export default TAutocomplete;
