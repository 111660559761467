import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchHospitalDepartments, createHospitalDepartment, updateHospitalDepartment } from './hospitalDepartmentsAPIs';
import { IHospitalDepartmentState } from './interface';

const initialState: IHospitalDepartmentState = {
  isFetching: false,
  isSuccess: false,
  isCreated: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  hospitalDepartment: undefined,
};

export const hospitalDepartments = createSlice({
  name: 'hospitalDepartments',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditHospitalDepartment(state, action) {
      state.isUpdated = false;
    },
    requestCreateHospitalDepartment(state, action) {
      state.isCreated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalDepartments.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalDepartments.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospitalDepartments.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createHospitalDepartment.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createHospitalDepartment.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createHospitalDepartment.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateHospitalDepartment.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateHospitalDepartment.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateHospitalDepartment.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditHospitalDepartment, requestCreateHospitalDepartment } = hospitalDepartments.actions;
export const hospitalDepartmentsSelector = (state: RootState) => state.hospitalDepartments;

export default hospitalDepartments.reducer;
