import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchAccountPhoneTypes } from './phoneTypesAPIs';
import { IAccountPhoneTypeState } from './interface';

const initialState: IAccountPhoneTypeState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  PhoneTypes: [],
};

export const phoneTypesSlice = createSlice({
  name: 'phoneTypes',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountPhoneTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAccountPhoneTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.PhoneTypes = payload.Data;
      })
      .addCase(fetchAccountPhoneTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = phoneTypesSlice.actions;
export const phoneTypesSelector = (state: RootState) => state.phoneTypes;

export default phoneTypesSlice.reducer;
