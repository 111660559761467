import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { HOSPITALS_CONFIG_API } from './apiUrls';
import { IHospitalConfigResponse, IHospitalUpdateConfigPayload } from './interface';

export const fetchHospitalDetailByFilter = (data: any) => {
  return httpRequest.insurance.get(HOSPITALS_CONFIG_API, { params: data });
};

export const fetchHospitalConfig = createAsyncThunk<IHospitalConfigResponse, any, { rejectValue: string }>(
  'hospitalConfig/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalDetailByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospitalConfig = createAsyncThunk<any, IHospitalUpdateConfigPayload, { rejectValue: string }>(
  'hospitalConfig/update',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;
      await httpRequest.insurance.put(HOSPITALS_CONFIG_API, payload);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
