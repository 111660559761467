import { FC } from 'react';

import { KeycloakService } from 'services';

const GuestGuard: FC = ({ children }) => {
  const isAuth = KeycloakService.isLoggedIn();
  if (isAuth) return null;
  return <>{children}</>;
};

export default GuestGuard;
