import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchBankAccounts, fetchBankLists } from './bankAccountsAPIs';
import { IBankAccountState } from './interface';

const initialState: IBankAccountState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  bankList: [],
};

export const bankAccounts = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccounts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBankAccounts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchBankAccounts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchBankLists.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBankLists.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.bankList = payload;
      })
      .addCase(fetchBankLists.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = bankAccounts.actions;
export const bankAccountsSelector = (state: RootState) => state.amanshopBankAccount;

export default bankAccounts.reducer;
