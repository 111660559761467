import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  budget: {
    width: '35px',
    height: '35px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  budgetActive: {
    width: '35px',
    height: '35px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));

export default useStyles;
