import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { CURRENCY_URL, FETCH_ALL_CURRENCIES, FETCH_CURRENCIES, FETCH_TIMEZONE } from './apiUrls';

import { IResponseCurrencies, IResponseAllCurrencies } from './interface';
import { IFamily } from '../../families/interface';

export const fetchCurrenciesByFilter = (params: any) => httpRequest.insurance.get(FETCH_CURRENCIES, { params: params });
export const fetchTimeZones = () => httpRequest.insurance.get(FETCH_TIMEZONE);

export const fetchCurrencies = createAsyncThunk<IResponseCurrencies, any, { rejectValue: string }>(
  'currencies/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_CURRENCIES, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAllCurrencies = createAsyncThunk<IResponseAllCurrencies, any, { rejectValue: string }>(
  'currencies/fetchAll',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_ALL_CURRENCIES, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createCurrency = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'currencies/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(CURRENCY_URL, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateCurrency = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'currencies/update',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.put(CURRENCY_URL, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteCurrency = (id: number) => httpRequest.insurance.delete(`${CURRENCY_URL}/${id}`);
