import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchEmployeeFamily, createEmployeeFamily, updateEmployeeFamily } from './employeeFamilyAPIs';
import { IEmployeeFamilyState } from './interface';

const initialState: IEmployeeFamilyState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
};

export const employeeFamilySlice = createSlice({
  name: 'employeeFamily',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeFamily.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeFamily.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployeeFamily.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createEmployeeFamily.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createEmployeeFamily.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createEmployeeFamily.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateEmployeeFamily.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateEmployeeFamily.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateEmployeeFamily.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = employeeFamilySlice.actions;
export const employeeFamilySelector = (state: RootState) => state.employeeFamily;

export default employeeFamilySlice.reducer;
