import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  name: {
    padding: '6px 16px',
    marginBottom: 6,
    fontSize: '1rem',
  },
  menuLanguage: {
    alignSelf: 'center',
    fontSize: '16px',
    fontWeight: '600',
  },
}));

export default useStyles;
