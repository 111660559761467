import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseDiscounts, IDiscountData } from './interface';

export const fetchDiscountDetail = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/${data}`, { params: params });
};

export const fetchDiscountsByFilter = (data: any) => {
  return httpRequest.amanShop.get(PREFIX, { params: data });
};

export const fetchDiscounts = createAsyncThunk<IResponseDiscounts, any, { rejectValue: string }>(
  'Discount/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchDiscountsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createDiscount = createAsyncThunk<IDiscountData, any, { rejectValue: string }>(
  'Discount/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateDiscount = createAsyncThunk<any, any, { rejectValue: string }>(
  'Discount/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteDiscount = (data: any) => httpRequest.amanShop.delete(`${PREFIX}/${data}`);
