import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IIcdCodesState } from './interface';
import {
  fetchIcdCodes,
  updateIcdCode,
  createIcdCode,
  fetchNotReadIcdCode,
  fetchIcdCodeDetail,
  updateIcdCodeDetail,
  deleteIcdCodeDetail,
  fetchGroups,
  groupChangeKeyword,
  createIcdCodeDetail,
} from './icdCodesAPI';

const initialState: IIcdCodesState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
  notReadIcdCode: null,
  icdCodeDetail: {
    isFetching: false,
    isSuccess: false,
    isUpdated: false,
    isDeleted: false,
    isCreated: false,
    isError: false,
    errMsg: '',
    dataList: [],
    totalCount: 0,
  },
  isUpdatedIcdCodeDetail: false,
  group: {
    isFetching: false,
    isSuccess: false,
    isUpdated: false,
    isDeleted: false,
    isCreated: false,
    dataList: [],
    totalCount: 0,
  },
};

export const icd_codes = createSlice({
  name: 'icd-codes',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditIcdCode(state, action) {
      state.isUpdated = false;
    },
    updateListIcdCodeDetail(state, action) {
      state.icdCodeDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIcdCodes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchIcdCodes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = false;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchIcdCodes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchNotReadIcdCode.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(fetchNotReadIcdCode.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.notReadIcdCode = payload;
      })
      .addCase(fetchNotReadIcdCode.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createIcdCode.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createIcdCode.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createIcdCode.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    // Update user
    builder
      .addCase(updateIcdCode.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateIcdCode.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateIcdCode.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchIcdCodeDetail.pending, (state) => {
        state.icdCodeDetail.isFetching = true;
      })
      .addCase(fetchIcdCodeDetail.fulfilled, (state, { payload }) => {
        state.icdCodeDetail.isFetching = false;
        state.icdCodeDetail.isSuccess = true;
        state.icdCodeDetail.dataList = payload.data;
        state.icdCodeDetail.totalCount = payload.totalCount;
        state.icdCodeDetail.isCreated = false;
        state.icdCodeDetail.isDeleted = false;
        state.icdCodeDetail.isUpdated = false;
      })
      .addCase(fetchIcdCodeDetail.rejected, (state, { payload }) => {
        state.icdCodeDetail.isFetching = false;
        state.icdCodeDetail.isError = true;
        state.icdCodeDetail.errMsg = payload || '';
      });

    builder
      .addCase(fetchGroups.pending, (state) => {
        state.group.isFetching = true;
      })
      .addCase(fetchGroups.fulfilled, (state, { payload }) => {
        state.group.isFetching = false;
        state.group.isSuccess = true;
        state.group.dataList = payload.data;
        state.group.totalCount = payload.totalCount;
        state.group.isCreated = false;
        state.group.isDeleted = false;
        state.group.isUpdated = false;
      })
      .addCase(fetchGroups.rejected, (state, { payload }) => {
        state.group.isFetching = false;
      });

    builder.addCase(updateIcdCodeDetail.fulfilled, (state, { payload }) => {
      state.icdCodeDetail.isUpdated = true;
    });

    builder.addCase(deleteIcdCodeDetail.fulfilled, (state, { payload }) => {
      state.icdCodeDetail.isDeleted = true;
    });

    builder.addCase(groupChangeKeyword.fulfilled, (state, { payload }) => {
      state.group.isDeleted = true;
    });

    builder.addCase(createIcdCodeDetail.fulfilled, (state, { payload }) => {
      state.icdCodeDetail.isCreated = true;
    });
  },
});

export const { clearState, requestEditIcdCode } = icd_codes.actions;
export const usersSelector = (state: RootState) => state.users;

export default icd_codes.reducer;
