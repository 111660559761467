import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import {
  FETCH_MERCHANT_GRID_API,
  MERCHANT_API,
  // PREFIX,
} from './apiUrls';
import { IResponseMerchants, IResponseMerchantDetail } from './interface';

export const fetchMerchantGridByFilter = (data: any) => {
  return httpRequest.amanShop.get(FETCH_MERCHANT_GRID_API, { params: data });
};

export const fetchMerchantDetailByFilter = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${MERCHANT_API}/${data}`, { params: params });
};

// export const fetchAllHospital = createAsyncThunk<Array<IHospital>, any, { rejectValue: string }>(
//   'Hospital/fetchAll',
//   async (payload, thunkAPI) => {
//     try {
//       const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_API, { params: payload });
//       return data.Data;
//     } catch (err: any) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   },
// );

// export const fetchAllHospitalDepartment = createAsyncThunk<Array<IHospitalDepartment>, any, { rejectValue: string }>(
//   'Hospital/fetchAllDepartment',
//   async (payload, thunkAPI) => {
//     try {
//       const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_DEPARTMENT_API, { params: payload });
//       return data.Data;
//     } catch (err: any) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   },
// );

// export const fetchAllHospitalPersonnel = createAsyncThunk<Array<IHospitalPersonnel>, any, { rejectValue: string }>(
//   'Hospital/fetchAllPersonnel',
//   async (payload, thunkAPI) => {
//     try {
//       const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_PERSONNEL_API, { params: payload });
//       return data.Data;
//     } catch (err: any) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   },
// );

export const fetchMerchantGrid = createAsyncThunk<IResponseMerchants, any, { rejectValue: string }>(
  'Merchant/fetch-grid',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchMerchantGridByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchMerchantDetail = createAsyncThunk<IResponseMerchantDetail, any, { rejectValue: string }>(
  'Merchant-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchMerchantDetailByFilter(id, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createMerchant = createAsyncThunk<any, any, { rejectValue: string }>(
  'Merchant/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.post(MERCHANT_API, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateMerchant = createAsyncThunk<any, any, { rejectValue: string }>(
  'Merchant/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(MERCHANT_API, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteMerchant = (data: any) => httpRequest.amanShop.delete(`${MERCHANT_API}/${data}`);

export const changeStateMerchant = createAsyncThunk<any, any, { rejectValue: string }>(
  'Merchant/changeState',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(`${MERCHANT_API}/change-status`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
// export const mergeHospital = createAsyncThunk<any, any, { rejectValue: string }>('Hospital/merge', async (payload, thunkAPI) => {
//   try {
//     const newPayload = cloneDeep(payload);
//     const { onSuccess } = newPayload;
//     delete newPayload.onSuccess;
//     const { data } = await httpRequest.insurance.post(MERGE_HOSPITAL_API, newPayload);
//     if (onSuccess) onSuccess();
//     return data;
//   } catch (err: any) {
//     return thunkAPI.rejectWithValue(err);
//   }
// });
