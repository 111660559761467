import React from 'react';

const PhoneCallIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#0BE3B2" />
      <path
        d="M8.7241 7.79961C8.7601 8.33361 8.8501 8.85561 8.9941 9.35361L8.2741 10.0736C8.0281 9.35361 7.8721 8.59161 7.8181 7.79961H8.7241ZM14.6401 15.0116C15.1501 15.1556 15.6721 15.2456 16.2001 15.2816V16.1756C15.4081 16.1216 14.6461 15.9656 13.9201 15.7256L14.6401 15.0116ZM9.3001 6.59961H7.2001C6.8701 6.59961 6.6001 6.86961 6.6001 7.19961C6.6001 12.8336 11.1661 17.3996 16.8001 17.3996C17.1301 17.3996 17.4001 17.1296 17.4001 16.7996V14.7056C17.4001 14.3756 17.1301 14.1056 16.8001 14.1056C16.0561 14.1056 15.3301 13.9856 14.6581 13.7636C14.5981 13.7396 14.5321 13.7336 14.4721 13.7336C14.3161 13.7336 14.1661 13.7936 14.0461 13.9076L12.7261 15.2276C11.0281 14.3576 9.6361 12.9716 8.7721 11.2736L10.0921 9.95361C10.2601 9.78561 10.3081 9.55161 10.2421 9.34161C10.0201 8.66961 9.9001 7.94961 9.9001 7.19961C9.9001 6.86961 9.6301 6.59961 9.3001 6.59961Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneCallIcon;
