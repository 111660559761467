import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';

import {
  IGetCompaniesByType,
  IGetInsuranceProductType,
  IRequestCreatePolicy,
  IResponseCreatePolicy,
  IResponseEmployers,
  IResponseEmployer,
  IRequestCreateAccount,
  IResponseCreateAccount,
  IResponseApplyOnBoard,
  IGetAllGroupCurrencies,
} from './interface';
import { API_VERSION_1, API_VERSION_2 } from 'configs/constants';
import { EMPLOYER_COMPANY, FETCH_EMPLOYERS, ACCOUNT, ONBOARD, ONBOARD_APPLY, PREFIX } from './apiUrls';

export const fetchEmployersByFilter = (data: any) => {
  return httpRequest.insurance.get(FETCH_EMPLOYERS, { params: data });
};

export const fetchEmployers = createAsyncThunk<IResponseEmployers, any, { rejectValue: string }>(
  'employers/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_EMPLOYERS, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

/* Settings */
export const getInsuranceProductType = () =>
  httpRequest.insurance.get<IGetInsuranceProductType>(`${API_VERSION_2}/Setting/InsuranceProduct/GetAll`);

export const getAllGroupCurrencies = () =>
  httpRequest.insurance.get<IGetAllGroupCurrencies>(`${API_VERSION_2}/Setting/Currency/GetAllGroup`);

export const getCompaniesByType = (type = 0 | 1 | 2) =>
  httpRequest.insurance.get<IGetCompaniesByType>(`${API_VERSION_1}/Company/${type}`);

export const getCompanyByInsurerAndBroker = () =>
  httpRequest.insurance.get<IGetCompaniesByType>(`${API_VERSION_1}/Company/insurer-and-broker`);

export const getOnBoard = () => httpRequest.insurance.get<IResponseEmployer>(ONBOARD);

export const applyOnBoard = (data: { companyId: string | number; messageSuccess?: string }) =>
  httpRequest.insurance.post<IResponseApplyOnBoard>(ONBOARD_APPLY, data);

export const createCompany = (CompanyName: string) =>
  httpRequest.insurance.post<IResponseEmployer>(EMPLOYER_COMPANY, { CompanyName });

/* Account */
export const createAccount = (Account: IRequestCreateAccount) =>
  httpRequest.insurance.post<IResponseCreateAccount>(ACCOUNT, Account);

export const deleteAccount = (AccountId: string | number) => httpRequest.insurance.delete(`${PREFIX}/${AccountId}`);

export const updateAccount = (Account: IRequestCreateAccount) => httpRequest.insurance.put(ACCOUNT, Account);

export const getEmployer = (CompanyId: number, InsurerId: number, BrokerId: number) =>
  httpRequest.insurance.get<IResponseEmployer>(`${PREFIX}/${CompanyId}/${InsurerId}/${BrokerId}`);

export const createPolicy = (data: IRequestCreatePolicy) =>
  httpRequest.insurance.post<IResponseCreatePolicy>(`${API_VERSION_2}/Policy`, data);

export const updatePolicy = (data: any) => httpRequest.insurance.put(`${API_VERSION_2}/Policy`, data);

export const deletePolicy = (Id: string) => httpRequest.insurance.delete(`${API_VERSION_2}/Policy/${Id}`);

export const saveSaaS = (data: any) => httpRequest.insurance.put(`${API_VERSION_2}/Policy/saas`, data);
