import Input from '@mui/material/Input';
import { FC } from 'react';

type Props = {
  initValue: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
};

const TInput: FC<Props> = ({ initValue, onValueChange, disabled, readOnly, required, style }) => {
  return (
    <Input
      disabled={disabled}
      readOnly={readOnly}
      defaultValue={initValue}
      onChange={(e) => onValueChange?.(e.target.value)}
      style={style}
      className={required && !initValue ? 'prevent-drag-scroll TInput-required' : 'prevent-drag-scroll'}
      fullWidth
    />
  );
};

export default TInput;
