import { API_VERSION_2 } from 'configs/constants';

export const PREFIX = `${API_VERSION_2}/Setting/IcdCode`;

export const NOT_READ_API = `${PREFIX}Detail/NotRead`;

export const FETCH_ICD_CODE_DETAIL_API = `${API_VERSION_2}/Setting/IcdCodeDetail`;

export const ICD_CODE_DETAIL_API = `${API_VERSION_2}/Setting/IcdCodeDetail`;

export const GROUP_API = `${PREFIX}/GetAllGroups`;

export const GROUP_CHANGE_KEYWORD_API = `${PREFIX}/ChangeKeywordToGroup`;

export const CREATE_ICD_CODE_DETAIL_API = `${API_VERSION_2}/Setting/IcdCodeDetail`;
