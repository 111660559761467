import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_COUNTRY, FETCH_PROVINCE_BY_COUNTRY, FETCH_CITY_BY_PROVINCE } from './apiUrls';

import { IResponseCities, IResponseCountries, IResponseProvinces } from './interface';

export const fetchCountry = createAsyncThunk<IResponseCountries, any, { rejectValue: string }>(
  'country/fetch',
  async (params, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_COUNTRY);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchProvinceByCountry = createAsyncThunk<IResponseProvinces, any, { rejectValue: string }>(
  'provinceByCountry/fetch',
  async (params, thunkAPI) => {
    try {
      if (params?.countryId > 0) {
        const url = `${FETCH_PROVINCE_BY_COUNTRY}/${params.countryId}`;
        const { data } = await httpRequest.insurance.get(url);
        return data;
      } else {
        return { Data: [] };
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const fetchCityByProvince = createAsyncThunk<IResponseCities, any, { rejectValue: string }>(
  'cityByProvince/fetch',
  async (params, thunkAPI) => {
    try {
      if (params.provinceId) {
        const url = `${FETCH_CITY_BY_PROVINCE}/${params.provinceId}`;
        const { data } = await httpRequest.insurance.get(url);
        return data;
      } else {
        return { Data: [] };
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const fetchCityByProvinceName = createAsyncThunk<IResponseCities, any, { rejectValue: string }>(
  'cityByProvinceName/fetch',
  async (params, thunkAPI) => {
    try {
      if (params.countryId) {
        const url = `${FETCH_CITY_BY_PROVINCE}/${params.countryId}/${params.provinceName}`;
        const { data } = await httpRequest.insurance.get(url);
        return data;
      } else {
        return { Data: [] };
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
