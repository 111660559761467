import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import {
  fetchHospitalServices,
  createHospitalService,
  updateHospitalService,
  fetchHospitalServicesAddList,
} from './hospitalServicesAPIs';
import { IHospitalServiceState } from './interface';

const initialState: IHospitalServiceState = {
  isFetching: false,
  isSuccess: false,
  isCreated: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  hospitalService: undefined,
  dataAddList: [],
  totalCountAddList: 0,
};

export const hospitalServices = createSlice({
  name: 'hospitalServices',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditHospitalService(state, action) {
      state.isUpdated = false;
    },
    requestCreateHospitalService(state, action) {
      state.isCreated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalServices.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalServices.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospitalServices.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchHospitalServicesAddList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalServicesAddList.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataAddList = payload.data;
        state.totalCountAddList = payload.totalCount;
      })
      .addCase(fetchHospitalServicesAddList.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createHospitalService.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createHospitalService.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createHospitalService.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateHospitalService.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateHospitalService.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateHospitalService.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditHospitalService, requestCreateHospitalService } = hospitalServices.actions;
export const hospitalServicesSelector = (state: RootState) => state.hospitalServices;

export default hospitalServices.reducer;
