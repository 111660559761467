import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from 'state';
import { KeycloakService } from 'services';
import { SoftUIControllerProvider } from 'contexts/SoftUIControllerContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'locales/i18n';
import './index.css';
import 'react-quill/dist/quill.snow.css';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <SoftUIControllerProvider>
        <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
          <App />
        </DevSupport>
      </SoftUIControllerProvider>
    </Provider>,
    document.getElementById('root'),
  );

KeycloakService.initKeycloak(renderApp, () => {
  KeycloakService.doLogout();
  localStorage.clear();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
