import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchStoreDeliverys, addOrUpdateStoreDelivery } from './storeDeliverysAPIs';
import { IStoreDeliveryState } from './interface';

const initialState: IStoreDeliveryState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  storeDelivery: undefined,
  isCreated: false,
  isCreatedOrUpdated: false,
};

export const storeDeliverys = createSlice({
  name: 'storeDeliverys',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditStore(state, action) {
      state.isUpdated = false;
    },
    requestCreateStore(state, action) {
      state.isCreated = false;
    },
    requestCreateOrUpdateStore(state, action) {
      state.isCreatedOrUpdated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreDeliverys.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStoreDeliverys.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchStoreDeliverys.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(addOrUpdateStoreDelivery.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addOrUpdateStoreDelivery.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreatedOrUpdated = true;
      })
      .addCase(addOrUpdateStoreDelivery.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateStore, requestEditStore, requestCreateOrUpdateStore, requestClearError } =
  storeDeliverys.actions;
export const storeDeliverysSelector = (state: RootState) => state.amanshopStoreDeliverys;

export default storeDeliverys.reducer;
