import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { KeycloakService } from 'services';
import { getUserDefaultRole } from 'utils/tokenUtils';

const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.headers && KeycloakService.getToken()) {
        config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
        const defaultRole = getUserDefaultRole();
        if (defaultRole && KeycloakService.hasRealmRole(defaultRole.Role)) {
          config.headers['X-User-Role'] = defaultRole.Role;
        }
      }
      // KeycloakService.updateToken(
      //   () => {
      //     if (config.headers && KeycloakService.getToken()) {
      //       config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
      //       const defaultRole = getUserDefaultRole();
      //       if (defaultRole && KeycloakService.hasRealmRole(defaultRole.Role)) {
      //         config.headers['X-User-Role'] = defaultRole.Role;
      //       }
      //     }
      //     return Promise.resolve(config);
      //   },
      //   () => {
      //     setTimeout(() => {
      //       KeycloakService.doLogout();
      //       localStorage.clear();
      //     }, 500);
      //   },
      // );
      return Promise.resolve(config);
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      let msg: string = '';
      if (err.response) {
        const status = err.response.status;
        const errData = err.response.data;
        switch (status) {
          case 401: {
            msg = '401 Authorization Required';
            // setTimeout(() => {
            //   KeycloakService.doLogout();
            //   localStorage.clear();
            // }, 500);
            break;
          }
          case 403: {
            msg = 'You need permission to perform this action';
            break;
          }
          case 405: {
            msg = 'Requested method is not supported';
            break;
          }
          default:
            msg = errData?.Description || errData?.errorMessage || 'An error occurred while sending the request';
            break;
        }
      }

      return Promise.reject(msg);
    },
  );
};

export default setupInterceptors;
