import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchClaims, fetchClaimDetail, createClaim, updateClaim, fetchAllClaimTypes } from './claimsAPIs';
import { IClaimsState } from './interface';

const initialState: IClaimsState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  isCreated: false,
  isDeleted: false,
  claimDetail: undefined,
  totalExceedSLA: 0,
  totalWithinSLA: 0,
  totalNotStarted: 0,
  totalPending: 0,
  allClaimTypes: [],
};

export const claims = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaims.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchClaims.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
        if (payload.data && payload.data.length > 0) {
          state.totalExceedSLA = payload.data[0].TotalExceedSLA;
          state.totalWithinSLA = payload.data[0].TotalWithinSLA;
          state.totalNotStarted = payload.data[0].TotalNotStarted;
          state.totalPending = payload.data[0].TotalPending;
        }
      })
      .addCase(fetchClaims.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchClaimDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchClaimDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.claimDetail = payload;
      })
      .addCase(fetchClaimDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createClaim.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createClaim.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createClaim.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateClaim.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateClaim.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateClaim.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder.addCase(fetchAllClaimTypes.fulfilled, (state, { payload }) => {
      state.allClaimTypes = payload;
    });
  },
});

export const { clearState } = claims.actions;
export const claimsSelector = (state: RootState) => state.claims;

export default claims.reducer;
