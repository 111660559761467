import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { HISTORY_CHANGE_API, HISTORY_CHANGE_SEARCH_API } from './apiUrls';
import { IResponseHistoryChange } from './interface';

export const fetchHistoryChangeByFilter = (data: any) => {
  return httpRequest.insurance.get(HISTORY_CHANGE_API, { params: data });
};

export const fetchHistoryChange = createAsyncThunk<IResponseHistoryChange, any, { rejectValue: string }>(
  'history/fetchHistoryChange',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHistoryChangeByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchHistoryChangeSearchByFilter = (data: any) => {
  return httpRequest.insurance.get(HISTORY_CHANGE_SEARCH_API, { params: data });
};

export const fetchHistoryChangeSearch = createAsyncThunk<IResponseHistoryChange, any, { rejectValue: string }>(
  'history/fetchHistoryChangeSearch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHistoryChangeSearchByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
