import React from 'react';

const ChangeIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_14_2)">
        <path
          d="M18 35.5C27.665 35.5 35.5 27.665 35.5 18C35.5 8.33502 27.665 0.5 18 0.5C8.33502 0.5 0.5 8.33502 0.5 18C0.5 27.665 8.33502 35.5 18 35.5Z"
          fill="white"
          stroke="#E5E5E5"
        />
        <path
          d="M28 24V22H14V10H16L13 7L10 10H12V12H8V14H12V22C12 23.1 12.9 24 14 24H22V26H20L23 29L26 26H24V24H28ZM16 14H22V20H24V14C24 12.9 23.1 12 22 12H16V14Z"
          fill="#454550"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_2">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChangeIcon;
