import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { colors } from 'themes/colors';

interface IProps {
  theme: Theme;
  variant: 'text' | 'contained' | 'outlined' | 'standard' | undefined;
  size: string;
  circular: boolean;
  iconOnly: boolean;
  select: boolean;
}

export default styled(TextField)(({ theme, variant = 'contained', select = false }: IProps | any) => {
  const { palette } = theme;
  // styles for the button with variant="contained"
  const containedStyles = () => {
    return {
      boxShadow: 'none',
      padding: theme.spacing(1.3, 0),

      '& input': {
        paddingLeft: '0px',
        fontSize: '1.25rem',
        fontFamily: 'Poppins',
        fontWeight: '600',
        color: '#454550 !important',
        transition: `${theme.transitions.create(['border-color', 'box-shadow'])}`,
      },
      '& label': {
        color: `${theme.palette.grey[600]} !important`,
        fontSize: '18px',
      },
      '& fieldset': {
        border: '0px',
        borderBottom: `1px solid #E5E5E5 !important`,
        borderRadius: '0px',
      },
      '&:hover fieldset': {
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      },
      '& .Mui-focused fieldset': {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      },
      '&:disabled': {
        backgroundColor: palette.grey[400],
      },
    };
  };
  const selelectBoxStyles = () => {
    return {
      '& div': {
        fontWeight: '600',
        fontSize: '16px',
        color: '#454550 !important',
        fontFamily: 'Poppins',
      },
    };
  };
  // styles for the button with variant="contained"
  const standardStyles = () => {
    return {
      boxShadow: 'none',
      padding: theme.spacing(1.3, 0),

      '& .MuiInput-root::before': {
        borderBottomColor: `#E5E5E5 !important`,
      },
      // '& .MuiInput-root:hover': {
      //   borderBottom: '1px solid #E5E5E5'
      // },
      '& input': {
        paddingLeft: '0px',
        // fontSize: '0.92rem',
        fontFamily: 'Poppins',
        // fontWeight: '400',
        fontWeight: '600',
        fontSize: '16px',
        color: '#454550 !important',
        transition: `${theme.transitions.create(['border-color', 'box-shadow'])}`,
      },
      '& textarea': {
        paddingLeft: '0px',
        // fontSize: '0.92rem',
        fontFamily: 'Poppins',
        // fontWeight: '400',
        fontWeight: '600',
        fontSize: '16px',
        color: '#454550 !important',
        transition: `${theme.transitions.create(['border-color', 'box-shadow'])}`,
      },
      '& label': {
        color: `${colors._777777} !important`,
        fontSize: '18px',
        fontWeight: '700',
      },
      '& fieldset': {
        border: '0px',
        borderBottom: `1px solid #E5E5E5 !important`,
        borderRadius: '0px',
      },
      '&:hover fieldset': {
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      },
      '& .Mui-focused fieldset': {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      },
      '&:disabled': {
        backgroundColor: palette.grey[400],
      },
    };
  };

  // styles for the button with variant="text"
  const outliedStyles = () => {
    // color value

    return {
      boxShadow: 'none',
      padding: theme.spacing(0.5, 1.5),
      border: '1px solid #E5E5E5',
      borderRadius: '8px',

      '& input': {
        fontSize: '0.92rem',
        fontFamily: 'Poppins',
        fontWeight: '400 !important',
        color: 'black !important',
      },
      '&:hover': {
        background: 'none',
      },
      '& label': {
        top: '-8px',
        left: '10px',
        padding: '0px 10px',
        background: '#fff',
      },
      '.MuiInput-root::before': {
        border: 'none !important',
      },

      '.MuiInput-root::after': {
        border: 'none !important',
      },
      '& fieldset': {
        border: 'none',
      },
      '& .Mui-focused fieldset': {
        borderColor: `1px solid ${theme.palette.primary.main} !important`,
      },
    };
  };

  return {
    ...(variant === 'contained' && containedStyles()),
    ...(variant === 'standard' && standardStyles()),
    ...(variant === 'outlined' && outliedStyles()),
    ...(select && selelectBoxStyles()),
  };
});
