import { useState, useEffect, useImperativeHandle, useRef, useCallback, forwardRef } from 'react';
import {
  Autocomplete,
  Popper,
  Stack,
  Box,
  Slider,
  ClickAwayListener,
  Typography,
  Grid,
  TextField as MUITextField,
  FormControl,
  FormLabel,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Filter } from '@devexpress/dx-react-grid';
import { useTranslation } from 'react-i18next';
import { DataTypes } from './DataTable';
import { DataOptionProps, FetchOptionProps } from './FilterCell';
import TextField from '../TextField';
import NumberFormatCustom from 'components/NumberFormatCustom';
import Button from 'components/Button';
import Card from 'components/Card';
import { moneyFormat } from 'utils/helpers';

export type Option = {
  label: any;
  value: any;
  count: number;
};

export type FilterCellRangeProps = {
  columnName: string;
  dataTypes?: DataTypes;
  width?: string | number;
  filter: Filter | null;
  onFilter: (filter: Filter | null) => void;
  fetchOption: (columnName: string, dataTypes: DataTypes, filter: string, option: FetchOptionProps) => Promise<DataOptionProps>;
};

interface StateNumberFormat {
  lowestValue: string;
  highestValue: string;
}

const optionAll = { label: 'All', value: 'select-all', count: 0 };

const RangeSlider = forwardRef<any, any>(({ title, width, defaultValue, minValue, maxValue }: any, ref) => {
  let valueTemp = [minValue, maxValue];
  if (defaultValue && defaultValue.length === 2) {
    valueTemp = defaultValue;
  }
  const [values, setValues] = useState<number[]>(valueTemp);
  const [valueInputs, setValueInputs] = useState<StateNumberFormat>({
    lowestValue: `${valueTemp[0]}`,
    highestValue: `${valueTemp[1]}`,
  });

  useImperativeHandle(ref, () => ({
    getRangeValue() {
      return values;
    },
  }));

  const handleChange = (event: Event, newValue: number | number[]) => {
    const newValueTemp = newValue as number[];
    setValues(newValueTemp);
    setValueInputs({
      lowestValue: `${newValueTemp[0]}`,
      highestValue: `${newValueTemp[1]}`,
    });
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (event.target.name === 'lowestValue') {
      if (parseInt(value) > parseInt(valueInputs.highestValue)) {
        value = valueInputs.lowestValue;
      }
      setValues([parseInt(value), values[1]]);
    }
    if (event.target.name === 'highestValue') {
      if (parseInt(value) < parseInt(valueInputs.lowestValue)) {
        value = valueInputs.lowestValue;
      }
      setValues([values[0], parseInt(value)]);
    }
    setValueInputs({
      ...valueInputs,
      [event.target.name]: value,
    });
  };

  return (
    <Box
      sx={{
        width: width ?? 'auto',
        minWidth: 400,
        padding: '12px 24px 12px 24px',
      }}
    >
      <Typography fontWeight={600} gutterBottom>
        {title}
      </Typography>
      <Slider min={minValue} max={maxValue} value={values} onChange={handleChange} valueLabelDisplay="off" disableSwap />
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <FormControl component="fieldset" sx={{ width: '150px' }}>
            <FormLabel component="legend">Lowest</FormLabel>
            <MUITextField
              size="small"
              name="lowestValue"
              value={valueInputs.lowestValue}
              onChange={handleChangeInput}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <div style={{ paddingTop: '24px' }}>-</div>
        </Grid>
        <Grid item>
          <FormControl component="fieldset" sx={{ width: '150px' }}>
            <FormLabel component="legend">Highest</FormLabel>
            <MUITextField
              size="small"
              name="highestValue"
              value={valueInputs.highestValue}
              onChange={handleChangeInput}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
});

const FilterCellRange = (props: FilterCellRangeProps) => {
  const { t } = useTranslation();
  const { columnName, dataTypes, width, filter, onFilter, fetchOption } = props;

  const rangeSliderRef = useRef<any>(null);

  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [values, setValues] = useState<Option[]>([]);
  const [openFirst, setOpenFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalCountSelectedOption, setTotalCountSelectedOption] = useState(0);

  const fetchOptions = useCallback(
    (value?: string, page: number = 1) => {
      const totalSummary = JSON.stringify([
        { selector: columnName, summaryType: 'min' },
        { selector: columnName, summaryType: 'max' },
      ]);
      const filter = value ? JSON.stringify([columnName, 'contains', value]) : '';
      return fetchOption(columnName, dataTypes, filter, {
        totalSummary,
        skip: 0,
        take: page * 10,
      });
    },
    [columnName, dataTypes, fetchOption],
  );

  useEffect(() => {
    if (filter?.value && filter?.value.length === 3) {
      setTotalCountSelectedOption(filter?.value[2]);
      setValues([optionAll]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.value]);

  const handleChange = useCallback(
    (event, selectedOptions, reason) => {
      if (reason === 'clear') {
        setTotalCountSelectedOption(0);
        setValues([]);
        onFilter(null);
      }
    },
    [onFilter],
  );

  const onOkHandler = () => {
    const rangeValue = rangeSliderRef.current.getRangeValue();
    // Get total row
    const filter = `[${[
      JSON.stringify([columnName, '>=', rangeValue[0]]),
      JSON.stringify([columnName, '<=', rangeValue[1]]),
    ].join(',"and",')}]`;
    fetchOption(columnName, dataTypes, filter, {
      skip: 0,
      take: 10,
      requireTotalCount: true,
    }).then((response: any) => {
      const totalCount = response.totalCount ?? 0;
      setTotalCountSelectedOption(totalCount);
      setValues([optionAll]);
      onFilter({ columnName, operation: '#', value: [rangeValue[0], rangeValue[1], totalCount] });
      setOpen(false);
    });
  };

  const onOpenHandler = (event: React.SyntheticEvent) => {
    if (event.type === 'click') {
      setOpen(true);
      if (openFirst) {
        setLoading(true);
        fetchOptions().then((response: any) => {
          if (response.summary?.length > 1) {
            setMinValue(response.summary[0]);
            setMaxValue(response.summary[1]);
          }
          setLoading(false);
          setOpenFirst(false);
        });
      }
    }
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      open={open}
      onOpen={onOpenHandler}
      loading={loading}
      options={[optionAll]}
      value={values}
      onChange={handleChange}
      sx={{ margin: '8px' }}
      renderInput={(props: any) => {
        let title = '';
        let placeholder = 'Filter';
        if (filter?.value && filter?.value.length === 3) {
          const text = `${moneyFormat(filter?.value[0])} - ${moneyFormat(filter?.value[1])}`;
          title = text;
          placeholder = text;
        }
        return (
          <TextField
            {...props}
            title={title}
            style={{ borderColor: values.length > 0 ? '#00C2CB' : '#E5E5E5', caretColor: 'transparent' }}
            variant="outlined"
            placeholder={placeholder}
          />
        );
      }}
      renderTags={(selected, getTagProps, ownerState) => {
        // return `+${totalCountSelectedOption}`;
        return '';
      }}
      getOptionLabel={(option) => (option.value === null ? '' : `${option.label}`)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableCloseOnSelect
      popupIcon={<KeyboardArrowDown style={{ color: '#C1C1C1' }} />}
      PopperComponent={(props) => (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper
            {...props}
            style={{
              width: width ?? 'auto',
              minWidth: 400,
            }}
            placement="bottom"
          >
            <Card>
              {maxValue > 0 ? (
                <RangeSlider
                  ref={rangeSliderRef}
                  title={'Price'}
                  width={width}
                  defaultValue={filter?.value}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              ) : (
                <div style={{ color: '#777777', padding: '14px 16px' }}>{loading ? 'Loading...' : 'No options'}</div>
              )}
              <Stack borderTop="1px solid #E5E5E5" direction="row" spacing={2} padding={2} justifyContent="flex-end">
                <Button style={{ fontSize: '14px' }} variant="outlined" type="button" onClick={() => setOpen(false)}>
                  {t('common.cancel')}
                </Button>
                <Button variant="contained" type="button" onClick={onOkHandler}>
                  {t('common.ok')}
                </Button>
              </Stack>
            </Card>
          </Popper>
        </ClickAwayListener>
      )}
    />
  );
};

export default FilterCellRange;
