import React from 'react';

const EditIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M18.56 16.02L19.48 16.94L12.92 23.5H12V22.58L18.56 16.02ZM22.16 10C21.91 10 21.65 10.1 21.46 10.29L19.63 12.12L23.38 15.87L25.21 14.04C25.6 13.65 25.6 13.02 25.21 12.63L22.87 10.29C22.67 10.09 22.42 10 22.16 10ZM18.56 13.19L9.99999 21.75V25.5H13.75L22.31 16.94L18.56 13.19Z"
        fill="#454550"
      />
    </svg>
  );
};

export default EditIcon;
