import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import isEmpty from 'lodash/isEmpty';

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = firebase.initializeApp(FIREBASE_CONFIG);

const validateFirebaseLogin = (firebaseToken: string) => {
  // Validate token firebase (auth firebase)
  app.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      console.log(user);
    } else {
      // No user is signed in.
      if (!isEmpty(firebaseToken)) {
        app
          .auth()
          .signInWithCustomToken(firebaseToken)
          .then((userCredential) => {
            // Signed in
            // const {user} = userCredential;
            console.log(userCredential);
          })
          .catch((error) => {
            console.log(error);
            // const errorCode = error.code;
            // const errorMessage = error.message;
          });
      }
    }
  });
};

export { validateFirebaseLogin };
export default app;
