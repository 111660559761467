import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseFamilies } from './interface';

export const fetchInsurers = createAsyncThunk<IResponseFamilies, any, { rejectValue: string }>(
  'insurer/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(`${PREFIX}/insurer`, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchInsurersByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/insurer`, { params: data });
};

export const updateInsurer = createAsyncThunk<any, any, { rejectValue: string }>('insurer/update', async (payload, thunkAPI) => {
  try {
    let formData = new FormData();
    formData.append('key', payload.key);
    formData.append('values', payload.value);
    const { data } = await httpRequest.insurance.put(`${PREFIX}/insurer?insurerId=${payload.insurerId}`, formData, {
      headers: {
        'Content-Type': ' multipart/form-data',
      },
    });
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteFamily = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
export const deleteInsurer = (data: any) => httpRequest.insurance.delete(`${PREFIX}/insurer/${data}`);
