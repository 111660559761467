import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import {
  CREATE_ICD_CODE_DETAIL_API,
  FETCH_ICD_CODE_DETAIL_API,
  GROUP_API,
  GROUP_CHANGE_KEYWORD_API,
  ICD_CODE_DETAIL_API,
  NOT_READ_API,
  PREFIX,
} from './apiUrls';
import {
  IFetchArgs,
  IIcdCode,
  IIcdCodeDetail,
  IRequestDeleteIcdCodeDetail,
  IRequestFetchIcdCodeDetail,
  IRequestGroupChangeKeyword,
  IRequestUpdateIcdCodeDetail,
  IResponseGroupChangeKeyword,
  IResponseIcdCodeDetailRespone,
  IResponseIcdCodes,
  IResponseNotReadIcdCode,
} from './interface';

export const fetchIcdCodesByFilter = (data: IFetchArgs) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchIcdCodeDetailByFilter = (id: number, params: IFetchArgs) => {
  return httpRequest.insurance.get(`${FETCH_ICD_CODE_DETAIL_API}/${id}`, { params: params });
};

export const notReadIcdCodeAPI = (data: IFetchArgs) => {
  return httpRequest.insurance.get(NOT_READ_API, { params: data });
};

export const fetchIcdCodes = createAsyncThunk<IResponseIcdCodes, any, { rejectValue: string }>(
  'icd-code/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchIcdCodesByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchIcdCodeDetail = createAsyncThunk<
  IResponseIcdCodeDetailRespone,
  IRequestFetchIcdCodeDetail,
  { rejectValue: string }
>('icd-code-detail/fetch', async ({ id, payload }, thunkAPI) => {
  try {
    const { data } = await fetchIcdCodeDetailByFilter(id, payload);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchNotReadIcdCode = createAsyncThunk<IResponseNotReadIcdCode, any, { rejectValue: string }>(
  'icd-code/not-read/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await notReadIcdCodeAPI(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createIcdCode = createAsyncThunk<IIcdCode, IIcdCode, { rejectValue: string }>(
  'icd-code/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(PREFIX, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateIcdCode = createAsyncThunk<any, IIcdCode, { rejectValue: string }>(
  'icd-code/update',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.put(PREFIX, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateIcdCodeDetail = createAsyncThunk<any, IRequestUpdateIcdCodeDetail, { rejectValue: string }>(
  'icd-code-detail/update',
  async (payload, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append('key', payload.key);
      formData.append('values', payload.value);
      const { data } = await httpRequest.insurance.put(`${ICD_CODE_DETAIL_API}?icdCodeDetailId=${payload.key}`, formData, {
        headers: {
          'Content-Type': ' multipart/form-data',
        },
      });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteIcdCode = (data: number) => httpRequest.insurance.delete(`${PREFIX}/${data}`);

export const deleteIcdCodeDetail = createAsyncThunk<any, IRequestDeleteIcdCodeDetail, { rejectValue: string }>(
  'icd-code-detail/delete',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.delete(`${ICD_CODE_DETAIL_API}?icdCodeDetailId=${payload.icdCodeDetailId}`);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchGroupsByFilter = (data: IFetchArgs) => {
  return httpRequest.insurance.get(GROUP_API, { params: data });
};

export const fetchGroups = createAsyncThunk<IResponseIcdCodes, any, { rejectValue: string }>(
  'icd-code-group/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchGroupsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const groupChangeKeyword = createAsyncThunk<
  IResponseGroupChangeKeyword,
  IRequestGroupChangeKeyword,
  { rejectValue: string }
>('icd-code-group/update', async (payload, thunkAPI) => {
  try {
    const { data } = await httpRequest.insurance.put(GROUP_CHANGE_KEYWORD_API, payload);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createIcdCodeDetail = createAsyncThunk<IIcdCode, IIcdCodeDetail, { rejectValue: string }>(
  'icd-code-detail/create',
  async (payload, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append('values', JSON.stringify(payload));
      const { data } = await httpRequest.insurance.post(`${CREATE_ICD_CODE_DETAIL_API}/${payload.GroupId}`, formData, {
        headers: {
          'Content-Type': ' multipart/form-data',
        },
      });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
