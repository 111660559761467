import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { AnchorHTMLAttributes } from 'react';

interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: Theme;
  color: 'primary' | 'success' | 'warning' | 'error' | 'info' | 'secondary' | undefined;
  variant: 'text' | 'contained' | 'outlined' | undefined;
  size: string;
  circular: boolean;
  iconOnly: boolean;
}

export default styled(Button)(
  ({ theme, color = 'primary', variant = 'contained', size = 'medium', circular = false, iconOnly = false }: IProps | any) => {
    const { palette, shape } = theme;
    // const { rgba } = functions;
    const { borderRadius } = shape;

    // styles for the button with variant="contained"
    const containedStyles = () => {
      // color value
      let colorValue = palette.common.white;

      if (color === 'primary' || color === 'success') {
        colorValue = palette.common.white;
      } else if (color === 'error' || color === 'warning') {
        colorValue = palette.common.white;
      }

      // color value when button is focused
      let focusedColorValue = palette.common.white;

      // if (color === "primary" || color === "success" ) {
      //   focusedColorValue =  palette.common.white;
      // } else if (color === "warning" || color === "error") {
      //   focusedColorValue = palette.common.white;
      // }

      return {
        background: palette[color].main,
        color: colorValue,
        boxShadow: 'none',
        padding: theme.spacing(1.3, 2.6),
        transition: 'background .4s linear !important',

        '&:hover': {
          background:
            color === 'success'
              ? `linear-gradient(143.47deg, ${palette[color].light} 0%, ${palette[color].main} 120.11%)`
              : palette[color].main,
          boxShadow: 'none',
        },

        '&:focus:not(:hover)': {
          backgroundColor: palette[color].main,
        },

        '&:disabled': {
          backgroundColor: palette.grey[400],
          color: focusedColorValue,
        },
      };
    };

    // styles for the button with variant="outlined"
    const outliedStyles = () => {
      // background color value
      const backgroundValue = color === 'primary' ?? palette.common.white;

      // color value
      const colorValue = palette[color] ? palette[color].main : palette.common.white;

      // border color value

      return {
        background: backgroundValue,
        color: colorValue,
        borderColor: palette[color].main,
        padding: theme.spacing(1.3, 2.6),
        borderRadius: '8px',
        fontWeight: 700,

        '&:hover': {
          borderColor: colorValue,
        },

        '&:active:not(:hover)': {
          backgroundColor: colorValue,
          color: palette.common.white,
          opacity: 0.85,
        },

        // '&:disabled': {
        //   color: colorValue,
        //   borderColor: colorValue,
        // },
      };
    };

    // styles for the button with variant="text"
    const textStyles = () => {
      // color value
      const colorValue = palette[color] ? palette[color].main : palette.common.white;

      // color value when button is focused
      const focusedColorValue = palette[color] ? palette[color] : palette.common.white;

      return {
        color: colorValue,

        '&:hover': {
          color: focusedColorValue,
          background: 'none',
        },

        '&:focus:not(:hover)': {
          color: focusedColorValue,
        },
      };
    };

    // styles for the button with circular={true}
    const circularStyles = () => ({
      borderRadius: borderRadius,
    });

    // styles for the button with iconOnly={true}
    const iconOnlyStyles = () => {
      // width, height, minWidth and minHeight values
      let sizeValue = theme.spacing(38);

      if (size === 'small') {
        sizeValue = theme.spacing(25.4);
      } else if (size === 'large') {
        sizeValue = theme.spacing(52);
      }

      // padding value
      let paddingValue = `${theme.spacing(11)} ${theme.spacing(11)} ${theme.spacing(10)}`;

      if (size === 'small') {
        paddingValue = theme.spacing(4.5);
      } else if (size === 'large') {
        paddingValue = theme.spacing(16);
      }

      return {
        width: sizeValue,
        minWidth: sizeValue,
        height: sizeValue,
        minHeight: sizeValue,
        padding: paddingValue,

        '& .material-icons': {
          marginTop: 0,
        },

        '&:hover, &:focus, &:active': {
          transform: 'none',
        },
      };
    };

    return {
      ...(variant === 'contained' && containedStyles()),
      ...(variant === 'outlined' && outliedStyles()),
      ...(variant === 'text' && textStyles()),
      ...(circular && circularStyles()),
      ...(iconOnly && iconOnlyStyles()),
    };
  },
);
