import { KeycloakService } from 'services';
import { REALM_ROLE, REALM_ROLE_LIST } from 'configs/constants';
import { RoleInfo } from 'services/interface';

const STORAGE_PERMISSIONS = 'permissions';
const STORAGE_DEFAULT_ROLE = 'default-role';
const STORAGE_FIREBASE_TOKEN = 'firebase-token';

export const setUserPermission = (permissions: any[]) => {
  localStorage.setItem(STORAGE_PERMISSIONS, JSON.stringify(permissions || []));
};
export const getUserPermission = (): any[] => {
  const permissionsString = localStorage.getItem(STORAGE_PERMISSIONS) || '[]';
  return JSON.parse(permissionsString) || [];
};

export const setUserDefaultRole = (role: RoleInfo) => {
  localStorage.setItem(STORAGE_DEFAULT_ROLE, JSON.stringify(role));
};
export const getUserDefaultRole = (): RoleInfo | null => {
  const roleString = localStorage.getItem(STORAGE_DEFAULT_ROLE);
  if (roleString) {
    return JSON.parse(roleString) || null;
  }
  return null;
};

export const setFirebaseToken = (firebaseToken: string) => {
  localStorage.setItem(STORAGE_FIREBASE_TOKEN, firebaseToken);
};
export const getFirebaseToken = () => {
  const firebaseToken = localStorage.getItem(STORAGE_FIREBASE_TOKEN);
  return firebaseToken || '';
};

export const isAuthorized = (resource: string, scope: string): boolean => {
  const permissions = getUserPermission();
  if (!permissions) {
    return false;
  }
  return permissions.some((p) => p.rsname === resource && p.scopes.some((s: string) => s === scope));
};

export const isPermission = (scope: string) => {
  const permissions = getUserPermission();
  if (!permissions) {
    return false;
  }
  return permissions.some((p) => p.scopes.some((s: string) => s === scope));
};

export const isMultiRole = () => {
  return (
    (KeycloakService.getRoles() || []).filter(
      (role) => role !== REALM_ROLE.Employee && role !== REALM_ROLE.Customer && REALM_ROLE_LIST.includes(role),
    ).length > 1
  );
};

export const isRoleEmployer = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.Employer && KeycloakService.hasRealmRole(REALM_ROLE.Employer)) {
    return true;
  }
  return false;
};

export const isRoleInsurerOrBroker = () => {
  const defaultRole = getUserDefaultRole();
  if (
    defaultRole &&
    (defaultRole.Role === REALM_ROLE.Insurer || defaultRole.Role === REALM_ROLE.Broker) &&
    (KeycloakService.hasRealmRole(REALM_ROLE.Insurer) || KeycloakService.hasRealmRole(REALM_ROLE.Broker))
  ) {
    return true;
  }
  return false;
};

export const isRoleInsurer = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.Insurer && KeycloakService.hasRealmRole(REALM_ROLE.Insurer)) {
    return true;
  }
  return false;
};

export const isRoleBroker = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.Broker && KeycloakService.hasRealmRole(REALM_ROLE.Broker)) {
    return true;
  }
  return false;
};

export const isRoleInternalAdmin = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.Admin && KeycloakService.hasRealmRole(REALM_ROLE.Admin)) {
    return true;
  }
  return false;
};

export const isRoleMerchant = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.Merchant && KeycloakService.hasRealmRole(REALM_ROLE.Merchant)) {
    return true;
  }
  return false;
};

export const isRoleInternalAmanFinance = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.AmanFinance && KeycloakService.hasRealmRole(REALM_ROLE.AmanFinance)) {
    return true;
  }
  return false;
};

export const isRoleInternalAmanCare = () => {
  const defaultRole = getUserDefaultRole();
  if (defaultRole && defaultRole.Role === REALM_ROLE.AmanCare && KeycloakService.hasRealmRole(REALM_ROLE.AmanCare)) {
    return true;
  }
  return false;
};
