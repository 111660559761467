import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchExchanges, fetchExchangeHistory, fetchExchangeDetail, fetchExchangesBidder } from './exchangesAPIs';
import { IExchangeState } from './interface';

const initialState: IExchangeState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  historyCount: 0,
  historyList: [],
  bidderList: [],
  isFetchingBidder: false,
  totalBidderCount: 0,
};

export const Exchanges = createSlice({
  name: 'Exchanges',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchanges.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchExchanges.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchExchanges.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchExchangesBidder.pending, (state) => {
        state.isFetchingBidder = true;
      })
      .addCase(fetchExchangesBidder.fulfilled, (state, { payload }) => {
        state.isFetchingBidder = false;
        state.isSuccess = true;
        state.bidderList = payload.data;
        state.totalBidderCount = payload.totalCount;
      })
      .addCase(fetchExchangesBidder.rejected, (state, { payload }) => {
        state.isFetchingBidder = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchExchangeHistory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchExchangeHistory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.historyList = payload.data;
        state.historyCount = payload.totalCount;
      })
      .addCase(fetchExchangeHistory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchExchangeDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchExchangeDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.detailExchange = payload;
      })
      .addCase(fetchExchangeDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = Exchanges.actions;
export const ExchangesSelector = (state: RootState) => state.saleExchangeReducer;

export default Exchanges.reducer;
