import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseLeadStatusGrids, ILeadStatusData } from './interface';

export const fetchLeadDetail = (data: any, params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/${data}`, { params: params });
};

export const fetchLeadsByFilter = (data: any) => {
  return httpRequest.amanSale.get(PREFIX, { params: data });
};

export const fetchLeadStatus = createAsyncThunk<IResponseLeadStatusGrids, any, { rejectValue: string }>(
  'LeadStatus/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchLeadsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const fetchAllLeadStatus = createAsyncThunk<ILeadStatusData[], any, { rejectValue: string }>(
  'LeadStatus/fetch-all',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/get-all`, {});
      return data?.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createLeadStatus = createAsyncThunk<ILeadStatusData, any, { rejectValue: string }>(
  'LeadStatus/create',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateLeadStatus = createAsyncThunk<any, any, { rejectValue: string }>(
  'LeadStatus/update',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteLeadStatus = (data: any) => httpRequest.amanSale.delete(`${PREFIX}/${data}`);
