import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import {
  fetchEmployeeEmploymentInformations,
  createEmployeeEmploymentInformation,
  updateEmployeeEmploymentInformation,
} from './employmentInformationAPIs';
import { IEmploymentInformationState } from './interface';

const initialState: IEmploymentInformationState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
};

export const employeeEmploymentInformation = createSlice({
  name: 'employeeEmploymentInformation',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeEmploymentInformations.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeEmploymentInformations.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchEmployeeEmploymentInformations.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createEmployeeEmploymentInformation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createEmployeeEmploymentInformation.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createEmployeeEmploymentInformation.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateEmployeeEmploymentInformation.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateEmployeeEmploymentInformation.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateEmployeeEmploymentInformation.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = employeeEmploymentInformation.actions;
export const employeeEmploymentInformationSelector = (state: RootState) => state.employeeEmploymentInformation;

export default employeeEmploymentInformation.reducer;
