// import React from 'react';

// import { PATH_NAME } from './pathName';
import { IRoute } from './interface';

// const Login = React.lazy(() => import('features/login'));

const guestRoutes: IRoute[] = [
  // {
  //   path: PATH_NAME.LOGIN,
  //   element: Login,
  // },
];

export default guestRoutes;
