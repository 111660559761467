import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseHospitalServices, IHospitalService } from './interface';
import { IResponseMedical } from '../medical/interface';

export const fetchHospitalServicesAddListByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/addlist`, { params: data });
};

export const fetchHospitalServicesAddList = createAsyncThunk<IResponseMedical, any, { rejectValue: string }>(
  'hospitalService/fetch-addlist',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalServicesAddListByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchHospitalServicesByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchHospitalServices = createAsyncThunk<IResponseHospitalServices, any, { rejectValue: string }>(
  'hospitalService/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalServicesByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHospitalService = createAsyncThunk<IHospitalService, any, { rejectValue: string }>(
  'hospitalService/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospitalService = createAsyncThunk<any, any, { rejectValue: string }>(
  'hospitalService/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHospitalService = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
