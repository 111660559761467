import createPalette from '@mui/material/styles/createPalette';

export const colors = {
  white: '#FFFFFF',
  primary: '#00C2CB',
  border: {
    primary: '#E5E5E5',
    secondary: '#aeaeae',
  },
  text: {
    primary: '#454550',
    secondary: '#777777',
  },
  _E5E5E5: '#E5E5E5',
  _454550: '#454550',
  _777777: '#777777',
  _C1C1C1: '#C1C1C1',
  _1C1C1E: '#1C1C1E',
  _EDEDED: '#EDEDED',
  _F7F7F7: '#F7F7F7',
};

export const palette = createPalette({
  primary: {
    main: colors.primary,
  },
  text: {
    primary: colors.text.primary,
    secondary: colors.text.secondary,
  },
});
