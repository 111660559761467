import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    position: 'absolute',
    right: '0.438rem',
    bottom: '0rem',
    width: '25%',
    zIndex: '999999 !important',
  },
  progress: {
    marginBottom: '0.438rem',
    borderRadius: '0.313rem',
    border: '0.063rem solid #D7D7D7',
    backgroundColor: 'white',
  },
  title: {
    backgroundColor: '#f1f1f1',
    borderBottom: '0.063rem solid #f1f1f1',
    paddingLeft: '0.625rem',
    borderRadius: '0.313rem',
    fontSize: '0.938rem',
    color: '#00C2CB',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.625rem',
    marginRight: '0.625rem',
    paddingTop: '0.625rem',
    paddingBottom: '0.938rem',
  },
  complete: {
    textAlign: 'center',
    fontSize: '0.938rem',
    color: '#000000',
    marginTop: '0.1rem',
  },
}));

export default useStyles;
