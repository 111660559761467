import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IBatchState } from './interface';
import { fetchBatch } from './batchAPI';

const initialState: IBatchState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  batch: undefined,
};

export const batch = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatch.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBatch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchBatch.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = batch.actions;
export const usersSelector = (state: RootState) => state.users;

export default batch.reducer;
