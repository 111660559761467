import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { FETCH_STORE_GRID_BY_MERCHANT_API, FETCH_STORE_GRID_API, PREFIX } from './apiUrls';
import { IResponseStores, IResponseStoreDetail } from './interface';

export const fetchStoreByMerchantGridByFilter = (data: any) => {
  return httpRequest.amanShop.get(FETCH_STORE_GRID_BY_MERCHANT_API, { params: data });
};

export const fetchStoreGridByFilter = (data: any) => {
  return httpRequest.amanShop.get(FETCH_STORE_GRID_API, { params: data });
};

export const fetchStoreDetailByFilter = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/${data}`, { params: params });
};

export const fetchStoreByMerchantGrid = createAsyncThunk<IResponseStores, any, { rejectValue: string }>(
  'Store/fetch-grid-by-merchant',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchStoreByMerchantGridByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchStoreGrid = createAsyncThunk<IResponseStores, any, { rejectValue: string }>(
  'Store/fetch-grid',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchStoreGridByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchStoreDetail = createAsyncThunk<IResponseStoreDetail, any, { rejectValue: string }>(
  'Store-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchStoreDetailByFilter(id, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createStore = createAsyncThunk<any, any, { rejectValue: string }>('Store/create', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateStore = createAsyncThunk<any, any, { rejectValue: string }>('Store/update', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteStore = (data: any) => httpRequest.amanShop.delete(`${PREFIX}/${data}`);

export const fetchStoreSumary = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/sumary/${data}`, { params: params });
};
