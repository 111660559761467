import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeExtractResponse } from './interface';

export const fetchEmployeeExtractByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/extract/search`, { params: data });
};

export const fetchEmployeeExtract = createAsyncThunk<IEmployeeExtractResponse, any, { rejectValue: string }>(
  'Employees/extract-fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchEmployeeExtractByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
