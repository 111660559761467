import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IUserExtractResponse } from './interface';

export const fetchUserExtractByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/extract/search`, { params: data });
};

export const fetchUserExtract = createAsyncThunk<IUserExtractResponse, any, { rejectValue: string }>(
  'Users/extract-fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchUserExtractByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
