import React from 'react';

const ArrowLeftIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.115 11.115L16.245 18L23.115 24.885L21 27L12 18L21 9L23.115 11.115Z" fill="#C1C1C1" />
    </svg>
  );
};

export default ArrowLeftIcon;
