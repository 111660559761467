import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponsePaymentInfos } from './interface';

export const fetchPaymentInfosByFilter = (data: any) => {
  return httpRequest.amanShop.get(`${PREFIX}`, { params: data });
};

export const fetchPaymentInfos = createAsyncThunk<IResponsePaymentInfos, any, { rejectValue: string }>(
  'PaymentInfo/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchPaymentInfosByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updatePaymentInfo = createAsyncThunk<any, any, { rejectValue: string }>(
  'PaymentInfo/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
