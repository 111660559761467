import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest, { KeycloakService } from 'services';
import {
  PERMISSIONS,
  ACCOUNT_INFO,
  ACCOUNT_LOGIN,
  ACCOUNT_CHANGE_ROLE,
  ACCOUNT_REFRESH_TOKEN,
  RESET_PASSWORD,
  SET_PASSWORD,
  IDENTITY_PROVIDER,
} from './apiUrls';
import { ILogin, IChangeRole, IResetPassword } from './interface';
import { setFirebaseToken } from 'utils/tokenUtils';

export const getPermissonsByRole = async (role: string | null, callback: Function) => {
  try {
    const { data } = await httpRequest.auth.get(PERMISSIONS, {
      params: {
        clientIds: [
          KeycloakService.CLIENT_IDENTITY_API,
          KeycloakService.CLIENT_INSURANCE_API,
          KeycloakService.CLIENT_AMANSHOP_API,
          KeycloakService.CLIENT_AMANSALE_API,
        ].join(','),
        role,
      },
    });
    if (data && data.Data) {
      callback(data.Data);
    } else {
      callback();
    }
  } catch (error) {
    console.log(error);
    callback();
  }
};

export const getAccountInfo = async (callback: Function) => {
  try {
    const { data } = await httpRequest.auth.get(ACCOUNT_INFO);
    if (data?.Data) {
      setFirebaseToken(data?.Data?.FireBaseToken);
      callback(data?.Data?.FireBaseToken);
    }
  } catch (error) {
    console.log(error);
    callback();
  }
};

export const setPassword = (data: any) => httpRequest.auth.put(SET_PASSWORD, data);

export const getIdentityProviderById = (data: any) => httpRequest.auth.get(`${IDENTITY_PROVIDER}/${data}`);

export const deleteIdentityProvider = (accountId: any, provider: any) =>
  httpRequest.insurance.delete(`${IDENTITY_PROVIDER}/${accountId}/${provider}`);

export const getRefreshToken = async (refreshToken: string) => {
  try {
    const { data } = await httpRequest.auth.get(ACCOUNT_REFRESH_TOKEN, {
      params: {
        refreshToken,
      },
    });
    if (data?.Data) {
      // setAuth(data.Data, false);
    }
    return data?.Data?.Token;
  } catch (error) {
    console.log(error);
  }
};

export const loginUser = createAsyncThunk<any, ILogin, { rejectValue: string }>('users/login', async (payload, thunkAPI) => {
  try {
    const { data } = await httpRequest.auth.post(ACCOUNT_LOGIN, payload);
    if (data?.Data) {
      // setAuth(data.Data, false);
    }
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const changeRole = createAsyncThunk<any, IChangeRole, { rejectValue: string }>(
  'users/changeRole',
  async (payload, thunkAPI) => {
    try {
      const { initRoleId, companyId, onSuccess } = payload;
      const { data } = await httpRequest.auth.post(ACCOUNT_CHANGE_ROLE, { initRoleId, companyId });
      if (data?.Data) {
        // setAuth(data.Data, true);
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const resetPassword = (data: IResetPassword) => httpRequest.auth.post(RESET_PASSWORD, data);
