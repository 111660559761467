import React from 'react';

const OrderHistoryInfoIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 3.75H5C3.625 3.75 2.5 4.875 2.5 6.25V23.75C2.5 25.125 3.625 26.25 5 26.25H25C26.375 26.25 27.5 25.125 27.5 23.75V6.25C27.5 4.875 26.375 3.75 25 3.75ZM25 23.75H5V6.25H25V23.75Z"
        fill="#00C2CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2625 13.025L22.4875 11.25L18.525 15.2125L16.7625 13.4375L15 15.2L18.525 18.75L24.2625 13.025Z"
        fill="#00C2CB"
      />
      <path d="M12.5 8.75H6.25V11.25H12.5V8.75Z" fill="#00C2CB" />
      <path d="M12.5 13.75H6.25V16.25H12.5V13.75Z" fill="#00C2CB" />
      <path d="M12.5 18.75H6.25V21.25H12.5V18.75Z" fill="#00C2CB" />
    </svg>
  );
};

export default OrderHistoryInfoIcon;
