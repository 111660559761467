import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchHospitalRatings } from './hospitalRatingsAPIs';
import { IHospitalRatingState } from './interface';

const initialState: IHospitalRatingState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  hospitalRating: undefined,
};

export const hospitalRatings = createSlice({
  name: 'hospitalRatings',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalRatings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalRatings.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospitalRatings.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = hospitalRatings.actions;
export const hospitalRatingsSelector = (state: RootState) => state.hospitalRatings;

export default hospitalRatings.reducer;
