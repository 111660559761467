import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { PREFIX, EXCHAGE_ADD_BID, EXCHAGE_WITHDRAW, EXCHAGE_HISTORY } from './apiUrls';
import cloneDeep from 'lodash/cloneDeep';
import {
  IResponseExchangeGrids,
  IResponseExchangeHistoryGrids,
  IExchangeGridData,
  IResponseExchangeBidderGrids,
} from './interface';

export const fetchExchangeDetail = createAsyncThunk<IExchangeGridData, any, { rejectValue: string }>(
  'Exchange-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/detail/${id}`, {});
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchExchangesBidderByFilter = (data: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/bidder-grid`, { params: data });
};

export const fetchExchangesBidder = createAsyncThunk<IResponseExchangeBidderGrids, any, { rejectValue: string }>(
  'Exchange-Bidder/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchExchangesBidderByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchExchangesByFilter = (data: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/exchange-grid`, { params: data });
};

export const fetchExchanges = createAsyncThunk<IResponseExchangeGrids, any, { rejectValue: string }>(
  'Exchange/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchExchangesByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const addBid = createAsyncThunk<any, any, { rejectValue: string }>('Exchange/add-bid', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanSale.post(EXCHAGE_ADD_BID, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const withdraw = createAsyncThunk<any, any, { rejectValue: string }>('Exchange/withdraw', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanSale.post(`${EXCHAGE_WITHDRAW}/${newPayload.id}`, {});
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchExchangeHistoryByFilter = (data: any) => {
  return httpRequest.amanSale.get(EXCHAGE_HISTORY, { params: data });
};

export const fetchExchangeHistory = createAsyncThunk<IResponseExchangeHistoryGrids, any, { rejectValue: string }>(
  'Exchange/fetch-history',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchExchangeHistoryByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteExchange = (data: any) => httpRequest.amanSale.delete(`${PREFIX}/${data}`);

export const updateExchange = createAsyncThunk<any, any, { rejectValue: string }>(
  'Exchange/update',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    try {
      const { data } = await httpRequest.amanSale.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchLowestBidder = (params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/lowest-bidder`, { params: params });
};

export const getInviteeCompanyById = (payload: any) => {
  return httpRequest.amanSale.post(`${PREFIX}/invitee-company`, payload);
};

export const getExchangeStatusById = (payload: any) => {
  return httpRequest.amanSale.post(`${PREFIX}/exchange-status`, payload);
};

export const reminderExchange = createAsyncThunk<any, any, { rejectValue: string }>(
  'Exchange/reminder',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/reminder`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);
