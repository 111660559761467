import React from 'react';

const RoundedEditIcon = ({ color = '#00C2CB' }) => {
  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.5 30.4815C59.5 46.8116 46.2914 60.0479 30 60.0479C13.7086 60.0479 0.5 46.8116 0.5 30.4815C0.5 14.1513 13.7086 0.915039 30 0.915039C46.2914 0.915039 59.5 14.1513 59.5 30.4815Z"
        fill="white"
        stroke="#E5E5E5"
      />
      <path
        d="M31.3564 26.9968L32.8994 28.4366L21.8973 38.7032H20.3543V37.2634L31.3564 26.9968ZM37.3941 17.5753C36.9748 17.5753 36.5388 17.7318 36.2201 18.0292L33.1509 20.8932L39.4402 26.7621L42.5094 23.898C43.1635 23.2877 43.1635 22.3017 42.5094 21.6914L38.5849 18.0292C38.2495 17.7162 37.8302 17.5753 37.3941 17.5753ZM31.3564 22.5678L17 35.9644V41.8333H23.2893L37.6457 28.4366L31.3564 22.5678Z"
        fill={color}
      />
    </svg>
  );
};

export default RoundedEditIcon;
