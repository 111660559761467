import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import {
  IResponseLeadGrids,
  ILeadDetailData,
  ILeadSource,
  ILeadIndustry,
  ILeadBasicDto,
  ILeadAvgAge,
  IMutationPayload,
  ILeadActionChange,
  ILeadSendToExchange,
  ILeadMergeInfoPayload,
  IMergeLeadPayload,
  ILeadBulkTemplate,
  ILeadGridData,
} from './interface';

export const fetchLeadDetail = createAsyncThunk<ILeadDetailData, any, { rejectValue: string }>(
  'Lead-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/${id}`, {});
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateFollowers = createAsyncThunk<ILeadDetailData, any, { rejectValue: string }>(
  'Lead-detail/update-followers',
  async ({ payload, newLeadDetail }, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      await httpRequest.amanSale.put(`${PREFIX}/update-followers`, newPayload);
      if (onSuccess) onSuccess();
      return newLeadDetail;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchLeadsByFilter = (data: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/lead-grid`, { params: data });
};
export const fetchLeadSource = (params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/source`, { params: params });
};
export const fetchLeadAvgAge = (params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/avg-age`, { params: params });
};
export const getInfoExchangeByLeadId = (id: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/info-exchange/${id}`, {});
};
export const fetchLeads = createAsyncThunk<IResponseLeadGrids, any, { rejectValue: string }>(
  'Lead/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchLeadsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const getLeadSource = createAsyncThunk<ILeadSource[], any, { rejectValue: string }>(
  'LeadSource/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchLeadSource(payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getLeadAvgAge = createAsyncThunk<ILeadAvgAge[], any, { rejectValue: string }>(
  'LeadAvgAge/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchLeadAvgAge(payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getLeadIndustry = createAsyncThunk<ILeadIndustry[], any, { rejectValue: string }>(
  'LeadIndustry/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/industry`, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createLead = createAsyncThunk<ILeadDetailData, any, { rejectValue: string }>(
  'Lead/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanSale.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const getAllLead = createAsyncThunk<ILeadBasicDto[], any, { rejectValue: string }>(
  'Lead/get-all',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/get-all`, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateLead = createAsyncThunk<any, any, { rejectValue: string }>('Lead/update', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.amanSale.put(PREFIX, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteLead = (data: any) => httpRequest.amanSale.delete(`${PREFIX}/${data}`);

export const updateWatchOrUnwatch = createAsyncThunk<ILeadGridData, any, { rejectValue: string }>(
  'Lead/update-followers',
  async ({ payload, newLead }, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      await httpRequest.amanSale.put(`${PREFIX}/update-followers`, newPayload);
      if (onSuccess) onSuccess();
      return newLead;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const actionChangeLead = createAsyncThunk<any, ILeadActionChange & IMutationPayload, { rejectValue: string }>(
  'Lead/action',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/action-change`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const sendToExchange = createAsyncThunk<any, ILeadSendToExchange & IMutationPayload, { rejectValue: string }>(
  'Lead/send-to-exchange',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/send-to-exchange`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getLeadStatusById = (payload: any) => {
  return httpRequest.amanSale.post(`${PREFIX}/lead-status`, payload);
};

export const fetchMergeLeadsInfo = createAsyncThunk<any, ILeadMergeInfoPayload & IMutationPayload, { rejectValue: string }>(
  'Lead/merge-lead-info',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/merge-lead/info`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const mergeLead = createAsyncThunk<any, IMergeLeadPayload & IMutationPayload, { rejectValue: string }>(
  'Lead/merge-lead',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/merge-lead`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchLeadBulkTemplate = createAsyncThunk<any, any, { rejectValue: string }>(
  'Lead/fetch-lead-bulk-template',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/template-firestore`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateLeadBulkTemplate = createAsyncThunk<any, ILeadBulkTemplate & IMutationPayload, { rejectValue: string }>(
  'Lead/update-lead-bulk-template',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/template-firestore`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      if (onError) onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const applyImportLeadBulk = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Lead/apply-import-lead-bulk',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { IsResume, onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importLeadTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.amanSale.post(`${PREFIX}/import/apply`, {
          ImportTransId: transId,
          IsResume: IsResume,
        });
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const undoImportLeadBulk = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Lead/undo-import-lead-bulk',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importLeadTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.amanSale.post(`${PREFIX}/import/undo`, { ImportTransId: transId });
        localStorage.removeItem('importLeadTransId');
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const importLead = (payload: FormData) => {
  return httpRequest.amanSale.post(`${PREFIX}/import`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getImportLead = (data: any, params: any) => {
  return httpRequest.amanSale.get(`${PREFIX}/import/${data}`, { params: params });
};

export const exportLead = (payload: any) => {
  return httpRequest.amanSale.post(`${PREFIX}/extract`, payload, { responseType: 'blob' });
};
