import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { getAllLeadHistory, fetchLeadHistory, getMeeting, addMessage, getMessage, addMeeting } from './leadHistoryAPIs';
import { ILeadHistoryState } from './interface';

const initialState: ILeadHistoryState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  totalCount: 0,
  meetingDetail: undefined,
  leadHistoryDetail: undefined,
  allLeadHistory: {
    PageIndex: 1,
    TotalPages: 1,
    TotalItems: 1,
    PageSize: 10,
    Items: [],
    HasPreviousPage: false,
    HasNextPage: false,
  },
};

export const LeadHistory = createSlice({
  name: 'LeadHistory',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditLead(state, action) {
      state.isUpdated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMessage.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addMessage.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(addMessage.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(addMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addMeeting.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(addMeeting.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchLeadHistory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadHistory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.leadHistoryDetail = payload;
      })
      .addCase(fetchLeadHistory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder.addCase(getAllLeadHistory.fulfilled, (state, { payload }) => {
      // @ts-ignore
      if (payload.PageIndex === 1) {
        state.allLeadHistory = payload || initialState.allLeadHistory;
      } else {
        const newData = {
          ...payload,
          Items: [...state.allLeadHistory.Items, ...payload.Items],
        };
        state.allLeadHistory = newData || initialState.allLeadHistory;
      }
    });

    builder
      .addCase(getMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMeeting.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.meetingDetail = payload;
      })
      .addCase(getMeeting.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(getMessage.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMessage.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.messageDetail = payload;
      })
      .addCase(getMessage.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = LeadHistory.actions;
export const LeadHistorySelector = (state: RootState) => state.saleLeadHistoryReducer;

export default LeadHistory.reducer;
