import React from 'react';

const OrderSentTodayIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 10H21.25V5H3.75C2.375 5 1.25 6.125 1.25 7.5V21.25H3.75C3.75 23.325 5.425 25 7.5 25C9.575 25 11.25 23.325 11.25 21.25H18.75C18.75 23.325 20.425 25 22.5 25C24.575 25 26.25 23.325 26.25 21.25H28.75V15L25 10ZM24.375 11.875L26.825 15H21.25V11.875H24.375ZM7.5 22.5C6.8125 22.5 6.25 21.9375 6.25 21.25C6.25 20.5625 6.8125 20 7.5 20C8.1875 20 8.75 20.5625 8.75 21.25C8.75 21.9375 8.1875 22.5 7.5 22.5ZM10.275 18.75C9.5875 17.9875 8.6125 17.5 7.5 17.5C6.3875 17.5 5.4125 17.9875 4.725 18.75H3.75V7.5H18.75V18.75H10.275ZM22.5 22.5C21.8125 22.5 21.25 21.9375 21.25 21.25C21.25 20.5625 21.8125 20 22.5 20C23.1875 20 23.75 20.5625 23.75 21.25C23.75 21.9375 23.1875 22.5 22.5 22.5Z"
        fill="white"
      />
    </svg>
  );
};

export default OrderSentTodayIcon;
