import React from 'react';

const OrderTodayIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.375 4.375L22.5 2.5L20.625 4.375L18.75 2.5L16.875 4.375L15 2.5L13.125 4.375L11.25 2.5L9.375 4.375L7.5 2.5L5.625 4.375L3.75 2.5V27.5L5.625 25.625L7.5 27.5L9.375 25.625L11.25 27.5L13.125 25.625L15 27.5L16.875 25.625L18.75 27.5L20.625 25.625L22.5 27.5L24.375 25.625L26.25 27.5V2.5L24.375 4.375ZM23.75 23.8625H6.25V6.1375H23.75V23.8625ZM7.5 18.75H22.5V21.25H7.5V18.75ZM7.5 13.75H22.5V16.25H7.5V13.75ZM7.5 8.75H22.5V11.25H7.5V8.75Z"
        fill="white"
      />
      <rect x="20" y="0.699219" width="8" height="8" fill="#766EC8" />
      <circle cx="24.5" cy="4.5" r="2.5" fill="white" />
    </svg>
  );
};

export default OrderTodayIcon;
