const PREFIX = '/v1.0/Account';

export const ACCOUNT_LOGIN = `${PREFIX}/login`;
export const ACCOUNT_CHANGE_ROLE = `${PREFIX}/changerole`;
export const ACCOUNT_REFRESH_TOKEN = `${PREFIX}/refreshaccesstoken`;
export const RESET_PASSWORD = `${PREFIX}/forgotpassword`;

export const TOKEN_ENDPOINT = `/realms/{realm-name}/protocol/openid-connect/token`;
export const PERMISSIONS = `${PREFIX}/permissions`;
export const ACCOUNT_INFO = `${PREFIX}/user-information`;
export const SET_PASSWORD = `${PREFIX}/set-password`;
export const IDENTITY_PROVIDER = `${PREFIX}/identity-provider`;
