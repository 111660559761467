const BatchIdIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5V19H5V5H19ZM19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="#00C2CB"
      />
      <path
        d="M17 10.75V9.5H14.5V7H13.25V9.5H10.75V7H9.5V9.5H7V10.75H9.5V13.25H7V14.5H9.5V17H10.75V14.5H13.25V17H14.5V14.5H17V13.25H14.5V10.75H17ZM13.25 13.25H10.75V10.75H13.25V13.25Z"
        fill="#00C2CB"
      />
    </svg>
  );
};

export default BatchIdIcon;
