import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import ProgressBar from '@ramonak/react-progress-bar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'components/Button';
import DialogPage from 'components/DialogPage/DialogPage';
import { useDispatch } from 'react-redux';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { FirebaseAman } from 'firebaseAman';
import { enqueueSnackbar, setImportHospital } from 'state/app/appSlice';
import {
  applyImportHospitalBulk,
  getFailedListImport,
  getImportHospital,
  undoImportHospitalBulk,
  updateFailedListImport,
} from 'state/hospitals/hospitalsAPI';
import ImportHospitalDataTable from './ImportHospitalDataTable';
import { DialogConfirm, EDialogType } from 'components/DialogConfirm';

interface IImportHospitalProgress {
  importHospital: any;
}

const ImportHospitalProgress = ({ importHospital }: IImportHospitalProgress) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const unsubscribeRef = useRef<any>();
  const dialogApplyRef = useRef<any>(null);
  const dialogErrorRef = useRef<any>(null);
  const dialogResumeRef = useRef<any>(null);
  const importHospitalDataTableRef = useRef<any>(null);
  const dialogModalRef = useRef<any>(null);

  const [dataListError, setDataListError] = useState<any[]>([]);
  const [dataExisted, setDataExisted] = useState<any[]>([]);
  // const [failedImportedCount, setFailedImportedCount] = useState(0);
  // const [rejectReasons, setRejectReasons] = useState([]);
  const [isApply, setIsApply] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [dataForm, setDataForm] = useState({
    TotalRecord: 0,
    InsertedRecord: 0,
    AppliedRecord: 0,
    TotalApplyRecord: 0,
  });

  useEffect(() => {
    let transId = localStorage.getItem('importHospitalTransId') || '0';
    if (importHospital) {
      const { ImportTransId } = importHospital;
      if (transId !== '0' && transId !== `${ImportTransId}`) {
        dispatch(undoImportHospitalBulk({}));
      }
      localStorage.setItem('importHospitalTransId', ImportTransId);
      transId = `${ImportTransId}`;
      setVisible(true);
      setIsProcessing(true);
    }
    // Firestore
    if (unsubscribeRef.current) unsubscribeRef.current();
    getImportHospital(transId, {})
      .then((result) => {
        const data = result.data?.Data;
        // Check resume
        let isResume = false;
        if (data && transId === '0') {
          if (data.IsCompleted) {
            transId = `${data.ImportTransId}`;
            localStorage.setItem('importHospitalTransId', data.ImportTransId);
            setVisible(false);
            setIsProcessing(false);
            dialogResumeRef.current?.toggle();
            isResume = true;
          } else {
            localStorage.setItem('importHospitalTransId', data.ImportTransId);
            onUndoImport();
          }
        }
        if (transId !== '0') {
          const db = getFirestore(FirebaseAman);
          const unsubscribe = onSnapshot(
            doc(db, 'ImportHospital', `ImportHospitalId-${transId}`),
            (docSnapshot) => {
              const source = docSnapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
              console.log(source, ' data: ', docSnapshot.data());
              if (docSnapshot.data()) {
                let {
                  ImportState,
                  InsertedRecord = 0,
                  TotalRecord = 0,
                  IsCompleted,
                  IsError,
                  IsApplied,
                  AppliedRecord = 0,
                  TotalApplyRecord = 0,
                  FailedImportedCnt,
                  // RejectReasons,
                  ErrorMsg,
                } = docSnapshot.data() as any;
                if (isResume) {
                  IsApplied = false;
                }
                // setFailedImportedCount(FailedImportedCnt);
                // if (RejectReasons) {
                //   const rejectReasons = JSON.parse(RejectReasons);
                //   setRejectReasons(rejectReasons);
                // }
                setIsProcessing(false);
                setDataForm((prev) => ({
                  ...prev,
                  InsertedRecord,
                  TotalRecord,
                  AppliedRecord,
                  TotalApplyRecord,
                }));
                if (ImportState === 2 && IsCompleted && !IsApplied && !IsError) {
                  getFailedListImport(transId, {})
                    .then((result) => {
                      const datas: any[] = result.data?.Data || [];
                      setDataExisted(datas.filter((item) => item.IsExisted === true));
                      setDataListError(datas);
                      setVisible((!IsCompleted && !IsError) || (!IsApplied && TotalApplyRecord > 0));
                      if (FailedImportedCnt > 0) {
                        dialogErrorRef.current?.toggle();
                      } else {
                        const dataNotExisteds = datas.filter((item) => item.IsExisted === false);
                        if (dataNotExisteds.length > 0) {
                          dialogErrorRef.current?.toggle();
                        } else {
                          dialogApplyRef.current?.toggle();
                        }
                      }
                    })
                    .catch((err: any) => {
                      dispatch(
                        enqueueSnackbar({
                          message: err,
                          variant: 'error',
                        }),
                      );
                      onUndoImport();
                    });
                } else {
                  setVisible((!IsCompleted && !IsError) || (!IsApplied && TotalApplyRecord > 0));
                }
                if (IsApplied || IsError) {
                  if (IsApplied) {
                    dispatch(
                      enqueueSnackbar({
                        message: `You have successfully updated and/or added ${TotalApplyRecord} healthcare facilities`,
                        variant: 'success',
                      }),
                    );
                  }
                  if (IsError) {
                    dispatch(
                      enqueueSnackbar({
                        message: ErrorMsg,
                        variant: 'error',
                      }),
                    );
                  }
                  onUndoImport();
                }
                if (ImportState === 3) {
                  setIsApply(true);
                } else {
                  setIsApply(false);
                }
              }
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            },
          );
          unsubscribeRef.current = unsubscribe;
        }
      })
      .catch((err: any) => {
        // dispatch(
        //   enqueueSnackbar({
        //     message: err,
        //     variant: 'error',
        //   }),
        // );
        onUndoImport();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importHospital]);

  const onApplyImport = () => {
    dialogApplyRef.current?.toggle();
    // Update record existed
    if (dataExisted.length > 0) {
      const payload = {
        ImportTransId: parseInt(localStorage.getItem('importHospitalTransId') || '0'),
        FailedLists: dataExisted,
      };
      dispatch(
        updateFailedListImport({
          ...payload,
          onSuccess: () => {
            setIsApply(true);
            setIsProcessing(true);
            dispatch(applyImportHospitalBulk({ IsResume: false }));
          },
        }),
      );
    } else {
      setIsApply(true);
      setIsProcessing(true);
      dispatch(applyImportHospitalBulk({ IsResume: false }));
    }
  };

  const onErrorImport = () => {
    const dataChanges = importHospitalDataTableRef.current?.getDataChange();
    let isValid = true;
    dataChanges.forEach((item: any) => {
      if (!item.HospitalName) {
        isValid = false;
      }
      if (!item.HospitalTypeId || item.HospitalTypeId === 0) {
        isValid = false;
      }
      if (!item.HospitalAddress) {
        isValid = false;
      }
      if (!item.HospitalCountryId || item.HospitalCountryId === 0) {
        isValid = false;
      }
      if (!item.InsurerId || item.InsurerId === 0) {
        isValid = false;
      }
      // if (item.CashlessInpatient === false && item.CashlessOutpatient === false) {
      //   isValid = false;
      // }
      if (item.IsMerge) {
        if (!item.MergeHospitalId || item.MergeHospitalId === 0) {
          isValid = false;
        }
      }
    });
    if (!isValid) {
      dispatch(
        enqueueSnackbar({
          message: 'Please fill in all required information!',
          variant: 'error',
        }),
      );
      return;
    }
    // Update record existed
    if (dataExisted.length > 0) {
      dataExisted.forEach((item: any) => {
        dataChanges.push(item);
      });
    }
    const payload = {
      ImportTransId: parseInt(localStorage.getItem('importHospitalTransId') || '0'),
      FailedLists: dataChanges,
    };
    dispatch(
      updateFailedListImport({
        ...payload,
        onSuccess: () => {
          dialogErrorRef.current?.toggle();
          setIsApply(true);
          setIsProcessing(true);
          dispatch(applyImportHospitalBulk({ IsResume: false }));
        },
      }),
    );
  };

  const onResumeImport = () => {
    setIsApply(true);
    setIsProcessing(true);
    dispatch(applyImportHospitalBulk({ IsResume: true }));
    dialogResumeRef.current?.toggle();
  };

  const onUndoImport = () => {
    dispatch(
      undoImportHospitalBulk({
        onSuccess: () => {
          dispatch(setImportHospital(''));
        },
      }),
    );
  };

  const onConfirmHandle = (dialogType?: EDialogType | null, params?: any) => {
    if (dialogType === EDialogType.CLOSE_IMPORT) {
      onUndoImport();
      if (params === 'APPLY') {
        dialogApplyRef?.current?.toggle();
      }
      if (params === 'ERROR') {
        dialogErrorRef?.current?.toggle();
      }
    }
  };

  const renderBodyApply = () => {
    return (
      <>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>
          You have uploaded a total <span style={{ color: '#00C2CB' }}>{dataListError.length}</span>, including{' '}
          <span style={{ color: '#00C2CB' }}>{dataExisted.length}</span> records already existed in the system.
        </p>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>
          <span style={{ color: '#00C2CB' }}>{dataListError.length - dataExisted.length}</span> records require more confirmation
          below.
        </p>
      </>
    );
  };

  const renderBodyError = () => {
    if (dataListError.length === 0) return null;
    return (
      <>
        <ImportHospitalDataTable ref={importHospitalDataTableRef} dataListError={dataListError} />
        {/* {rejectReasons.map((item: any) => {
          return (
            <div key={item.RejectId}>
              <span>{`${item.Name} (${item.Count})`}</span>
            </div>
          );
        })} */}
      </>
    );
  };

  const renderBodyResume = () => {
    return (
      <>
        <p style={{ fontSize: '18px', fontWeight: 600 }}>The upload process has stopped, do you want to resume?</p>
      </>
    );
  };

  const handleMinimize = () => {
    setMinimize(true);
  };

  const handleMaximize = () => {
    setMinimize(false);
  };

  let completedCount;
  let increaseCount;
  let totalCount;
  if (dataForm.TotalApplyRecord > 0) {
    increaseCount = dataForm.AppliedRecord;
    totalCount = dataForm.TotalApplyRecord;
    completedCount = Math.floor((increaseCount / totalCount) * 100);
  } else {
    increaseCount = dataForm.InsertedRecord;
    totalCount = dataForm.TotalRecord;
    completedCount = Math.floor((increaseCount / totalCount) * 100);
  }
  let textTitle = isProcessing ? 'Facilities are being uploaded' : 'Validating...';
  if (isApply) {
    textTitle = 'Saving facilities to database...';
  }
  return (
    <>
      {visible && (
        <div className={classes.progress}>
          <div className={classes.title}>
            <span style={{ flex: '1' }}>{textTitle}</span>
            {!minimize && (
              <Button variant="text" onClick={handleMinimize}>
                <ExpandMoreIcon />
              </Button>
            )}
            {minimize && (
              <Button variant="text" onClick={handleMaximize}>
                <ExpandLessIcon />
              </Button>
            )}
          </div>
          {!minimize && (
            <>
              {isProcessing && (
                <>
                  <div className={classes.content}>
                    <ProgressBar completed={1} bgColor="#00C2CB" height="30px" margin="10px 0px 0px 0px" />
                  </div>
                  <div className={classes.complete}>Processing</div>
                </>
              )}
              {!isProcessing && (
                <>
                  <div className={classes.content}>
                    <ProgressBar completed={completedCount} bgColor="#00C2CB" height="30px" margin="10px 0px 0px 0px" />
                  </div>
                  <div className={classes.complete}>
                    {increaseCount} / {totalCount} Processed
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      <DialogPage
        ref={dialogApplyRef}
        title={t('settings.hospitals.add_or_update_bulk.title')}
        maxWidth={'sm'}
        renderBody={renderBodyApply}
        renderFooter={() => (
          <>
            <Button
              onClick={() => {
                dialogModalRef.current?.showDialog({
                  open: true,
                  cancelTitle: 'common.no',
                  okTitle: 'common.yes',
                  content: t('common.confirm_cancel'),
                  dialogType: EDialogType.CLOSE_IMPORT,
                  params: 'APPLY',
                });
              }}
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
            <Button onClick={onApplyImport} variant="contained">
              {t('common.submit')}
            </Button>
          </>
        )}
      />
      <DialogPage
        ref={dialogErrorRef}
        title={t('settings.hospitals.add_or_update_bulk.title')}
        titleDes={() => (
          <>
            <p style={{ fontSize: '18px', fontWeight: 600, lineHeight: 0 }}>
              You have uploaded a total <span style={{ color: '#00C2CB' }}>{dataListError.length}</span>, including{' '}
              <span style={{ color: '#00C2CB' }}>{dataExisted.length}</span> records already existed in the system.
            </p>
            <p style={{ fontSize: '18px', fontWeight: 600, lineHeight: 1 }}>
              <span style={{ color: '#00C2CB' }}>{dataListError.length - dataExisted.length}</span> records require more
              confirmation below.
            </p>
          </>
        )}
        maxWidth={'xl'}
        fullHeight
        renderBody={renderBodyError}
        renderFooter={() => (
          <>
            <Button
              onClick={() => {
                dialogModalRef.current?.showDialog({
                  open: true,
                  cancelTitle: 'common.no',
                  okTitle: 'common.yes',
                  content: t('common.confirm_cancel'),
                  dialogType: EDialogType.CLOSE_IMPORT,
                  params: 'ERROR',
                });
              }}
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
            <Button onClick={onErrorImport} variant="contained">
              {t('common.submit')}
            </Button>
          </>
        )}
      />
      <DialogPage
        ref={dialogResumeRef}
        title={t('settings.hospitals.add_or_update_bulk.title')}
        maxWidth={'sm'}
        renderBody={renderBodyResume}
        renderFooter={() => (
          <>
            <Button
              onClick={() => {
                onUndoImport();
                dialogResumeRef.current?.toggle();
              }}
              variant="outlined"
            >
              {t('common.no')}
            </Button>
            <Button onClick={onResumeImport} variant="contained">
              {t('common.yes')}
            </Button>
          </>
        )}
      />
      <DialogConfirm ref={dialogModalRef} onConfirm={onConfirmHandle} />
    </>
  );
};

export default ImportHospitalProgress;
