import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseStoreDeliverys } from './interface';

export const fetchStoreDeliverysByFilter = (data: any) => {
  return httpRequest.amanShop.get(PREFIX, { params: data });
};

export const fetchStoreDeliverys = createAsyncThunk<IResponseStoreDeliverys, any, { rejectValue: string }>(
  'StoreDelivery/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchStoreDeliverysByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const addOrUpdateStoreDelivery = createAsyncThunk<any, any, { rejectValue: string }>(
  'StoreDelivery/addOrUpdate',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
