import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_FAMILIES, PREFIX } from './apiUrls';
import { IFamily, IResponseFamilies } from './interface';

export const fetchFamiliesByFilter = (data: any) => {
  return httpRequest.insurance.get(FETCH_FAMILIES, { params: data });
};

export const fetchFamilies = createAsyncThunk<IResponseFamilies, any, { rejectValue: string }>(
  'family/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchFamiliesByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createFamily = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'family/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(PREFIX, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateFamily = createAsyncThunk<any, any, { rejectValue: string }>('family/update', async (payload, thunkAPI) => {
  try {
    const { data } = await httpRequest.insurance.put(PREFIX, payload);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteFamily = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
