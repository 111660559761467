import Checkbox from '@mui/material/Checkbox';
import { FC } from 'react';

type Props = {
  initValue: boolean;
  onValueChange?: (value: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
};

const TCheckBox: FC<Props> = ({ initValue, onValueChange, disabled, readOnly, style, required }) => {
  return (
    <Checkbox
      disabled={disabled}
      readOnly={readOnly}
      defaultChecked={initValue}
      onChange={(e) => {
        onValueChange?.(e.target.checked);
      }}
      style={style}
      className={required ? 'prevent-drag-scroll TCheckBox-required' : 'prevent-drag-scroll'}
    />
  );
};

export default TCheckBox;
