import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IHistoryChangeState } from './interface';
import { fetchHistoryChange, fetchHistoryChangeSearch } from './historyChangeAPI';

const initialState: IHistoryChangeState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
};

export const history = createSlice({
  name: 'history',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryChange.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHistoryChange.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHistoryChange.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchHistoryChangeSearch.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHistoryChangeSearch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHistoryChangeSearch.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = history.actions;
export const usersSelector = (state: RootState) => state.histories;

export default history.reducer;
