import React, { ForwardedRef, forwardRef, memo, useImperativeHandle, useRef } from 'react';

export type AutocompleteListBoxProps = React.HTMLAttributes<HTMLUListElement> & {};

type NullableUlElement = HTMLUListElement | null;

const AutocompleteListBox = forwardRef(({ children, ...rest }: AutocompleteListBoxProps, ref: ForwardedRef<HTMLUListElement>) => {
  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

  return (
    <ul {...rest} ref={innerRef} role="list-box">
      {children}
    </ul>
  );
});

export default memo(AutocompleteListBox);
