import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IHospitalPersonnel } from 'state/hospitals/interface';
import { IResponseHospitalPersonnels } from './interface';

export const fetchHospitalPersonnelsByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchHospitalPersonnels = createAsyncThunk<IResponseHospitalPersonnels, any, { rejectValue: string }>(
  'hospitalPersonnel/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalPersonnelsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHospitalPersonnel = createAsyncThunk<IHospitalPersonnel, any, { rejectValue: string }>(
  'hospitalPersonnel/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospitalPersonnel = createAsyncThunk<any, any, { rejectValue: string }>(
  'hospitalPersonnel/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHospitalPersonnel = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
