import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseHospitalRatings, IHospitalRating } from './interface';

export const fetchHospitalRatingsByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchHospitalRatings = createAsyncThunk<IResponseHospitalRatings, any, { rejectValue: string }>(
  'hospitalRating/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalRatingsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHospitalRating = createAsyncThunk<IHospitalRating, any, { rejectValue: string }>(
  'hospitalRating/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospitalRating = createAsyncThunk<any, any, { rejectValue: string }>(
  'hospitalRating/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHospitalRating = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
