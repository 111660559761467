import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_BANKS } from './apiUrls';

import { IResponseBanks } from './interface';

export const fetchBanks = createAsyncThunk<IResponseBanks, any, { rejectValue: string }>(
  'Bank/fetch',
  async (params, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_BANKS);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
