import React from 'react';

const ArrowRightIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.885 24.885L19.755 18L12.885 11.115L15 9L24 18L15 27L12.885 24.885Z" fill="#C1C1C1" />
    </svg>
  );
};

export default ArrowRightIcon;
