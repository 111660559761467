export const DEFAULT_PAGINATION = {
  page: 0,
  limit: 10,
  requireTotalCount: true,
};

export interface IClientPagination {
  page?: number;
  limit?: number;
  requireTotalCount?: boolean;
  time?: number;
}

export interface IServerPagination {
  skip?: number;
  take?: number;
  requireTotalCount?: boolean;
  _?: number;
}

export const paginationMapping = (filter: IClientPagination): IServerPagination => ({
  skip: (filter.limit || 10) * (filter.page || 0),
  take: filter.limit,
  requireTotalCount: filter.requireTotalCount,
  _: filter.time || Date.now(),
});
