import React from 'react';

const InforIcon = () => {
  // @ts-ignore
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 5.75H9.75V7.25H8.25V5.75ZM8.25 8.75H9.75V13.25H8.25V8.75ZM9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM9 15.5C5.6925 15.5 3 12.8075 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 12.8075 12.3075 15.5 9 15.5Z"
        fill="#C1C1C1"
      />
    </svg>
  );
};

export default InforIcon;
