import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IStreamState } from './interface';
import {
  fetchStream,
  createStream,
  detailStream,
  updateStream,
  createBanner,
  deleteBanner,
  fetchDetailBanners,
  fetchStreamHistory,
} from './streamsAPI';

const initialState: IStreamState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
  isBannerCreated: false,
  isBannerDeleted: false,
  isDeleted: false,
  streamDetail: null,
  bannerList: [],
  totalBannerCount: 0,
  isHistoryFetching: false,
  historyList: [],
  changeDateOptions: [],
  changeTimeOptions: [],
  changeByOptions: [],
  changeAgentRoleOptions: [],
  changeTypeOptions: [],
  interfaceOptions: [],
};

export const streams = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStream.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStream.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchStream.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchDetailBanners.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDetailBanners.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.bannerList = payload.data;
        state.totalBannerCount = payload.totalCount;
      })
      .addCase(fetchDetailBanners.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createStream.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createStream.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createStream.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateStream.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateStream.fulfilled, (state) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateStream.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(detailStream.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(detailStream.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.streamDetail = payload || null;
      })
      .addCase(detailStream.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createBanner.pending, (state) => {
        state.isFetching = true;
        state.isBannerCreated = false;
      })
      .addCase(createBanner.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isBannerCreated = true;
      })
      .addCase(createBanner.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(deleteBanner.pending, (state) => {
        state.isFetching = true;
        state.isBannerDeleted = false;
      })
      .addCase(deleteBanner.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isBannerDeleted = true;
      })
      .addCase(deleteBanner.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchStreamHistory.pending, (state) => {
        state.isHistoryFetching = true;
      })
      .addCase(fetchStreamHistory.fulfilled, (state, { payload }) => {
        state.isHistoryFetching = false;
        const dataList = payload.Data;
        state.historyList = dataList;
        state.changeDateOptions = dataList
          .map((item) => item.ChangeDate)
          .filter((value, index, self) => self.indexOf(value) === index);
        state.changeTimeOptions = dataList
          .map((item) => item.ChangeDate)
          .filter((value, index, self) => self.indexOf(value) === index);
        state.changeByOptions = dataList
          .map((item) => item.ChangeBy)
          .filter((value, index, self) => self.indexOf(value) === index);
        state.changeAgentRoleOptions = dataList
          .map((item) => item.ChangeByRole)
          .filter((value, index, self) => self.indexOf(value) === index);
        state.changeTypeOptions = dataList
          .map((item) => item.ChangeType)
          .filter((value, index, self) => self.indexOf(value) === index);
        state.interfaceOptions = dataList
          .map((item) => `${item.InterfaceWeb}`)
          .filter((value, index, self) => self.indexOf(value) === index);
      })
      .addCase(fetchStreamHistory.rejected, (state, { payload }) => {
        state.isHistoryFetching = false;
        // state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = streams.actions;
export const streamsSelector = (state: RootState) => state.streams;

export default streams.reducer;
