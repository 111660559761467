import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchUserExtract } from './userExtractAPIs';
import { IUserExtractState } from './interface';

const initialState: IUserExtractState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
};

export const userExtractSlice = createSlice({
  name: 'userExtract',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserExtract.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserExtract.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchUserExtract.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = userExtractSlice.actions;
export const userExtractSelector = (state: RootState) => state.userExtract;

export default userExtractSlice.reducer;
