import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/index';

import { createHoliday, fetchHolidays, updateHoliday } from './holidaysAPIs';
import { IHolidayState } from './interface';

const initialState: IHolidayState = {
  isFetching: false,
  isSuccess: false,
  isCreated: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  countryOptions: [],
  currencyOptions: [],
  currencyWithoutCountryNameOptions: [],
};

export const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHolidays.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data.map((item: any, index: number) => ({ index, ...item }));
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHolidays.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createHoliday.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createHoliday.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createHoliday.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateHoliday.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateHoliday.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateHoliday.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = holidaysSlice.actions;
export const holidaysSelector = (state: RootState) => state.holidays;

export default holidaysSlice.reducer;
