/**
 * Download CSV file
 *
 */
export const downloadFile = (fileData: any, name: string) => {
  let { data } = fileData;
  let filename = name;
  let headers: any = '';
  if (fileData && fileData.hasOwnProperty('headers')) {
    headers = fileData.headers;
    delete fileData.reponseHeader;
  }
  let contentType = 'application/octet-stream';
  let orgFileName = filename;
  if (headers !== '') {
    const orgContentType = headers['content-type'];
    if (orgContentType !== undefined) {
      contentType = orgContentType;
    }
    orgFileName = getFileNameFromHeader(headers);
    if (orgFileName !== '') {
      filename = orgFileName;
    }
  } else if (filename.includes('.pdf')) {
    // TODO Fix pdf blank files
    contentType = 'application/pdf';
    if (fileData.data) {
      data = base64ToArrayBuffer(fileData.data);
    } else {
      data = base64ToArrayBuffer(fileData);
    }
  }

  let blob = null;
  if (data) {
    blob = new Blob([data], { type: contentType });
  } else {
    blob = new Blob([fileData], { type: contentType });
  }

  if ((window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    if (filename) {
      a.download = filename;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
export const getFileNameFromHeader = (headers: any) => {
  let fileName = '';
  const headerLine = headers['content-disposition'];
  if (headerLine !== undefined) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headerLine);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
};
export const base64ToArrayBuffer = (data: any) => {
  const binaryString = window.atob(data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};
