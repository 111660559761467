import React, { memo } from 'react';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import TCheckBox from 'components/DataTable/Common/TCheckBox';
import TInput from 'components/DataTable/Common/TInput';
import TAutocomplete from 'components/DataTable/Common/TAutocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableCell from './TableCell';

const TableEditRowCell = ({ ...restProps }: any) => {
  const {
    column: { dataTypes, optionSource, required },
    tableColumn: { align },
    value,
    onValueChange,
    editingEnabled,
  } = restProps;

  // payment_platform
  const platformDataRef = React.useRef<any[]>();
  if (dataTypes === 'payment_platform') {
    platformDataRef.current = [...value];
  }
  const onPaymentPlatformChange = (platformId: any) => (value: any) => {
    const data = platformDataRef.current?.map((item: any) => {
      if (item.PlatformId !== platformId) return { ...item };
      const tmp = { ...item, Checked: value };
      return tmp;
    });
    platformDataRef.current = data;
    if (onValueChange) onValueChange(data);
  };

  if (editingEnabled) {
    if (dataTypes === 'string' || dataTypes === 'number') {
      if (optionSource && optionSource.length > 0) {
        return (
          <TableEditRow.Cell {...restProps} style={{ textAlign: align }}>
            <TAutocomplete initValue={value} onValueChange={onValueChange} options={optionSource} required={required} />
          </TableEditRow.Cell>
        );
      }
      return (
        <TableEditRow.Cell {...restProps} style={{ textAlign: align }}>
          <TInput initValue={value} onValueChange={onValueChange} required={required} />
        </TableEditRow.Cell>
      );
    } else if (dataTypes === 'boolean') {
      return (
        <TableEditRow.Cell {...restProps} style={{ textAlign: align }}>
          <TCheckBox initValue={value} onValueChange={onValueChange} required={required} />
        </TableEditRow.Cell>
      );
    } else if (dataTypes === 'payment_platform') {
      if (!restProps.value || restProps.value.length < 1) return <TableEditRow.Cell {...restProps} />;
      return (
        <TableEditRow.Cell {...restProps}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {restProps.value.map((item: any) => {
              return (
                <FormControlLabel
                  sx={{ paddingTop: '10px', paddingLeft: '8px' }}
                  key={item.PlatformId}
                  control={
                    <TCheckBox
                      key={item.PlatformId}
                      style={{ padding: '0px' }}
                      initValue={item.Checked}
                      onValueChange={onPaymentPlatformChange(item.PlatformId)}
                    />
                  }
                  label={<span style={{ fontSize: '16px', fontWeight: '400', marginLeft: '5px' }}>{item.PlatformName}</span>}
                />
              );
            })}
          </div>
        </TableEditRow.Cell>
      );
    } else if (dataTypes === 'payment_fee') {
      if (!restProps.value || restProps.value.length < 1) return <TableEditRow.Cell {...restProps} />;
      return (
        <TableEditRow.Cell {...restProps}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {restProps.value.map((item: any, index: number) => {
              let fee = '';
              if (item.FeeOffset > 0 && item.FeePercent > 0) {
                fee = `${item.FeePercent}% + ${item.FeeOffset}`;
              } else if (item.FeeOffset > 0) {
                fee = `${item.FeeOffset}`;
              } else if (item.FeePercent > 0) {
                fee = `${item.FeePercent}%`;
              }
              return (
                <span key={index} style={{ fontSize: '16px', fontWeight: '400', paddingTop: '10px' }}>
                  {fee}
                </span>
              );
            })}
          </div>
        </TableEditRow.Cell>
      );
    }
    return <TableEditRow.Cell {...restProps} />;
  } else {
    return <TableCell {...restProps} />;
  }
};

export default memo(TableEditRowCell);
