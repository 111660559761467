import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchStoreVouchers, createStoreVoucher, updateStoreVoucher, changeStatusStoreVoucher } from './storeVouchersAPIs';
import { IStoreVoucherState } from './interface';

const initialState: IStoreVoucherState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  storeVoucher: undefined,
  isCreated: false,
};

export const storeVouchers = createSlice({
  name: 'storeVouchers',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditStore(state, action) {
      state.isUpdated = false;
    },
    requestCreateStore(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreVouchers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStoreVouchers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchStoreVouchers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createStoreVoucher.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createStoreVoucher.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createStoreVoucher.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateStoreVoucher.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateStoreVoucher.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateStoreVoucher.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(changeStatusStoreVoucher.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(changeStatusStoreVoucher.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(changeStatusStoreVoucher.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateStore, requestEditStore, requestClearError } = storeVouchers.actions;
export const storeVouchersSelector = (state: RootState) => state.amanshopStoreVouchers;

export default storeVouchers.reducer;
