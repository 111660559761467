import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import {
  FETCH_ALL_HOSPITAL_API,
  FETCH_ALL_HOSPITAL_DEPARTMENT_API,
  FETCH_ALL_HOSPITAL_PERSONNEL_API,
  HOSPITAL_API,
  MERGE_HOSPITAL_API,
  FETCH_HOSPITAL_DETAIL_API,
  // PREFIX,
} from './apiUrls';
import {
  IHospital,
  IHospitalDepartment,
  IHospitalPersonnel,
  IResponseHospitals,
  IResponseHospitalDetail,
  IMutationPayload,
} from './interface';

export const fetchHospitalByFilter = (data: any) => {
  return httpRequest.insurance.get(`${HOSPITAL_API}/searchWeb`, { params: data });
};

export const fetchHospitalDetailByFilter = (data: any, params: any) => {
  return httpRequest.insurance.get(`${FETCH_HOSPITAL_DETAIL_API}/${data}`, { params: params });
};

export const fetchAllHospital = createAsyncThunk<Array<IHospital>, any, { rejectValue: string }>(
  'Hospital/fetchAll',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_API, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAllHospitalDepartment = createAsyncThunk<Array<IHospitalDepartment>, any, { rejectValue: string }>(
  'Hospital/fetchAllDepartment',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_DEPARTMENT_API, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAllHospitalPersonnel = createAsyncThunk<Array<IHospitalPersonnel>, any, { rejectValue: string }>(
  'Hospital/fetchAllPersonnel',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_ALL_HOSPITAL_PERSONNEL_API, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchHospital = createAsyncThunk<IResponseHospitals, any, { rejectValue: string }>(
  'Hospital/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchHospitalDetail = createAsyncThunk<IResponseHospitalDetail, any, { rejectValue: string }>(
  'Hospital-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchHospitalDetailByFilter(id, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHospital = createAsyncThunk<IHospital, any, { rejectValue: string }>(
  'Hospital/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(HOSPITAL_API, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospital = createAsyncThunk<any, any, { rejectValue: string }>(
  'Hospital/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(HOSPITAL_API, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHospital = (data: any) => httpRequest.insurance.delete(`${HOSPITAL_API}/${data}`);

export const mergeHospital = createAsyncThunk<any, any, { rejectValue: string }>('Hospital/merge', async (payload, thunkAPI) => {
  try {
    const newPayload = cloneDeep(payload);
    const { onSuccess } = newPayload;
    delete newPayload.onSuccess;
    const { data } = await httpRequest.insurance.post(MERGE_HOSPITAL_API, newPayload);
    if (onSuccess) onSuccess();
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const downloadTemplateHospital = () =>
  httpRequest.insurance.get(`${HOSPITAL_API}/download-template-hospital`, {
    responseType: 'arraybuffer',
    timeout: 0,
  });

export const applyImportHospitalBulk = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Hospital/apply-import-hospital-bulk',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { IsResume, onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importHospitalTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.insurance.post(`${HOSPITAL_API}/import/apply`, {
          ImportTransId: transId,
          IsResume: IsResume,
        });
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const undoImportHospitalBulk = createAsyncThunk<any, any & IMutationPayload, { rejectValue: string }>(
  'Hospital/undo-import-hospital-bulk',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    const transId = localStorage.getItem('importHospitalTransId') || '0';
    if (transId !== '0') {
      try {
        const { data } = await httpRequest.insurance.post(`${HOSPITAL_API}/import/undo`, { ImportTransId: transId });
        localStorage.removeItem('importHospitalTransId');
        if (onSuccess) onSuccess(data);
        return data;
      } catch (err: any) {
        if (onError) onError(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const importHospital = (payload: FormData) => {
  return httpRequest.insurance.post(`${HOSPITAL_API}/import`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getImportHospital = (data: any, params: any) => {
  return httpRequest.insurance.get(`${HOSPITAL_API}/import/${data}`, { params: params });
};

export const getFailedListImport = (data: any, params: any) => {
  return httpRequest.insurance.get(`${HOSPITAL_API}/import/failed-list/${data}`, { params: params });
};

export const updateFailedListImport = createAsyncThunk<any, any, { rejectValue: string }>(
  'Hospital/update-failed-list-import',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(`${HOSPITAL_API}/import/failed-list`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
