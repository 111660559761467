import React, { useState, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { EDialogType } from './interfaces';
import Button from '../../components/Button/ButtonRoot';
import { styled } from '@mui/material/styles';
import { colors } from '../../themes/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type TStateDialog = {
  open: boolean;
  content?: string;
  cancelTitle?: string;
  okTitle?: string;
  swapTitle?: boolean;
  dialogType: EDialogType | null;
  params?: any;
};

type TDialogConfirmProps = {
  onConfirm?: (dialogType?: EDialogType | null, params?: any) => void;
  isDisabled?: boolean;
};

const DialogCustomize = styled(Dialog)(({ theme }) => ({
  '& div[role="dialog"]': {
    borderRadius: '16px',
  },
  '& .MuiDialogContent-root': {
    padding: '30px 30px 0 30px',
  },
  '& .MuiDialogTitle-root': {
    padding: '25px 30px',
  },
  '& .MuiDialogActions-root': {
    // padding: theme.spacing(1),
    marginLeft: '20px',
    marginRight: '20px',
    justifyContent: 'space-around',
    marginTop: '35px',
    marginBottom: '30px',
  },
  '& .MuiButton-outlined': {
    borderColor: `${colors._E5E5E5} !important`,
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  '& .MuiButton-contained': {
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  '& button.MuiButton-root': {
    minHeight: '40px',
    minWidth: '150px',
  },
}));

const DialogConfirm = forwardRef<any, TDialogConfirmProps>(({ onConfirm, isDisabled = false }, ref) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<TStateDialog>({ open: false, dialogType: null });

  React.useImperativeHandle(ref, () => ({
    showDialog(formData: TStateDialog) {
      setFormData({ ...formData });
    },
    closeDialog() {
      setFormData({ open: false, dialogType: null });
    },
  }));

  const onCloseHandle = () => {
    setFormData((prev) => ({ ...prev, open: false }));
  };

  const onConfirmHandle = () => {
    if (onConfirm) {
      const { dialogType, params } = formData;
      onConfirm(dialogType, params);
      onCloseHandle();
    }
  };

  return (
    <>
      <DialogCustomize open={formData.open} onClose={onCloseHandle} fullWidth={true} maxWidth={'xs'} disableEscapeKeyDown={true}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton aria-label="close" onClick={onCloseHandle}>
              <CloseIcon fontSize={'medium'} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: formData.content ?? '' }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandle} variant={formData.swapTitle ? 'outlined' : 'contained'}>
            {t(formData.cancelTitle ?? 'common.cancel')}
          </Button>
          <Button onClick={onConfirmHandle} variant={formData.swapTitle ? 'contained' : 'outlined'} disabled={isDisabled}>
            {t(formData.okTitle ?? 'common.save')}
          </Button>
        </DialogActions>
      </DialogCustomize>
    </>
  );
});
export default DialogConfirm;
