import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { ILeadHistoryBasicDto, ILeadHistory, IMeetingDetailData, ILeadHistoryMessageData } from './interface';

export const updateLeadHistory = createAsyncThunk<ILeadHistory, any, { rejectValue: string }>(
  'LeadHistory/update-detail',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.amanSale.put(`${PREFIX}`, payload);
      if (onSuccess) onSuccess();
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchLeadHistory = createAsyncThunk<ILeadHistory, any, { rejectValue: string }>(
  'LeadHistory/get-detail',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.get(`${PREFIX}/detail`, { params: payload });
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getAllLeadHistory = createAsyncThunk<ILeadHistoryBasicDto, any, { rejectValue: string }>(
  'LeadHistory/get-all',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.amanSale.post(`${PREFIX}/logs`, payload);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const addMessage = createAsyncThunk<ILeadHistoryMessageData, any, { rejectValue: string }>(
  'LeadHistory/add-message',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      if (payload.isEdit) {
        const { data } = await httpRequest.amanSale.put(`${PREFIX}/message`, newPayload);
        if (onSuccess) onSuccess();
        return data.Data;
      } else {
        const { data } = await httpRequest.amanSale.post(`${PREFIX}/message`, newPayload);
        if (onSuccess) onSuccess();
        return data.Data;
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getMessage = createAsyncThunk<ILeadHistoryMessageData, any, { rejectValue: string }>(
  'LeadHistory/get-message',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.amanSale.get(`${PREFIX}/message/${newPayload.messageId}`, {});
      if (onSuccess) onSuccess(data.Data);
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const addMeeting = createAsyncThunk<IMeetingDetailData[], any, { rejectValue: string }>(
  'LeadHistory/add-meeting',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      if (payload.isEdit) {
        const { data } = await httpRequest.amanSale.put(`${PREFIX}/meeting`, newPayload);
        if (onSuccess) onSuccess();
        return data.Data;
      } else {
        const { data } = await httpRequest.amanSale.post(`${PREFIX}/meeting`, newPayload);
        if (onSuccess) onSuccess();
        return data.Data;
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getMeeting = createAsyncThunk<IMeetingDetailData, any, { rejectValue: string }>(
  'LeadHistory/get-meeting',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.amanSale.get(`${PREFIX}/meeting/${newPayload.meetingId}`, {});
      if (onSuccess) onSuccess();
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteMeeting = createAsyncThunk<IMeetingDetailData, any, { rejectValue: string }>(
  'LeadHistory/delete-meeting',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.amanSale.delete(`${PREFIX}/meeting/${newPayload.meetingId}`, {});
      if (onSuccess) onSuccess();
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteMessage = createAsyncThunk<IMeetingDetailData, any, { rejectValue: string }>(
  'LeadHistory/delete-message',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;

      const { data } = await httpRequest.amanSale.delete(`${PREFIX}/message/${newPayload.id}`, {});
      if (onSuccess) onSuccess();
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
