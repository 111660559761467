import httpRequest from 'services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IResponseInsuranceProducts } from './interface';
import { PREFIX } from './apiUrls';
import { IFamily } from '../families/interface';

export const fetchInsuranceProducts = createAsyncThunk<IResponseInsuranceProducts, any, { rejectValue: string }>(
  'insuranceProducts/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get<IResponseInsuranceProducts>(`${PREFIX}/GetAll`, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createInsuranceProduct = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'insuranceProduct/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(PREFIX, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateInsuranceProduct = createAsyncThunk<any, any, { rejectValue: string }>(
  'insuranceProduct/update',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.put(PREFIX, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteInsuranceProduct = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
