import { FC, useState, useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  getUserDefaultRole,
  getUserPermission,
  isMultiRole,
  isRoleEmployer,
  isRoleInsurerOrBroker,
  isRoleInternalAdmin,
  isRoleInternalAmanCare,
  isRoleInternalAmanFinance,
  isRoleMerchant,
} from 'utils/tokenUtils';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Box, Drawer as MuiDrawer, CssBaseline, IconButton, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import logoAmanPng from 'assets/images/logoAman.png';
import iconAmanPng from 'assets/images/iconAman.png';

import AppBar from './AppBar';
import NavBar from './NavBar';
import { PATH_NAME } from 'routes/pathName';

const openedMixin = (theme: Theme): CSSObject => ({
  width: `${process.env.REACT_APP_DRAWER_WIDTH}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#00C2CB',
  margin: '0rem',
  borderRadius: '0rem',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#00C2CB',
  margin: '0rem',
  borderRadius: '0rem',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: `${process.env.REACT_APP_DRAWER_WIDTH}px`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MainLayout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(true);

  useEffect(() => {
    if (getUserPermission().length === 0) {
      navigate(PATH_NAME.NOT_ALLOWED);
    } else {
      if (isMultiRole() && getUserDefaultRole() === null) {
        navigate(PATH_NAME.ROLE_SELECT, { state: location });
      } else {
        if (
          location.pathname !== '/' &&
          location.pathname !== PATH_NAME.NOT_ALLOWED &&
          location.pathname !== PATH_NAME.ROLE_SELECT
        ) {
          navigate(location.pathname);
        } else {
          if (isRoleInternalAdmin()) {
            navigate(PATH_NAME.EMPLOYERS);
          } else if (isRoleEmployer()) {
            navigate(PATH_NAME.DASHBOARD);
          } else if (isRoleInsurerOrBroker()) {
            navigate(PATH_NAME.DASHBOARD);
          } else if (isRoleInternalAmanFinance()) {
            navigate(PATH_NAME.MARKETPLACE);
          } else if (isRoleInternalAmanCare()) {
            navigate(PATH_NAME.MARKETPLACE);
          } else if (isRoleMerchant()) {
            navigate(PATH_NAME.MARKETPLACE);
          } else {
            navigate(PATH_NAME.DASHBOARD);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setToggleMenu(true);
    }, 300);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setToggleMenu(false);
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#FFFFFF' }}>
      <CssBaseline />
      <AppBar open={open} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open && (
            <Link
              to="/"
              onClick={handleDrawerOpen}
              style={{
                display: 'contents',
              }}
            >
              <img style={{ paddingRight: '1rem' }} src={iconAmanPng} alt="Icon aman" />
              <img height={24} src={logoAmanPng} alt="Aman" />
            </Link>
          )}
          {!open && (
            <div
              onClick={handleDrawerOpen}
              style={{
                display: 'contents',
                cursor: 'pointer',
              }}
            >
              <img src={iconAmanPng} alt="Icon aman" />
            </div>
          )}
        </DrawerHeader>
        <Divider />
        <NavBar drawerOpen={open} />
      </Drawer>
      {toggleMenu && (
        <IconButton
          sx={{
            position: 'fixed',
            marginLeft: '280px',
            marginTop: '10px',
            backgroundColor: '#fff',
            zIndex: 1202,
            transition: '10s width ease',
            transitionDelay: '5s',
            ':hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            },
          }}
          onClick={handleDrawerClose}
        >
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, overflow: 'auto', width: '100%', position: 'relative', maxHeight: '100vh', minHeight: '100vh' }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
