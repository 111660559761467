import React from 'react';

const ActiveIcon = () => {
  // @ts-ignore
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E5E5E5" />
      <path
        d="M28 11.18L16.59 22.6L12.35 18.36L13.76 16.95L16.59 19.78L26.59 9.78L28 11.18ZM25.79 16.22C25.92 16.79 26 17.39 26 18C26 22.42 22.42 26 18 26C13.58 26 10 22.42 10 18C10 13.58 13.58 10 18 10C19.58 10 21.04 10.46 22.28 11.25L23.72 9.81C22.1 8.67 20.13 8 18 8C12.48 8 8 12.48 8 18C8 23.52 12.48 28 18 28C23.52 28 28 23.52 28 18C28 16.81 27.78 15.67 27.4 14.61L25.79 16.22Z"
        fill="#454550"
      />
    </svg>
  );
};

export default ActiveIcon;
