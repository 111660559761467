import { createSelector } from 'reselect';
import { RootState } from 'state';

export const hospitalSelector = createSelector(
  (state: RootState) => state.hospitals,
  (app) => app,
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.hospitals,
  (app) => app.isFetching,
);

export const hospitalDetailSelector = createSelector(
  (state: RootState) => state.hospitals,
  (app) => app.hospitalDetail,
);
