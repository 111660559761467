import cloneDeep from 'lodash/cloneDeep';
import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IResponseHospitalDepartments, IHospitalDepartment } from './interface';

export const fetchHospitalDepartmentsByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchHospitalDepartments = createAsyncThunk<IResponseHospitalDepartments, any, { rejectValue: string }>(
  'hospitalDepartment/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchHospitalDepartmentsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHospitalDepartment = createAsyncThunk<IHospitalDepartment, any, { rejectValue: string }>(
  'hospitalDepartment/create',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;

    try {
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      onSuccess();
      return data;
    } catch (err: any) {
      onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHospitalDepartment = createAsyncThunk<any, any, { rejectValue: string }>(
  'hospitalDepartment/update',
  async (payload, thunkAPI) => {
    const newPayload = cloneDeep(payload);
    const { onSuccess, onError } = newPayload;
    delete newPayload.onSuccess;
    delete newPayload.onError;
    try {
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      onSuccess();
      return data;
    } catch (err: any) {
      onError(err);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHospitalDepartment = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
