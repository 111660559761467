import React from 'react';

const DiscountIcon = () => {
  // @ts-ignore
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M7 13L13 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
      />
      <path
        d="M7.5 8C7.77614 8 8 7.77614 8 7.5C8 7.22386 7.77614 7 7.5 7C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.20046C3 4.61698 3.23179 4.05741 3.64437 3.64483C4.05695 3.23225 4.61653 3.00046 5.2 3.00046H6.2C6.7809 3.00013 7.33809 2.77006 7.75 2.36046L8.45 1.66046C8.65445 1.45486 8.89752 1.2917 9.16525 1.18037C9.43297 1.06903 9.72006 1.01172 10.01 1.01172C10.3 1.01172 10.587 1.06903 10.8548 1.18037C11.1225 1.2917 11.3656 1.45486 11.57 1.66046L12.27 2.36046C12.6819 2.77006 13.2391 3.00013 13.82 3.00046H14.82C15.4035 3.00046 15.9631 3.23225 16.3756 3.64483C16.7882 4.05741 17.02 4.61698 17.02 5.20046V6.20046C17.0203 6.78136 17.2504 7.33855 17.66 7.75046L18.36 8.45046C18.5656 8.65491 18.7288 8.89798 18.8401 9.1657C18.9514 9.43342 19.0087 9.72051 19.0087 10.0105C19.0087 10.3004 18.9514 10.5875 18.8401 10.8552C18.7288 11.1229 18.5656 11.366 18.36 11.5705L17.66 12.2705C17.2504 12.6824 17.0203 13.2396 17.02 13.8205V14.8205C17.02 15.4039 16.7882 15.9635 16.3756 16.3761C15.9631 16.7887 15.4035 17.0205 14.82 17.0205H13.82C13.2391 17.0208 12.6819 17.2509 12.27 17.6605L11.57 18.3605C11.3656 18.5661 11.1225 18.7292 10.8548 18.8406C10.587 18.9519 10.3 19.0092 10.01 19.0092C9.72006 19.0092 9.43297 18.9519 9.16525 18.8406C8.89752 18.7292 8.65445 18.5661 8.45 18.3605L7.75 17.6605C7.33809 17.2509 6.7809 17.0208 6.2 17.0205H5.2C4.61653 17.0205 4.05695 16.7887 3.64437 16.3761C3.23179 15.9635 3 15.4039 3 14.8205V13.8205C2.99967 13.2396 2.76961 12.6824 2.36 12.2705L1.66 11.5705C1.4544 11.366 1.29125 11.1229 1.17991 10.8552C1.06858 10.5875 1.01126 10.3004 1.01126 10.0105C1.01126 9.72051 1.06858 9.43342 1.17991 9.1657C1.29125 8.89798 1.4544 8.65491 1.66 8.45046L2.36 7.75046C2.76961 7.33855 2.99967 6.78136 3 6.20046V5.20046Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DiscountIcon;
