import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchHospitalTypes } from './hospitalTypesAPIs';
import { IHospitalTypeState } from './interface';

const initialState: IHospitalTypeState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
};

export const hospitalTypesSlice = createSlice({
  name: 'hospitalTypes',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.Data;
      })
      .addCase(fetchHospitalTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = hospitalTypesSlice.actions;
export const hospitalTypesSelector = (state: RootState) => state.hospitalTypes;

export default hospitalTypesSlice.reducer;
