import React from 'react';

import { PATH_NAME } from './pathName';
import { IRoute } from './interface';
import { PERMISSION, RESOURCE_PERMISSION } from 'configs/constants';

const Dashboard = React.lazy(() => import('features/dashboard'));
// features
const Employer = React.lazy(() => import('features/employers'));
const Users = React.lazy(() => import('features/users').then((module) => ({ default: module.DataTable })));
const UsersDetail = React.lazy(() => import('features/users/detail').then((module) => ({ default: module.UserDetail })));
const Claims = React.lazy(() => import('features/claims'));
const ClaimsDetail = React.lazy(() => import('features/claims/detail').then((module) => ({ default: module.ClaimsDetail })));
const Invoices = React.lazy(() => import('features/invoices'));
const Streams = React.lazy(() => import('features/streams').then((module) => ({ default: module.Streams })));
const StreamsDetail = React.lazy(() => import('features/streams/Detail').then((module) => ({ default: module.StreamsDetail })));
const Marketplace = React.lazy(() => import('features/merchants').then((module) => ({ default: module.DataTable })));
const MarketplaceDetail = React.lazy(() =>
  import('features/merchants/detail').then((module) => ({ default: module.MerchantsDetail })),
);
const Merchants = React.lazy(() => import('features/merchants').then((module) => ({ default: module.DataTable })));
const MerchantsDetail = React.lazy(() =>
  import('features/merchants/detail').then((module) => ({ default: module.MerchantsDetail })),
);
const Stores = React.lazy(() => import('features/stores').then((module) => ({ default: module.DataTable })));
const StoresDetail = React.lazy(() => import('features/stores/detail').then((module) => ({ default: module.StoresDetail })));
const Orders = React.lazy(() => import('features/orders').then((module) => ({ default: module.DataTable })));
const OrdersDetail = React.lazy(() => import('features/orders/detail').then((module) => ({ default: module.OrdersDetail })));
const OrdersComplaintDiscussion = React.lazy(() =>
  import('features/orders/detail/complaint-discussion').then((module) => ({ default: module.OrdersComplaintDiscussion })),
);
const Batch = React.lazy(() => import('features/batch').then((module) => ({ default: module.Batch })));
const SettingInsurers = React.lazy(() => import('features/setting/insurers').then((module) => ({ default: module.DataTable })));
const SettingInsurerDetail = React.lazy(() => import('features/setting/insurers').then((module) => ({ default: module.Detail })));
const InsuranceProducts = React.lazy(() =>
  import('features/setting/insurance-product').then((module) => ({ default: module.DataTable })),
);
const InsuranceProductsDetail = React.lazy(() =>
  import('features/setting/insurance-product').then((module) => ({ default: module.Detail })),
);
const SettingFamily = React.lazy(() => import('features/setting/family').then((module) => ({ default: module.DataTable })));
const SettingFamilyDetail = React.lazy(() => import('features/setting/family').then((module) => ({ default: module.Detail })));
const Employees = React.lazy(() => import('features/employees').then((module) => ({ default: module.DataTable })));
const EmployeesDetail = React.lazy(() =>
  import('features/employees/detail').then((module) => ({ default: module.EmployeeDetail })),
);
const Currencies = React.lazy(() => import('features/setting/currencies'));
const CurrencieDetail = React.lazy(() => import('features/setting/currencies/Detail'));
const Holidays = React.lazy(() => import('features/setting/currencies/holidays/Holidays'));
const HolidayDetail = React.lazy(() => import('features/setting/currencies/holidays/Detail'));
const Hospitals = React.lazy(() => import('features/hospitals').then((module) => ({ default: module.DataTable })));
const HospitalsDetail = React.lazy(() =>
  import('features/hospitals/detail').then((module) => ({ default: module.HospitalsDetail })),
);
const ICD_CODES = React.lazy(() => import('features/setting/icd-codes').then((module) => ({ default: module.DataTable })));
const ICD_CODE_DETAIL = React.lazy(() => import('features/setting/icd-codes').then((module) => ({ default: module.Detail })));
const ICD_CODE_ADD = React.lazy(() => import('features/setting/icd-codes').then((module) => ({ default: module.Add })));
const Medical = React.lazy(() => import('features/setting/medical').then((module) => ({ default: module.Medical })));
const MedicalDetail = React.lazy(() =>
  import('features/setting/medical/detail/index').then((module) => ({ default: module.MedicalDetail })),
);
const Email = React.lazy(() => import('features/email').then((module) => ({ default: module.DataTable })));
const Brokers = React.lazy(() => import('features/setting/brokers').then((module) => ({ default: module.DataTable })));
const BrokerDetail = React.lazy(() => import('features/setting/brokers').then((module) => ({ default: module.Detail })));

// Aman sale
const SaleLead = React.lazy(() => import('features/aman-sale/lead').then((module) => ({ default: module.DataTable })));
const LeadDetail = React.lazy(() => import('features/aman-sale/lead/detail').then((module) => ({ default: module.LeadDetail })));

const SaleExchange = React.lazy(() => import('features/aman-sale/exchange').then((module) => ({ default: module.DataTable })));
const ExchangeDetail = React.lazy(() =>
  import('features/aman-sale/exchange/detail').then((module) => ({ default: module.ExchangeDetail })),
);

const SaleDashboard = React.lazy(() =>
  import('features/aman-sale/dashboard').then((module) => ({ default: module.SaleDashboard })),
);

const Goldmine = React.lazy(() => import('features/goldmine').then((module) => ({ default: module.Goldmine })));
const GoldmineDetail = React.lazy(() =>
  import('features/goldmine/detail').then((module) => ({ default: module.GoldmineDetail })),
);

const authRoutes: IRoute[] = [
  {
    path: PATH_NAME.DASHBOARD,
    element: Dashboard,
    isPermit: true,
  },
  {
    path: PATH_NAME.EMPLOYERS,
    element: Employer,
    resource: RESOURCE_PERMISSION.Employers,
    permission: PERMISSION.Employers_Overview.View,
  },
  {
    path: PATH_NAME.USER_MANAGEMENT,
    element: Users,
    resource: RESOURCE_PERMISSION.UserManagement,
    permission: PERMISSION.UserManagement_Overview.View,
  },
  {
    path: PATH_NAME.USER_MANAGEMENT_DETAIL,
    element: UsersDetail,
    resource: RESOURCE_PERMISSION.UserManagement,
    permission: PERMISSION.UserManagement_Overview.View,
  },
  {
    path: PATH_NAME.CLAIMS,
    element: Claims,
    resource: RESOURCE_PERMISSION.Claims,
    permission: PERMISSION.Claims_Overview.View,
  },
  {
    path: PATH_NAME.CLAIMS_DETAIL,
    element: ClaimsDetail,
    resource: RESOURCE_PERMISSION.Claims,
    permission: PERMISSION.Claims_Overview.View,
  },
  {
    path: PATH_NAME.INVOICES,
    element: Invoices,
    resource: RESOURCE_PERMISSION.Invoices,
    permission: PERMISSION.Invoices_Overview.View,
  },
  {
    path: PATH_NAME.STREAMS,
    element: Streams,
    resource: RESOURCE_PERMISSION.CommunicationsStreams,
    permission: PERMISSION.Communications_Streams_Overview.View,
  },
  {
    path: PATH_NAME.STREAMS_DETAIL,
    element: StreamsDetail,
    resource: RESOURCE_PERMISSION.CommunicationsStreams,
    permission: PERMISSION.Communications_Streams_Overview.View,
  },
  {
    path: PATH_NAME.BATCH,
    element: Batch,
    resource: RESOURCE_PERMISSION.Members,
    permission: PERMISSION.Members_Overview.View,
  },
  {
    path: PATH_NAME.EMPLOYEES,
    element: Employees,
    resource: RESOURCE_PERMISSION.Members,
    permission: PERMISSION.Members_Overview.View,
  },
  {
    path: PATH_NAME.EMPLOYEE_DETAIL,
    element: EmployeesDetail,
    resource: RESOURCE_PERMISSION.Members,
    permission: PERMISSION.Members_Overview.View,
  },
  {
    path: PATH_NAME.FAMILY,
    element: SettingFamily,
    resource: RESOURCE_PERMISSION.SettingsFamilyRelationships,
    permission: PERMISSION.Settings_FamilyRelationships_Overview.View,
  },
  {
    path: PATH_NAME.INSURANCE_PRODUCT,
    element: InsuranceProducts,
    resource: RESOURCE_PERMISSION.SettingsInsuranceProducts,
    permission: PERMISSION.Settings_InsuranceProducts_Overview.View,
  },
  {
    path: PATH_NAME.INSURANCE_PRODUCT_DETAIL,
    element: InsuranceProductsDetail,
    resource: RESOURCE_PERMISSION.SettingsInsuranceProducts,
    permission: PERMISSION.Settings_InsuranceProducts_Overview.View,
  },
  {
    path: PATH_NAME.INSURERS,
    element: SettingInsurers,
    resource: RESOURCE_PERMISSION.SettingsInsurers,
    permission: PERMISSION.Settings_Insurers_Overview.View,
  },
  {
    path: PATH_NAME.INSURER_DETAIL,
    element: SettingInsurerDetail,
    resource: RESOURCE_PERMISSION.SettingsInsurers,
    permission: PERMISSION.Settings_Insurers_Overview.View,
  },
  {
    path: PATH_NAME.FAMILY_DETAIL,
    element: SettingFamilyDetail,
    resource: RESOURCE_PERMISSION.SettingsFamilyRelationships,
    permission: PERMISSION.Settings_FamilyRelationships_Overview.View,
  },
  {
    path: PATH_NAME.CURRENCIES,
    element: Currencies,
    resource: RESOURCE_PERMISSION.SettingsCountriesCurrenciesHolidays,
    permission: PERMISSION.Settings_CountriesCurrenciesHolidays_Overview.View,
  },
  {
    path: PATH_NAME.CURRENCIES_DETAIL,
    element: CurrencieDetail,
    resource: RESOURCE_PERMISSION.SettingsCountriesCurrenciesHolidays,
    permission: PERMISSION.Settings_CountriesCurrenciesHolidays_Overview.View,
  },
  {
    path: PATH_NAME.HOLIDAYS,
    element: Holidays,
    resource: RESOURCE_PERMISSION.SettingsCountriesCurrenciesHolidays,
    permission: PERMISSION.Settings_CountriesCurrenciesHolidays_Overview.View,
  },
  {
    path: PATH_NAME.HOLIDAY_DETAIL,
    element: HolidayDetail,
    resource: RESOURCE_PERMISSION.SettingsCountriesCurrenciesHolidays,
    permission: PERMISSION.Settings_CountriesCurrenciesHolidays_Overview.View,
  },
  {
    path: PATH_NAME.HOSPITALS,
    element: Hospitals,
    resource: RESOURCE_PERMISSION.SettingsHospitals,
    permission: PERMISSION.Settings_Hospitals_Overview.View,
  },
  {
    path: PATH_NAME.HOSPITALS_DETAIL,
    element: HospitalsDetail,
    resource: RESOURCE_PERMISSION.SettingsHospitals,
    permission: PERMISSION.Settings_Hospitals_Overview.View,
  },
  {
    path: PATH_NAME.ICD_CODES,
    element: ICD_CODES,
    resource: RESOURCE_PERMISSION.SettingsICD10,
    permission: PERMISSION.Settings_ICD10_Overview.View,
  },
  {
    path: PATH_NAME.ICD_CODES_DETAIL,
    element: ICD_CODE_DETAIL,
    resource: RESOURCE_PERMISSION.SettingsICD10,
    permission: PERMISSION.Settings_ICD10_Overview.View,
  },
  {
    path: PATH_NAME.ICD_CODES_ADD,
    element: ICD_CODE_ADD,
    resource: RESOURCE_PERMISSION.SettingsICD10,
    permission: PERMISSION.Settings_ICD10_Overview.View,
  },
  {
    path: PATH_NAME.MEDICAL_PROCEDURES,
    element: Medical,
    resource: RESOURCE_PERMISSION.SettingsMedicalProcedures,
    permission: PERMISSION.Settings_MedicalProcedures_Overview.View,
  },
  {
    path: PATH_NAME.MEDICAL_PROCEDURES_DETAIL,
    element: MedicalDetail,
    resource: RESOURCE_PERMISSION.SettingsMedicalProcedures,
    permission: PERMISSION.Settings_MedicalProcedures_Overview.View,
  },
  {
    path: PATH_NAME.EMAIL,
    element: Email,
    resource: RESOURCE_PERMISSION.CommunicationsMessages,
    permission: PERMISSION.Communications_Messages_Overview.View,
  },
  {
    path: PATH_NAME.EMAIL_DETAIL,
    element: Email,
    resource: RESOURCE_PERMISSION.CommunicationsMessages,
    permission: PERMISSION.Communications_Messages_Overview.View,
  },
  {
    path: PATH_NAME.BROKERS,
    element: Brokers,
    resource: RESOURCE_PERMISSION.SettingsBrokers,
    permission: PERMISSION.Settings_Brokers_Overview.View,
  },
  {
    path: PATH_NAME.BROKERS_DETAIL,
    element: BrokerDetail,
    resource: RESOURCE_PERMISSION.SettingsBrokers,
    permission: PERMISSION.Settings_Brokers_Overview.View,
  },
  // Aman shop
  {
    path: PATH_NAME.MARKETPLACE,
    element: Marketplace,
    resource: RESOURCE_PERMISSION.AmanShopMarketplace,
    permission: PERMISSION.AmanShop_Marketplace_Overview.View,
  },
  {
    path: PATH_NAME.MARKETPLACE_DETAIL,
    element: MarketplaceDetail,
    resource: RESOURCE_PERMISSION.AmanShopMarketplace,
    permission: PERMISSION.AmanShop_Marketplace_Overview.View,
  },
  {
    path: PATH_NAME.MERCHANTS,
    element: Merchants,
    resource: RESOURCE_PERMISSION.AmanShopMerchants,
    permission: PERMISSION.AmanShop_Merchants_Overview.View,
  },
  {
    path: PATH_NAME.MERCHANTS_DETAIL,
    element: MerchantsDetail,
    resource: RESOURCE_PERMISSION.AmanShopMerchants,
    permission: PERMISSION.AmanShop_Merchants_Overview.View,
  },
  {
    path: PATH_NAME.STORES,
    element: Stores,
    resource: RESOURCE_PERMISSION.AmanShopStores,
    permission: PERMISSION.AmanShop_Stores_Overview.View,
  },
  {
    path: PATH_NAME.STORES_DETAIL,
    element: StoresDetail,
    resource: RESOURCE_PERMISSION.AmanShopStores,
    permission: PERMISSION.AmanShop_Stores_Overview.View,
  },
  {
    path: PATH_NAME.ORDERS,
    element: Orders,
    resource: RESOURCE_PERMISSION.AmanShopOrders,
    permission: PERMISSION.AmanShop_Orders_Overview.View,
  },
  {
    path: PATH_NAME.ORDERS_DETAIL,
    element: OrdersDetail,
    resource: RESOURCE_PERMISSION.AmanShopOrders,
    permission: PERMISSION.AmanShop_Orders_Overview.View,
  },
  {
    path: PATH_NAME.ORDERS_COMPLAINT_DISCUSSION,
    element: OrdersComplaintDiscussion,
    resource: RESOURCE_PERMISSION.AmanShopOrders,
    permission: PERMISSION.AmanShop_Orders_Overview.View,
  },
  // Aman sale
  {
    path: PATH_NAME.SALE_DASHBOARD,
    element: SaleDashboard,
  },
  {
    path: PATH_NAME.SALE_LEAD,
    element: SaleLead,
    resource: RESOURCE_PERMISSION.AmanSaleLeads,
    permission: PERMISSION.AmanSale_Leads_Overview.View,
  },
  {
    path: PATH_NAME.SALE_LEAD_DETAIL,
    element: LeadDetail,
    resource: RESOURCE_PERMISSION.AmanSaleLeads,
    permission: PERMISSION.AmanSale_Leads_Overview.View,
  },
  {
    path: PATH_NAME.SALE_EXCHANGE,
    element: SaleExchange,
    resource: RESOURCE_PERMISSION.AmanSaleExchange,
    permission: PERMISSION.AmanSale_Exchange_Overview.View,
  },
  {
    path: PATH_NAME.SALE_EXCHANGE_DETAIL,
    element: ExchangeDetail,
    resource: RESOURCE_PERMISSION.AmanSaleExchange,
    permission: PERMISSION.AmanSale_Exchange_Overview.View,
  },
  {
    path: PATH_NAME.GOLDMINE_COMPANY,
    element: Goldmine,
    resource: RESOURCE_PERMISSION.Goldmine,
    permission: PERMISSION.Goldmine_Overview.View,
  },
  {
    path: PATH_NAME.GOLDMINE_COMPANY_DETAIL,
    element: GoldmineDetail,
    resource: RESOURCE_PERMISSION.Goldmine,
    permission: PERMISSION.Goldmine_Overview.View,
  },
];

export default authRoutes;
