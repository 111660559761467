import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { fetchBanks } from './banksAPIs';
import { IBanksState } from './interface';

const initialState: IBanksState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  bankList: [],
};

export const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanks.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBanks.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        const banks: any = [];
        payload.Data.forEach((x) => {
          const description = x.SwiftCode ? `${x.SwiftCode} - ${x.AliasName ? x.AliasName : x.BankName}` : x.BankName;
          banks.push({
            ...x,
            BankDescription: description,
          });
        });
        state.bankList = banks;
      })
      .addCase(fetchBanks.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = banksSlice.actions;
export const banksSelector = (state: RootState) => state.banks;

export default banksSlice.reducer;
