import { useState, MouseEvent } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';

import { LANGUAGE } from 'configs/languages';
import { languageSelector } from 'state/app/appSelector';
import { useAppSelector, useAppDispatch } from 'state/hooks';
import { setLanguage } from 'state/app/appSlice';
import { routes, childRoutes } from 'routes/routes';
import { PATH_NAME } from 'routes/pathName';

import useStyles from './styles';
import { KeycloakService } from 'services';
import { getUserDefaultRole, isMultiRole } from 'utils/tokenUtils';
import { REALM_ROLE } from 'configs/constants';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(7)} - 1px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${process.env.REACT_APP_DRAWER_WIDTH}px`,
    width: `calc(100% - ${process.env.REACT_APP_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MenuApp = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid #E5E5E5',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15);',
    borderRadius: '8px',

    '& .MuiMenuItem-root': {
      padding: '0.5rem 2.5rem',

      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: theme.palette.primary.main,
    },
  },
}));

type IProps = {
  open: boolean;
};

const PrimaryAppBar = ({ open = false }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const language = useAppSelector(languageSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorLanguage, setAnchorLanguage] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLanguage = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };

  const handleChangeLanguage = (language: string) => () => {
    dispatch(setLanguage(language));
    setAnchorLanguage(null);
  };

  const handleSwitchRole = () => {
    navigate(PATH_NAME.ROLE_SELECT);
  };

  const handleLogout = () => {
    // localStorage.clear();
    KeycloakService.doLogout();
  };

  const getCompanyName = () => {
    const defaultRole = getUserDefaultRole();
    if (defaultRole) {
      if (
        defaultRole.Role === REALM_ROLE.Employer ||
        defaultRole.Role === REALM_ROLE.Insurer ||
        defaultRole.Role === REALM_ROLE.Broker ||
        defaultRole.Role === REALM_ROLE.Employee
      ) {
        return defaultRole.CompanyName;
      }
      if (defaultRole.Role === REALM_ROLE.Merchant) {
        return defaultRole.MerchantName;
      }
    }
    return 'Aman';
  };

  const renderTextLanguage = () => {
    switch (language) {
      case LANGUAGE.EN: {
        return 'EN';
      }
      case LANGUAGE.ID: {
        return 'ID';
      }
      default:
        return null;
    }
  };

  const getTitleDashboard = () => {
    const menus = routes.concat(childRoutes);
    const menu = menus.find((el) => matchPath(el.path ?? '', location.pathname));
    if (menu) {
      return t(`menu_dashboard.${menu.name}`);
    }
    return 'Aman Dashboard';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#F7F7F7' }} open={open}>
        <Toolbar>
          <Typography variant="h4" sx={{ color: '#00C2CB', fontWeight: '600' }} noWrap component="div">
            {getTitleDashboard()}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            <Button
              className={classes.menuLanguage}
              style={{ color: Boolean(anchorLanguage) ? '#00C2CB' : '#454550' }}
              aria-controls="change-language"
              title="Change Language"
              aria-haspopup="true"
              onClick={handleOpenLanguage}
            >
              <LanguageIcon style={{ marginRight: '1rem' }} />
              {renderTextLanguage()} <ExpandMoreIcon />
            </Button>
            <MenuApp
              id="change-language"
              anchorEl={anchorLanguage}
              keepMounted
              open={Boolean(anchorLanguage)}
              onClose={handleCloseLanguage}
            >
              <MenuItem selected={language === LANGUAGE.EN} onClick={handleChangeLanguage(LANGUAGE.EN)}>
                EN
              </MenuItem>
              <MenuItem selected={language === LANGUAGE.ID} onClick={handleChangeLanguage(LANGUAGE.ID)}>
                ID
              </MenuItem>
            </MenuApp>
            <Typography variant="h6" sx={{ fontWeight: '600', alignSelf: 'center' }} noWrap component="div">
              {`${KeycloakService.getFullName() || 'User'} - ${getCompanyName()}`}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <div className={classes.name}>{`${KeycloakService.getFullName() || 'User'} - ${getCompanyName()}`}</div>
        <Divider />
        <MenuItem>{t('login.change_password')}</MenuItem>
        {isMultiRole() && <MenuItem onClick={handleSwitchRole}>{t('login.change_role')}</MenuItem>}
        <MenuItem onClick={handleLogout}>{t('login.logout')}</MenuItem>
      </Menu>
    </Box>
  );
};

export default PrimaryAppBar;
