import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_HOLIDAY } from './apiUrls';

import { IHoliday, IResponseCurrencies } from './interface';
import { IFamily } from '../../families/interface';

export const fetchHolidays = createAsyncThunk<IResponseCurrencies, any, { rejectValue: string }>(
  'holidays/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(`${FETCH_HOLIDAY}/GetAll/${payload.currencyId}`, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createHoliday = createAsyncThunk<IHoliday, any, { rejectValue: string }>(
  'holidays/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(FETCH_HOLIDAY, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateHoliday = createAsyncThunk<IFamily, any, { rejectValue: string }>(
  'holidays/update',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.put(FETCH_HOLIDAY, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteHoliday = (id: number) => httpRequest.insurance.delete(`${FETCH_HOLIDAY}/${id}`);
