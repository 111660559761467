import React from 'react';

const HospitalIcon = () => {
  // @ts-ignore
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M20.1312 18.3C19.9515 18.1116 19.7066 18.0038 19.4499 18H18.4765V1C18.4765 0.734784 18.374 0.480444 18.1914 0.292908C18.0089 0.105371 17.7613 0 17.5032 0H3.86678C3.738 0.00054925 3.6106 0.0273608 3.49198 0.0788574C3.37335 0.130354 3.26586 0.2055 3.17572 0.299988C2.9959 0.486248 2.89452 0.73766 2.89344 1V18H1.92011C1.79133 18.0005 1.66393 18.0274 1.54531 18.0789C1.42668 18.1304 1.3192 18.2055 1.22905 18.3C1.04923 18.4862 0.947853 18.7377 0.946777 19C0.946777 19.2652 1.04934 19.5196 1.23187 19.7071C1.41441 19.8946 1.66197 20 1.92011 20H19.4401C19.6955 19.9989 19.9402 19.8948 20.1215 19.71C20.2134 19.6174 20.2866 19.5069 20.3367 19.3851C20.3868 19.2632 20.4129 19.1323 20.4134 19C20.4124 18.7377 20.311 18.4862 20.1312 18.3ZM11.6632 18H9.71652V14H11.6632V18ZM16.5299 18H13.6099V13C13.6099 12.7348 13.5073 12.4804 13.3248 12.2929C13.1422 12.1054 12.8947 12 12.6365 12H8.74319C8.61441 12.0005 8.48701 12.0274 8.36838 12.0789C8.24976 12.1304 8.14224 12.2055 8.0521 12.3C7.87228 12.4862 7.77093 12.7377 7.76985 13V18H4.84985V2H16.5299V18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HospitalIcon;
