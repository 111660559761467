export function queryToObject(queryString) {
  return Object.fromEntries(new URLSearchParams(queryString));
};

export const randomString = () => {
  return Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
};

export const IsValidImage = (files) => {
  if (!files || files.length === 0) return false;
  for (let i = 0; i < files.length; i = i + 1) {
    // If no image selected, return
    if (!/^image\//.test(files[i].type)) {
      return false;
    }
  }
  return true;
};

export const saveFileFromResponse = (response) => {
  const blob = new Blob([response.data], { type: response.headers["content-type"] });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'unknown';
  if (contentDisposition) {
    let filename = contentDisposition.split('filename=')[1].split('.')[0];
    let extension = contentDisposition.split('.')[1].split(';')[0];
    fileName = `${filename}.${extension}`;
  }
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const getFileNameNotExt = (fileName, indexOf = 0) => {
  let fileNameNotExt = '';
  const dotLastIdx = fileName.lastIndexOf('.');
  if (dotLastIdx >= 0) fileNameNotExt = fileName.substring(0, dotLastIdx + indexOf);
  return fileNameNotExt;
};

export const getExtension = (fileName, indexOf = 0) => {
  let fileExt = '';
  const dotLastIdx = fileName.lastIndexOf('.');
  if (dotLastIdx >= 0) fileExt = fileName.substring(dotLastIdx + indexOf);
  return fileExt;
};

export const getImageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.');
    };

    img.src = URL.createObjectURL(file);
  });

export const moneyFormat = (money) => {
  if (money === '' || money === null || money === undefined) return '';
  return money.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
};

export const formatNumber = (value) => {
  if (value === '' || value === null || value === undefined) return '';
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const addParamUrl = (url, obj) => {
  if (!url) return "";
  let urlData = new URL(url);
  if (obj) {
    for (const property in obj) {
      urlData.searchParams.append(property, obj[property]);
    }
  }
  return urlData.toString();
};