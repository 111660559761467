import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { FETCH_MEDICAL_DETAIL_API, PREFIX } from './apiUrls';
import { IMedical, IResponseMedicalDetailRespone, IResponseMedical, IResponseMedicalHospital } from './interface';

export const fetchMedicalHospitalByFilter = (medicalId: number, data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/hospital/${medicalId}`, { params: data });
};

export const fetchMedicalHospital = createAsyncThunk<IResponseMedicalHospital, any, { rejectValue: string }>(
  'medicalHospital/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchMedicalHospitalByFilter(payload.medicalId, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchMedicalByFilter = (data: any) => {
  return httpRequest.insurance.get(PREFIX, { params: data });
};

export const fetchMedicalDetailByFilter = (data: any, params: any) => {
  return httpRequest.insurance.get(`${FETCH_MEDICAL_DETAIL_API}/${data}`, { params: params });
};

export const fetchMedical = createAsyncThunk<IResponseMedical, any, { rejectValue: string }>(
  'medical-procedure/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchMedicalByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchMedicalDetail = createAsyncThunk<IResponseMedicalDetailRespone, any, { rejectValue: string }>(
  'medical-procedure-detail/fetch',
  async ({ id, payload }, thunkAPI) => {
    try {
      const { data } = await fetchMedicalDetailByFilter(id, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createMedical = createAsyncThunk<IMedical, any, { rejectValue: string }>(
  'medical-procedure/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateMedical = createAsyncThunk<any, any, { rejectValue: string }>(
  'medical-procedure/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteMedical = (data: any) => httpRequest.insurance.delete(`${PREFIX}/${data}`);
