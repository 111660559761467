import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { DASHBOARD_API } from './apiUrls';

export const fetchDashboard = createAsyncThunk('dashboard/user', async (thunkAPI) => {
  const { data } = await httpRequest.insurance.get(DASHBOARD_API);
  if (data?.Data) {
    return data.Data;
  }
});
