import React from 'react';

type TRefreshIcon = {
  width: number;
  height: number;
};
const RefreshIcon = ({ width, height }: TRefreshIcon) => {
  // @ts-ignore
  return (
    <svg width={width} height={height} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <title>ionicons-v5-b</title>
      <path
        d="M400,148l-21.12-24.57A191.43,191.43,0,0,0,240,64C134,64,48,150,48,256s86,192,192,192A192.09,192.09,0,0,0,421.07,320"
        style={{ fill: 'none', stroke: '#00C2CB', strokeLinecap: 'round', strokeMiterlimit: '10', strokeWidth: '32' }}
      />
      <path
        d="M464,97.42V208a16,16,0,0,1-16,16H337.42c-14.26,0-21.4-17.23-11.32-27.31L436.69,86.1C446.77,76,464,83.16,464,97.42Z"
        fill={'#00C2CB'}
      />
    </svg>
  );
};

export default RefreshIcon;
