import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_ACCOUNT_PHONE_TYPES } from './apiUrls';

import { IResponseAccountPhoneTypes } from './interface';

export const fetchAccountPhoneTypes = createAsyncThunk<IResponseAccountPhoneTypes, any, { rejectValue: string }>(
  'accountPhoneTypes/fetch',
  async (thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_ACCOUNT_PHONE_TYPES);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
