import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchDiscounts, createDiscount, updateDiscount } from './discountsAPIs';
import { IStoreVoucherState } from './interface';

const initialState: IStoreVoucherState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  discount: undefined,
  isCreated: false,
};

export const discounts = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditStore(state, action) {
      state.isUpdated = false;
    },
    requestCreateStore(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscounts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDiscounts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchDiscounts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createDiscount.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createDiscount.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createDiscount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateDiscount.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateDiscount.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateDiscount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestCreateStore, requestEditStore, requestClearError } = discounts.actions;
export const discountsSelector = (state: RootState) => state.amanshopDiscounts;

export default discounts.reducer;
