import React from 'react';

const HospitalServiceIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M19 12H17C17 10.8 16.25 9.72 15.13 9.3L8.97 7H1V18H7V16.56L14 18.5L22 16V15C22 13.34 20.66 12 19 12ZM3 16V9H5V16H3ZM13.97 16.41L7 14.48V9H8.61L14.43 11.17C14.77 11.3 15 11.63 15 12C15 12 13.01 11.95 12.7 11.85L10.32 11.06L9.69 12.96L12.07 13.75C12.58 13.92 13.11 14.01 13.65 14.01H19C19.39 14.01 19.74 14.24 19.9 14.57L13.97 16.41Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HospitalServiceIcon;
