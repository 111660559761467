import React from 'react';

const HospitalArrowDown = ({ color = '#C1C1C1' }: any) => {
  // @ts-ignore
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: color }}>
      <path d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z" fill="currentColor" />
    </svg>
  );
};

export default HospitalArrowDown;
