import React from 'react';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { colors } from 'themes/colors';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_TIME } from 'configs/constants';
import Iconify from '../Email/Iconify';
import InputStyle from '../Toolbar/SearchInput';
import { ColumnChooserIcon, HospitalArrowDownIcon } from '../Svg';
import useDebouncedCallback from '../../hooks/useDebounceCallback';
import isEmpty from 'lodash/isEmpty';

const ColumnChooserContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 70px 0 16px 0;
`;

const ColumnChooserView = styled('div')`
  margin-right: 5px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
`;

type TColumnChooser = {
  LeftComponent?: React.ReactNode;
  placeholderSearch?: string;
  onTextSearchChange?: (text: string) => void;
  onClickHandle?: () => void;
  columnChooserRef: any;
  onToolbarRender?: () => any;
  customStyle?: any;
};
const ColumnChooser = ({
  onClickHandle,
  placeholderSearch,
  onTextSearchChange,
  columnChooserRef,
  LeftComponent,
  customStyle,
}: TColumnChooser) => {
  const { t } = useTranslation();

  const onChangedDebounce = useDebouncedCallback((e: any) => {
    onTextSearchChange?.(e.target.value.trim());
  }, DEBOUNCE_TIME);

  return (
    <ColumnChooserContainer style={customStyle}>
      {LeftComponent}

      {!isEmpty(placeholderSearch) && (
        <InputStyle
          onChange={onChangedDebounce}
          stretchStart={350}
          size="small"
          placeholder={`${placeholderSearch} ...`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'ant-design:search-outlined'} sx={{ color: colors.primary, width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      )}

      <Typography variant="h6" component="span" sx={{ fontWeight: 500, color: '#777777', marginRight: '10px' }}>
        {t('common.showHideColumn')}
      </Typography>

      <ColumnChooserView ref={columnChooserRef} onClick={onClickHandle}>
        <ColumnChooserIcon />
        <HospitalArrowDownIcon color={'#454550'} />
      </ColumnChooserView>
    </ColumnChooserContainer>
  );
};

export default ColumnChooser;
