import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IStoreState } from './interface';
import { fetchStoreGrid, createStore, updateStore, fetchStoreDetail, fetchStoreByMerchantGrid } from './storesAPI';

const initialState: IStoreState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  createdId: undefined,
  isCreated: false,
  storeDetail: null,
};

export const stores = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditStore(state, action) {
      state.isUpdated = false;
    },
    requestCreateStore(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreByMerchantGrid.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStoreByMerchantGrid.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchStoreByMerchantGrid.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchStoreGrid.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStoreGrid.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchStoreGrid.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createStore.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createStore.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createStore.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateStore.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateStore.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateStore.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchStoreDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStoreDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.storeDetail = payload;
      })
      .addCase(fetchStoreDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError, requestCreateStore, requestEditStore } = stores.actions;
export const usersSelector = (state: RootState) => state.amanshopStores;

export default stores.reducer;
