import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IOrderState } from './interface';
import { fetchOrderGrid, createOrder, updateOrder, fetchOrderDetail } from './ordersAPI';

const initialState: IOrderState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  isCreated: false,
  orderDetail: null,
};

export const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditOrder(state, action) {
      state.isUpdated = false;
    },
    requestCreateOrder(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderGrid.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderGrid.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchOrderGrid.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createOrder.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateOrder.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchOrderDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderDetail = payload;
      })
      .addCase(fetchOrderDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError, requestCreateOrder, requestEditOrder } = orders.actions;
export const usersSelector = (state: RootState) => state.amanshopOrders;

export default orders.reducer;
