import Keycloak from 'keycloak-js';
import { REALM_ROLE_LIST, REALM_ROLE_TEXT } from 'configs/constants';
import { RealmRoleAttribute, RoleInfo } from './interface';
import { PATH_NAME } from '../routes/pathName';

let keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
});

const REALM = process.env.REACT_APP_KEYCLOAK_REALM || '';
const CLIENT_IDENTITY_API = process.env.REACT_APP_CLIENT_IDENTITY_API || '';
const CLIENT_INSURANCE_API = process.env.REACT_APP_CLIENT_INSURANCE_API || '';
const CLIENT_AMANSHOP_API = process.env.REACT_APP_CLIENT_AMANSHOP_API || '';
const CLIENT_AMANSALE_API = process.env.REACT_APP_CLIENT_AMANSALE_API || '';

const initKeycloak = (onAuthenticatedCallback: Function, logout: Function) => {
  if (window.location.pathname === PATH_NAME.FULL_PAGE) {
    return onAuthenticatedCallback();
  }
  keycloak
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      enableLogging: true,
    })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        logout();
      }
      return onAuthenticatedCallback();
    })
    .catch(() => {
      return console.error('failed to initialize');
    });
};

// Set token from iframe
const initToken = (dataKeycloak: Keycloak, onAuthenticatedCallback: Function) => {
  keycloak.token = dataKeycloak.token;
  keycloak.authenticated = dataKeycloak.authenticated;
  keycloak.tokenParsed = dataKeycloak.tokenParsed;
  return onAuthenticatedCallback();
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => keycloak.authenticated;

const getAccountId = (): string => keycloak.tokenParsed?.accountId;

const getAccountNumber = (): number => keycloak.tokenParsed?.accountNumber;

const getRealmRoles = (): RealmRoleAttribute[] => keycloak.tokenParsed?.realmRoles || [];

const getUsername = (): string => keycloak.tokenParsed?.preferred_username;

const getFullName = (): string => keycloak.tokenParsed?.name;

const getEmail = (): string => keycloak.tokenParsed?.email;

const getRoles = () => {
  return keycloak.tokenParsed?.realm_access?.roles || [];
};

const getRoleInfos = () => {
  const roleInfos: RoleInfo[] = [];
  const roles = getRoles();
  const realmRoles = getRealmRoles();
  roles.forEach((role) => {
    if (REALM_ROLE_LIST.includes(role)) {
      const roleInfo: RoleInfo = {
        Role: role,
        RoleName: 'Aman',
      };
      roleInfo.RoleName = REALM_ROLE_TEXT[role];
      const realmRole = realmRoles.find((item) => item.role === role);
      if (realmRole) {
        roleInfo.CompanyId = realmRole.company_id;
        roleInfo.CompanyName = realmRole.company_name;
        roleInfo.MerchantId = realmRole.merchant_id;
        roleInfo.MerchantName = realmRole.merchant_name;
      }
      roleInfos.push(roleInfo);
    }
  });
  return roleInfos;
};

// const hasRealmRole = (role: string) => keycloak.hasRealmRole(role);

const hasRealmRole = (role: string) => {
  const roles = getRoles();
  return roles.includes(role);
};

const hasRealmRoles = (roles: string[]) => roles.some((role: string) => keycloak.hasRealmRole(role));

const hasResourceRole = (role: string, resource?: string) => keycloak.hasResourceRole(role, resource);

const hasResourceRoles = (roles: string[], resource?: string) =>
  roles.some((role: string) => keycloak.hasResourceRole(role, resource));

const updateToken = (successCallback: any, errorCallback: any) => {
  if (keycloak.isTokenExpired()) {
    keycloak
      .updateToken(parseInt(process.env.REACT_APP_KEYCLOAK_UPDATE_TOKEN_MIN_VALIDITY || '30'))
      .then(successCallback)
      .catch(errorCallback);
  } else {
    successCallback();
  }
};

const getKeyCloak = () => keycloak;

const KeycloakService = {
  initKeycloak,
  initToken,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getAccountId,
  getAccountNumber,
  getRealmRoles,
  getUsername,
  getFullName,
  getEmail,
  getRoles,
  getRoleInfos,
  hasRealmRole,
  hasRealmRoles,
  hasResourceRole,
  hasResourceRoles,
  updateToken,
  getKeyCloak,
  REALM,
  CLIENT_IDENTITY_API,
  CLIENT_INSURANCE_API,
  CLIENT_AMANSHOP_API,
  CLIENT_AMANSALE_API,
};

export default KeycloakService;
