import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IFamiliesState } from './interface';
import { fetchFamilies, updateFamily, createFamily } from './familyAPI';

const initialState: IFamiliesState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
};

export const family = createSlice({
  name: 'families',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditFamily(state, action) {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchFamilies.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchFamilies.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createFamily.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createFamily.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createFamily.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    // Update user
    builder
      .addCase(updateFamily.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateFamily.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateFamily.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditFamily } = family.actions;
export const usersSelector = (state: RootState) => state.users;

export default family.reducer;
