import React from 'react';

const Hotness2Icon = () => {
  // @ts-ignore
  return (
    <svg width="130" height="16" viewBox="0 0 130 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-2-inside-1_243_3746" fill="white">
        <path d="M1 8C1 4.13401 4.13401 1 8 1H26.6667V15H7.99999C4.134 15 1 11.866 1 8Z" />
      </mask>
      <path d="M1 8C1 4.13401 4.13401 1 8 1H26.6667V15H7.99999C4.134 15 1 11.866 1 8Z" fill="#766EC8" />
      <path
        d="M1 1H26.6667H1ZM26.6667 15H1H26.6667ZM1 15V1V15ZM27.6667 1V15H25.6667V1H27.6667Z"
        fill="#E5E5E5"
        mask="url(#path-2-inside-1_243_3746)"
      />
      <mask id="path-4-inside-2_243_3746" fill="white">
        <path d="M26.667 1H52.3337V15H26.667V1Z" />
      </mask>
      <path d="M26.667 1H52.3337V15H26.667V1Z" fill="#766EC8" />
      <path
        d="M26.667 1H52.3337H26.667ZM52.3337 15H26.667H52.3337ZM26.667 15V1V15ZM53.3337 1V15H51.3337V1H53.3337Z"
        fill="#E5E5E5"
        mask="url(#path-4-inside-2_243_3746)"
      />
      <mask id="path-6-inside-3_243_3746" fill="white">
        <path d="M52.333 1H77.9997V15H52.333V1Z" />
      </mask>
      <path d="M52.333 1H77.9997V15H52.333V1Z" fill="#766EC8" />
      <path
        d="M52.333 1H77.9997H52.333ZM77.9997 15H52.333H77.9997ZM52.333 15V1V15ZM78.9997 1V15H76.9997V1H78.9997Z"
        fill="#E5E5E5"
        mask="url(#path-6-inside-3_243_3746)"
      />
      <mask id="path-8-inside-4_243_3746" fill="white">
        <path d="M78 1H103.667V15H78V1Z" />
      </mask>
      <path d="M78 1H103.667V15H78V1Z" fill="#766EC8" />
      <path
        d="M78 1H103.667H78ZM103.667 15H78H103.667ZM78 15V1V15ZM104.667 1V15H102.667V1H104.667Z"
        fill="#E5E5E5"
        mask="url(#path-8-inside-4_243_3746)"
      />
      <path
        d="M103.667 1H121.667C125.533 1 128.667 4.13401 128.667 8C128.667 11.866 125.533 15 121.667 15H103.667V1Z"
        fill="#E5E5E5"
      />
      <rect x="0.5" y="0.5" width="128.667" height="15" rx="7.5" stroke="#E5E5E5" />
    </svg>
  );
};

export default Hotness2Icon;
