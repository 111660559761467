import { Components, CSSInterpolation } from '@mui/material/styles';
import { colors } from 'themes/colors';

const Button: Components = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        let base: CSSInterpolation = {
          borderRadius: 8,
          boxShadow: 'none',
        };

        if (ownerState.variant === 'contained') {
          base = {
            ...base,
            backgroundColor: colors.primary,
            color: colors.white,
            ':hover': {
              backgroundColor: colors.primary,
            },
          };
        }

        if (ownerState.variant === 'outlined') {
          base = {
            ...base,
            backgroundColor: colors.white,
            color: colors.primary,
            border: '1px solid',
            borderColor: colors.border.primary,
            ':hover': {
              backgroundColor: colors.white,
              color: colors.primary,
              border: '1px solid',
              borderColor: colors.border.secondary,
            },
          };
        }

        if (ownerState.size === 'large') {
          base = {
            ...base,
            padding: '22px',
          };
        }

        return base;
      },
    },
  },
};

export default Button;
