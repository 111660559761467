import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchHospitalPersonnels, createHospitalPersonnel, updateHospitalPersonnel } from './hospitalPersonnelsAPIs';
import { IHospitalPersonnelState } from './interface';

const initialState: IHospitalPersonnelState = {
  isFetching: false,
  isSuccess: false,
  isCreated: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  hospitalPersonnel: undefined,
};

export const hospitalPersonnels = createSlice({
  name: 'hospitalPersonnels',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditHospitalPersonnel(state, action) {
      state.isUpdated = false;
    },
    requestCreateHospitalPersonnel(state, action) {
      state.isCreated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalPersonnels.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalPersonnels.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospitalPersonnels.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createHospitalPersonnel.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createHospitalPersonnel.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isCreated = true;
      })
      .addCase(createHospitalPersonnel.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateHospitalPersonnel.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateHospitalPersonnel.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateHospitalPersonnel.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditHospitalPersonnel, requestCreateHospitalPersonnel } = hospitalPersonnels.actions;
export const hospitalPersonnelsSelector = (state: RootState) => state.hospitalPersonnels;

export default hospitalPersonnels.reducer;
