import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import httpRequest from 'services';
import { PREFIX, BANK_LIST_API } from './apiUrls';
import { IResponseBankAccounts, IBankAccountData, IBankData } from './interface';

export const fetchBankAccountDetail = (data: any, params: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/${data}`, { params: params });
};

export const fetchBankAccountsByFilter = (data: any) => {
  return httpRequest.amanShop.get(`${PREFIX}/grid`, { params: data });
};

export const fetchBankLists = createAsyncThunk<IBankData[], any, { rejectValue: string }>(
  'BankList/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(BANK_LIST_API, {});
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const fetchBankAccounts = createAsyncThunk<IResponseBankAccounts, any, { rejectValue: string }>(
  'BankAccount/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await fetchBankAccountsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createBankAccount = createAsyncThunk<IBankAccountData, any, { rejectValue: string }>(
  'BankAccount/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.post(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateBankAccount = createAsyncThunk<any, any, { rejectValue: string }>(
  'BankAccount/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.amanShop.put(PREFIX, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteBankAccount = (data: any) => httpRequest.amanShop.delete(`${PREFIX}/${data}`);
