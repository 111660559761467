import React from 'react';

const StreamBannerIcon = () => {
  // @ts-ignore
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M18 4H10V10H18V4ZM16 8H12V6H16V8ZM20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 17.98 2 17.98H20C21.1 17.98 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16.01H2V1.98H20V16.01Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StreamBannerIcon;
