import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { fetchPaymentInfos } from './paymentAPIs';
import { IPaymentInfoState } from './interface';

const initialState: IPaymentInfoState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
};

export const paymentInfos = createSlice({
  name: 'paymentInfos',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentInfos.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPaymentInfos.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchPaymentInfos.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState } = paymentInfos.actions;
export const paymentInfoSelector = (state: RootState) => state.amanshopPaymentInfo;

export default paymentInfos.reducer;
