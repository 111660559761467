import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { FETCH_HOSPITAL_CONFIG } from './apiUrls';
import { IHealthcareFacilitiesPayload, IHospitalConfigResponse } from './interface';

export const fetchHealthcareFacilitiesConfig = createAsyncThunk<IHospitalConfigResponse, any, { rejectValue: string }>(
  'healthcareFacilitiesConfig/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(FETCH_HOSPITAL_CONFIG, { params: payload });
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchHealthcareFacilitiesConfigByFilter = (data: any) => {
  return httpRequest.insurance.get(FETCH_HOSPITAL_CONFIG, { params: data });
};

export const updateHealthcareFacilitiesConfig = createAsyncThunk<any, IHealthcareFacilitiesPayload, { rejectValue: string }>(
  'healthcareFacilitiesConfig/update',
  async (payload, thunkAPI) => {
    try {
      const { onSuccess } = payload;

      let formData = new FormData();
      formData.append('key', payload.key);
      formData.append('values', payload.value);
      await httpRequest.insurance.put(`${FETCH_HOSPITAL_CONFIG}?hospitalId=0&insurerId=${payload.insurerId}`, formData, {
        headers: {
          'Content-Type': ' multipart/form-data',
        },
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
