import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#00C2CB',
    },
    success: {
      main: '#00C2CB',
      light: '#0BE3B2',
    },
  },
});

export default lightTheme;
