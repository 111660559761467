import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export default styled(Card)(() => {
  // styles for the button with variant="contained"
  const containedStyles = () => {
    // color value

    return {
      borderRadius: '16px',
      border: '1px solid #E5E5E5',
      // boxShadow: 'none'
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)',
    };
  };

  return {
    ...containedStyles(),
  };
});
