import React from 'react';

const StreamBannerIcon = () => {
  // @ts-ignore
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M8 5V10L12.25 12.52L13.02 11.24L9.5 9.15V5H8ZM18 7V0L15.36 2.64C13.74 1.01 11.49 0 9 0C4.03 0 0 4.03 0 9C0 13.97 4.03 18 9 18C13.97 18 18 13.97 18 9H16C16 12.86 12.86 16 9 16C5.14 16 2 12.86 2 9C2 5.14 5.14 2 9 2C10.93 2 12.68 2.79 13.95 4.05L11 7H18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StreamBannerIcon;
