import { API_VERSION_2 } from 'configs/constants';

export const PREFIX = `${API_VERSION_2}/Employer`;

export const FETCH_EMPLOYERS = `${PREFIX}/search`;

export const ONBOARD = `${PREFIX}/onboard`;

export const ONBOARD_APPLY = `${PREFIX}/onboard/apply`;

export const EMPLOYER_COMPANY = `${PREFIX}/company`;

export const ACCOUNT = `${PREFIX}/account`;
