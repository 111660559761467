import React from 'react';

const VoucherIcon = () => {
  // @ts-ignore
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#777777' }}>
      <path
        d="M22 10V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12C20 10.9 20.9 10 22 10ZM20 8.54C18.81 9.23 18 10.53 18 12C18 13.47 18.81 14.77 20 15.46V18H4V15.46C5.19 14.77 6 13.47 6 12C6 10.52 5.2 9.23 4.01 8.54L4 6H20V8.54ZM9.07 16L12 14.12L14.93 16L14.04 12.64L16.73 10.44L13.26 10.23L12 7L10.73 10.22L7.26 10.43L9.95 12.63L9.07 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VoucherIcon;
