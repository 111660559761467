import { useState, useEffect, useCallback, useMemo, forwardRef } from 'react';
import { Autocomplete, Popper, Stack, ClickAwayListener, Rating, PopperProps } from '@mui/material';
import { KeyboardArrowDown, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { Filter } from '@devexpress/dx-react-grid';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { DataTypes, Option } from './DataTable';
import { DataOptionProps, FetchOptionProps } from './FilterCell';
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '../CheckBox';
import TextField from '../TextField';
import Button from 'components/Button';
import Card from 'components/Card';
import AutocompleteListBox from 'components/Common/AutocompleteListBox';
import { matchSorter } from 'match-sorter';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import sumBy from 'lodash/sumBy';
import { PAYMENT_METHOD_NAME } from 'configs/constants';
export type FilterCellAutocompleteProps = {
  columnName: string;
  dataTypes?: DataTypes;
  optionSource?: Option[];
  width?: string | number;
  filter: Filter | null;
  onFilter: (filter: Filter | null) => void;
  fetchOption: (columnName: string, dataTypes: DataTypes, filter: string, option: FetchOptionProps) => Promise<DataOptionProps>;
};

const DescriptionDiv = styled('div')`
  white-space: pre-line;
  span {
    word-break: break-word;
  }
`;

const optionAll = { label: 'All', value: 'select-all', count: 0 };

const getLabelOptions = (options: Option[], value: any) => {
  return options.find((item: Option) => item.value === value)?.label ?? '';
};

const groupNullOrEmptyOptions = (options: Option[], nullOrEmpty: DataOptionProps) => {
  if (nullOrEmpty?.data?.length > 0) {
    const allOptions = options.filter((item) => !(item.value === null || item.value === undefined || item.value === ''));
    const optionNullOrEmpty = { label: '(Blanks)', value: null, count: nullOrEmpty?.totalCount ?? 0 };
    return [optionNullOrEmpty, ...allOptions];
  } else {
    return options;
  }
};

const convertFilterLabel = (translation: any, option: Option, dataType?: DataTypes, optionSource?: Option[]) => {
  if (option.value === optionAll.value) return option.label;

  if (dataType === 'rating') {
    return (
      <>
        <Rating
          defaultValue={option.value}
          size="small"
          readOnly
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          precision={0.5}
        />
        {` ${option.value} above`}
      </>
    );
  } else if (dataType === 'html') {
    return <DescriptionDiv dangerouslySetInnerHTML={{ __html: option.value }}></DescriptionDiv>;
  } else if (optionSource && optionSource.length > 0) {
    // for flag filter
    if (dataType === 'flag') {
      const country = optionSource.find((item) => item.value === option.value);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={country?.data?.CountryFlag} alt={country?.data?.CountryName} width="20px" height="15px" />
          &nbsp;&nbsp;&nbsp;<span>{country?.data?.CountryName}</span>
        </div>
      );
    }
    // for personel status filter (on/off duty)
    if (dataType === 'onoff') {
      return (
        <>
          <CircleIcon style={{ color: option.value === 1 ? '#00c2cb' : '#c1c1c1', marginRight: '5px' }} />
          {getLabelOptions(optionSource, option.value)}
        </>
      );
    }
    return getLabelOptions(optionSource, option.value);
  } else if (dataType === 'payment_method') {
    type ObjectKey = keyof typeof PAYMENT_METHOD_NAME;
    const methodId = option.value as ObjectKey;

    const name = PAYMENT_METHOD_NAME[methodId];
    return translation(name);
  }

  return option.label;
};

type CustomPopperProps = {
  width: any;
  setOpen: (open: boolean) => void;
  onOkHandler: () => void;
} & PopperProps;

const PopperComponentCustom = forwardRef<any, CustomPopperProps>(function PopperComponentCustom(props, ref) {
  const { t } = useTranslation();
  const { width, setOpen, onOkHandler } = props;

  return (
    <Popper
      {...props}
      style={{
        width: width ?? 'auto',
        minWidth: 200,
      }}
      placement="bottom"
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Card>
          {props.children}
          <Stack borderTop="1px solid #E5E5E5" direction="row" spacing={2} padding={2} justifyContent="flex-end">
            <Button style={{ fontSize: '14px' }} variant="outlined" type="button" onClick={() => setOpen(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="contained" type="button" onClick={onOkHandler}>
              {t('common.ok')}
            </Button>
          </Stack>
        </Card>
      </ClickAwayListener>
    </Popper>
  );
});

const FilterCellAutocomplete = (props: FilterCellAutocompleteProps) => {
  const { columnName, dataTypes, optionSource, width, filter, onFilter, fetchOption } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);
  const [filterOptions, setFilterOptions] = useState<Option[]>([]);
  const [values, setValues] = useState<Option[]>([]);
  const [valueRemoves, setValueRemoves] = useState<Option[]>([]);
  const [openFirst, setOpenFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageListbox, setPageListbox] = useState<number>(1);
  const [inputValue, setInputValue] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [totalCountOption, setTotalCountOption] = useState(0);

  const isIndeterminate = useMemo(() => values.length > 0 && values.length < options.length, [values, options]);

  const fetchOptions = useCallback(
    (value?: string, page: number = 1) => {
      const group = JSON.stringify([{ selector: columnName, isExpanded: false }]);
      const filter = value ? JSON.stringify([columnName, 'contains', value]) : '';
      return fetchOption(columnName, dataTypes, filter, {
        group,
        skip: 0,
        take: optionSource && optionSource.length > 0 ? optionSource.length + 1 : page * 10,
        requireTotalCount: true,
      });
    },
    [columnName, dataTypes, optionSource, fetchOption],
  );

  const fetchNullOrEmptyOptions = useCallback(() => {
    const group = JSON.stringify([{ selector: columnName, isExpanded: false }]);
    const filter = `[${JSON.stringify([columnName, '=', null])},"or",${JSON.stringify([columnName, '=', ''])}]`;
    return fetchOption(columnName, dataTypes, filter, {
      group,
      skip: 0,
      take: 2,
      requireTotalCount: true,
    });
  }, [columnName, dataTypes, fetchOption]);

  useEffect(() => {
    if (filter?.value && filter?.value.length > 0) {
      if (options.length === 0) {
        setLoading(true);
        fetchOptions().then((response) => {
          fetchNullOrEmptyOptions().then((responseNullOrEmpty) => {
            const dataOptions = [
              optionAll,
              ...groupNullOrEmptyOptions(
                response.data.map((item) => ({ label: item.key, value: item.key, count: item.count ?? 0 })),
                responseNullOrEmpty,
              ),
            ];
            setOptions(dataOptions);
            setTotalCountOption(response.totalCount ?? 0);
            setLoading(false);
          });
        });
      }
      if (options.length > 0) {
        if (filter?.operation === '!=') {
          setIsSelectAll(true);
          const diffOptions = differenceWith(options, filter?.value, isEqual);
          setValues(diffOptions);
          setValueRemoves(filter?.value);
        } else {
          setValues(filter?.value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.value, options]);

  useEffect(() => {
    if (pageListbox > 1) {
      setLoading(true);
      fetchOptions(inputValue, pageListbox).then((response) => {
        fetchNullOrEmptyOptions().then((responseNullOrEmpty) => {
          const dataOptions = [
            optionAll,
            ...groupNullOrEmptyOptions(
              response.data.map((item) => ({ label: item.key, value: item.key, count: item.count ?? 0 })),
              responseNullOrEmpty,
            ),
          ];
          setOptions(dataOptions);
          setFilterOptions(dataOptions);
          if (isSelectAll) {
            if (filter?.value && filter?.value.length > 0) {
              const diffOptions = differenceWith(dataOptions, filter?.value, isEqual);
              setValues(diffOptions);
            } else {
              setValues(dataOptions);
            }
          }
          setLoading(false);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageListbox]);

  const handleChange = useCallback(
    (event, selectedOptions, reason) => {
      if (reason === 'selectOption' && isSelectAll === false) {
        if (selectedOptions.find((option: any) => option.value === 'select-all')) {
          setIsSelectAll(true);
          setValues(options);
        } else {
          setValues(selectedOptions);
        }
      }
      if (reason === 'removeOption') {
        if (isSelectAll) {
          if (selectedOptions.find((option: any) => option.value === 'select-all')) {
            const diffOptions = differenceWith(options, selectedOptions, isEqual);
            setValues(selectedOptions);
            setValueRemoves(diffOptions);
          } else {
            setIsSelectAll(false);
            setValues([]);
            setValueRemoves([]);
          }
        } else {
          setValues(selectedOptions);
        }
      }
      if (reason === 'clear') {
        setIsSelectAll(false);
        setValues([]);
        setValueRemoves([]);
        onFilter(null);
      }
    },
    [isSelectAll, onFilter, options],
  );

  const onOkHandler = () => {
    if (isSelectAll) {
      if (dataTypes && dataTypes === 'rating') {
        onFilter({ columnName, operation: '!>=', value: valueRemoves });
      } else {
        onFilter({ columnName, operation: '!=', value: valueRemoves });
      }
    } else {
      if (dataTypes && dataTypes === 'rating') {
        onFilter({ columnName, operation: '>=', value: values });
      } else {
        onFilter({ columnName, operation: '=', value: values });
      }
    }
    setOpen(false);
  };

  const onOpenHandler = (event: React.SyntheticEvent) => {
    if (event.type === 'click') {
      setOpen(true);
      if (openFirst && filter === null) {
        setLoading(true);
        fetchOptions().then((response) => {
          if (response.data.length > 0) {
            fetchNullOrEmptyOptions().then((responseNullOrEmpty) => {
              const dataOptions = [
                optionAll,
                ...groupNullOrEmptyOptions(
                  response.data.map((item) => ({ label: item.key, value: item.key, count: item.count ?? 0 })),
                  responseNullOrEmpty,
                ),
              ];
              setOptions(dataOptions);
              setTotalCountOption(response.totalCount ?? 0);
              setLoading(false);
            });
          } else {
            setLoading(false);
          }
        });
        setOpenFirst(false);
      }
    }
  };

  const onInputChangeHandle = (event: React.SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
    if (value !== inputValue && (reason === 'input' || reason === 'reset')) {
      setOpen(true);
      setLoading(true);
      fetchOptions(optionSource && optionSource.length > 0 ? '' : value).then((response) => {
        if (response.data && response.data.length > 0) {
          fetchNullOrEmptyOptions().then((responseNullOrEmpty) => {
            const dataOptionResults = groupNullOrEmptyOptions(
              response.data.map((item) => ({ label: item.key, value: item.key, count: item.count ?? 0 })),
              responseNullOrEmpty,
            );
            let dataOptions = [optionAll, ...dataOptionResults];
            if (optionSource && optionSource.length > 0) {
              const dataFilterOptionSource = matchSorter(optionSource, value, { keys: ['label'] });
              if (dataFilterOptionSource && dataFilterOptionSource.length > 0) {
                const dataFilters = dataOptionResults.filter((item) =>
                  dataFilterOptionSource.map((item) => item.value).includes(item.value),
                );
                dataOptions = [optionAll, ...dataFilters];
              } else {
                dataOptions = [];
              }
            }
            setFilterOptions(dataOptions);
            if ((optionSource && optionSource.length > 0) || value === '') {
              setOptions(dataOptions);
            }
            if (isSelectAll) {
              setValues(dataOptions);
            }
            setLoading(false);
          });
        } else {
          setFilterOptions([]);
          setLoading(false);
        }
      });
    }
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      open={open}
      onOpen={onOpenHandler}
      loading={loading}
      options={inputValue ? filterOptions : options}
      value={values}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={onInputChangeHandle}
      sx={{ margin: '8px' }}
      filterOptions={(options, state) => options}
      renderInput={(props: any) => (
        <TextField
          {...props}
          style={{ borderColor: values.length > 0 ? '#00C2CB' : '#E5E5E5' }}
          variant="outlined"
          placeholder="Filter"
        />
      )}
      renderTags={(selected, getTagProps, ownerState) => {
        return isSelectAll ? `+${totalCountOption - sumBy(valueRemoves, 'count')}` : `+${sumBy(selected, 'count')}`;
      }}
      renderOption={(props, option, state) => {
        const selectAllProps = option.value === 'select-all' ? { checked: isSelectAll, indeterminate: isIndeterminate } : {};
        const isChecked = values.find((item: any) => item.value === option.value);
        return (
          <li {...props} key={props.id}>
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              style={{ marginRight: 8 }}
              checked={isChecked ? true : false}
              {...selectAllProps}
            />
            {option.value === null ? '(Blanks)' : convertFilterLabel(t, option, dataTypes, optionSource)}
          </li>
        );
      }}
      ListboxComponent={AutocompleteListBox}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (Math.round(listboxNode.scrollTop) + listboxNode.clientHeight === listboxNode.scrollHeight) {
            if (pageListbox * 10 < totalCountOption) {
              setPageListbox((page) => page + 1);
            }
          }
        },
      }}
      getOptionLabel={(option) => (option.value === null ? '' : `${option.label}`)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableCloseOnSelect
      limitTags={-1}
      popupIcon={<KeyboardArrowDown style={{ color: '#C1C1C1' }} />}
      PopperComponent={PopperComponentCustom as any}
      componentsProps={{
        popper: { width, setOpen, onOkHandler } as CustomPopperProps,
      }}
    />
  );
};

export default FilterCellAutocomplete;
