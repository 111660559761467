import React from 'react';

const ProductActiveIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.675 6.5375L23.9375 4.4375C23.6 4.0125 23.0875 3.75 22.5 3.75H7.5C6.9125 3.75 6.4 4.0125 6.05 4.4375L4.325 6.5375C3.9625 6.9625 3.75 7.525 3.75 8.125V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V8.125C26.25 7.525 26.0375 6.9625 25.675 6.5375ZM7.8 6.25H22.2L23.2375 7.5H6.775L7.8 6.25ZM6.25 23.75V10H23.75V23.75H6.25Z"
        fill="white"
      />
      <rect width="15" height="15" transform="translate(14 14)" fill="#766EC8" />
      <path
        d="M21.5 15.25C18.05 15.25 15.25 18.05 15.25 21.5C15.25 24.95 18.05 27.75 21.5 27.75C24.95 27.75 27.75 24.95 27.75 21.5C27.75 18.05 24.95 15.25 21.5 15.25ZM21.5 26.5C18.7438 26.5 16.5 24.2563 16.5 21.5C16.5 18.7438 18.7438 16.5 21.5 16.5C24.2563 16.5 26.5 18.7438 26.5 21.5C26.5 24.2563 24.2563 26.5 21.5 26.5ZM24.3687 18.7375L20.25 22.8562L18.6312 21.2438L17.75 22.125L20.25 24.625L25.25 19.625L24.3687 18.7375Z"
        fill="white"
      />
    </svg>
  );
};

export default ProductActiveIcon;
