import React from 'react';

const SalesIcon = () => {
  // @ts-ignore
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25ZM15.3875 13.925C13.175 13.3625 12.4625 12.75 12.4625 11.8375C12.4625 10.7875 13.45 10.05 15.0875 10.05C16.8125 10.05 17.4625 10.875 17.5125 12.1H19.65C19.5875 10.425 18.5625 8.8875 16.5375 8.3875V6.25H13.625V8.3625C11.7375 8.7625 10.225 9.9875 10.225 11.875C10.225 14.1125 12.0875 15.2375 14.8 15.8875C17.2375 16.4625 17.725 17.325 17.725 18.225C17.725 18.8875 17.2375 19.9625 15.1 19.9625C13.1 19.9625 12.3125 19.0625 12.2 17.9125H10.05C10.175 20.0375 11.75 21.2375 13.625 21.625V23.75H16.55V21.6625C18.45 21.3 19.95 20.2125 19.9625 18.2C19.95 15.45 17.5875 14.5 15.3875 13.925Z"
        fill="white"
      />
    </svg>
  );
};

export default SalesIcon;
