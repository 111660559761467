import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IUsersState } from './interface';
import { fetchUsers, createUser, getUserByRole } from './usersAPIs';

const initialState: IUsersState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  isCreated: false,
  isUpdated: false,
  dataList: [],
  totalCount: 0,
  userByRole: [],
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditUser(state, action) {
      state.isUpdated = false;
    },
    requestCreateUser(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchUsers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder.addCase(getUserByRole.fulfilled, (state, { payload }) => {
      state.userByRole = payload || [];
    });
  },
});

export const { clearState, requestCreateUser, requestEditUser, requestClearError } = users.actions;
export const usersSelector = (state: RootState) => state.users;

export default users.reducer;
