import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { IHospitalState } from './interface';
import {
  fetchAllHospital,
  fetchHospital,
  updateHospital,
  createHospital,
  fetchHospitalDetail,
  fetchAllHospitalDepartment,
  fetchAllHospitalPersonnel,
} from './hospitalsAPI';

const initialState: IHospitalState = {
  isFetching: false,
  isSuccess: false,
  isUpdated: false,
  isError: false,
  errMsg: '',
  dataList: [],
  totalCount: 0,
  family: undefined,
  createdId: undefined,
  isCreated: false,
  hospitalDetail: null,
  dataListAll: [],
  dataListAllDepartment: [],
  dataListAllPersonnel: [],
};

export const hospitals = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestEditHospital(state, action) {
      state.isUpdated = false;
    },
    requestCreateHospital(state, action) {
      state.isCreated = false;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllHospital.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllHospital.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataListAll = payload;
      })
      .addCase(fetchAllHospital.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchAllHospitalDepartment.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllHospitalDepartment.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataListAllDepartment = payload;
      })
      .addCase(fetchAllHospitalDepartment.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchAllHospitalPersonnel.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllHospitalPersonnel.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataListAllPersonnel = payload;
      })
      .addCase(fetchAllHospitalPersonnel.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchHospital.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospital.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.dataList = payload.data;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchHospital.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(createHospital.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createHospital.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isCreated = true;
      })
      .addCase(createHospital.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(updateHospital.pending, (state) => {
        state.isFetching = true;
        state.isUpdated = false;
      })
      .addCase(updateHospital.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUpdated = true;
      })
      .addCase(updateHospital.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });

    builder
      .addCase(fetchHospitalDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchHospitalDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.hospitalDetail = payload;
      })
      .addCase(fetchHospitalDetail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestEditHospital, requestCreateHospital, requestClearError } = hospitals.actions;
export const usersSelector = (state: RootState) => state.hospitals;

export default hospitals.reducer;
