import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import { downloadInvoice, fetchInvoices } from './invoicesAPIs';
import { IInvoicesState } from './interface';
import { Node } from 'components/TreeViewSelect';
import { makeId } from 'helpers/string';

const initialState: IInvoicesState = {
  dataList: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  isDownloading: false,
  isDownloadSuccess: false,
  isDownloadError: false,
  errMsg: '',
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setErrorMsg: (state, { payload }) => (state.errMsg = payload),
    clearState: (state) => {
      if (!state.isSuccess) {
        return initialState;
      } else {
        // Case: Download error - Fix bug clear all data if download fail
        state.isDownloading = false;
        state.isDownloadSuccess = false;
        state.isDownloadError = false;
        state.errMsg = '';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        const dataList = payload.Data.Data || [];
        state.dataList = dataList;
        const invoiceDates = dataList.map((item: any) => item.InvoiceDate);
        state.invoiceDateOptions = formatDates(invoiceDates);
        state.invoiceNumberOptions = dataList
          .map((item: any) => item.InvoiceNumber)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .map((value: any) => ({ value: value, label: value }));
        state.companyNameOptions = dataList
          .map((item: any) => item.CompanyName)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .map((value: any) => ({ value: value, label: value }));
        state.invoiceAmountOptions = dataList
          .map((item: any) => item.InvoiceAmount)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .map((value: any) => ({ value: value, label: value.toString() }));
        state.paidAmountOptions = dataList
          .map((item: any) => item.PaidAmount)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .map((value: any) => ({ value: value, label: value.toString() }));
        state.invoiceFileOptions = dataList
          .map((item: any) => item.InvoiceFile)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .map((value: any) => ({ value: value, label: value.toString() }));
        const payByList = dataList.map((item: any) => item.PayBy);
        state.payByOptions = formatDates(payByList);
        const paidOnList = dataList.map((item: any) => item.PaidOn);
        state.paidOnOptions = formatDates(paidOnList);
      })
      .addCase(fetchInvoices.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errMsg = payload || '';
      })
      .addCase(downloadInvoice.pending, (state) => {
        state.isDownloading = true;
      })
      .addCase(downloadInvoice.fulfilled, (state) => {
        state.isDownloading = false;
        state.isDownloadSuccess = true;
      })
      .addCase(downloadInvoice.rejected, (state, { payload }) => {
        state.isDownloading = false;
        state.isDownloadError = true;
        state.errMsg = payload || '';
      });
  },
});

function formatDates(dates: string[]) {
  const formattedDates = dates
    .filter((date) => date)
    .map((date) => date.split('T')[0])
    .sort();

  // Group date by year, month, day
  let groupDatesArr: any = [];
  formattedDates.forEach(function (item) {
    const splittedDate = item.split('-'); // [year, month, day]
    let listMonth: any = [];
    const year = splittedDate[0];
    const month = splittedDate[1];
    const day = splittedDate[2];
    listMonth[month] = [day];
    if (typeof groupDatesArr[year] == 'undefined') {
      groupDatesArr[year] = listMonth;
    }
    if (typeof groupDatesArr[year][month] == 'undefined') {
      groupDatesArr[year][month] = [day];
    } else {
      if (!groupDatesArr[year][month].includes(day)) {
        groupDatesArr[year][month].push(day);
      }
    }
  });

  // Convert to array of object
  const dateOptions: Node[] = [];
  groupDatesArr.forEach((item: any, year: string) => {
    let months: Node[] = [];
    const yearId = makeId();
    const yearNode: Node = { id: yearId, label: year.toString(), value: year.toString() };

    Object.keys(item).forEach((month) => {
      const monthId = makeId();
      let monthNode: Node = { id: monthId, label: month.toString(), value: month.toString(), parent: yearNode };
      const days: Node[] = Object.keys(item[month]).map((v) => {
        return {
          id: makeId(),
          label: item[month][v].toString(),
          value: item[month][v].toString(),
          parent: monthNode,
        };
      });
      months.push({
        id: monthId,
        label: month.toString(),
        value: month.toString(),
        children: days,
        parent: yearNode,
      });
    });

    dateOptions.push({
      id: yearId,
      label: year.toString(),
      value: year.toString(),
      children: months,
    });
  });

  return dateOptions;
}

export const { clearState, setErrorMsg } = invoicesSlice.actions;
export const invoicesSelector = (state: RootState) => state.invoices;

export default invoicesSlice.reducer;
