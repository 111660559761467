/** Config maxsize when upload files to FCS */
export const MAX_FILE_SIZE = 52428800; // 50MB

/** TABLE */
export const PAGE_SIZES = [10, 20, 30, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;

/** API VERSION */
export const API_VERSION_1 = 'v1.0';
export const API_VERSION_2 = 'v2.0';

/** DATE TIME FORMAT */
export const DEFAULT_FORMAT_DATETIME = 'YYYY-MM-DD-HH-mm-ss	';
export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD';

/** DEBOUNCE TIME */
export const DEBOUNCE_TIME = 500;

export const REALM_ROLE = {
  Admin: 'admin',
  // Insurance
  Broker: 'broker',
  Employee: 'employee',
  Employer: 'employer',
  Insurer: 'insurer',
  // Amanshop
  AmanCare: 'aman-care',
  AmanFinance: 'aman-finance',
  Customer: 'customer',
  Merchant: 'merchant',
  // Amansale
  AmanSaleAgent: 'aman-sale-agent',
};

export const REALM_ROLE_LIST = [
  REALM_ROLE.Admin,
  // Insurance
  REALM_ROLE.Broker,
  REALM_ROLE.Employee,
  REALM_ROLE.Employer,
  REALM_ROLE.Insurer,
  // Amanshop
  REALM_ROLE.AmanCare,
  REALM_ROLE.AmanFinance,
  REALM_ROLE.Customer,
  REALM_ROLE.Merchant,
  // Amansale
  REALM_ROLE.AmanSaleAgent,
];

export const REALM_ROLE_TEXT = {
  [REALM_ROLE.Admin]: 'Internal Admin',
  // Insurance
  [REALM_ROLE.Broker]: 'Broker',
  [REALM_ROLE.Employee]: 'Employee',
  [REALM_ROLE.Employer]: 'Employer',
  [REALM_ROLE.Insurer]: 'Insurer',
  // Amanshop
  [REALM_ROLE.AmanCare]: 'Internal Aman Care',
  [REALM_ROLE.AmanFinance]: 'Internal Aman Finance',
  [REALM_ROLE.Customer]: 'Customer',
  [REALM_ROLE.Merchant]: 'Merchant',
  // Amansale
  [REALM_ROLE.AmanSaleAgent]: 'Internal Aman Sale Agent',
};

export const ACTION_PERMISSION = {
  View: 'View',
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete',
  Import: 'Import',
  Export: 'Export',
};

export const RESOURCE_PERMISSION = {
  Employers: 'Employers',
  Members: 'Members',
  Analytics: 'Analytics',
  UserManagement: 'UserManagement',
  Claims: 'Claims',
  Invoices: 'Invoices',
  CommunicationsMessages: 'Communications-Messages',
  CommunicationsStreams: 'Communications-Streams',
  SettingsClaimTemplate: 'Settings-ClaimTemplate',
  SettingsInsurers: 'Settings-Insurers',
  SettingsBrokers: 'Settings-Brokers',
  SettingsFamilyRelationships: 'Settings-FamilyRelationships',
  SettingsInsuranceProducts: 'Settings-InsuranceProducts',
  SettingsCountriesCurrenciesHolidays: 'Settings-CountriesCurrenciesHolidays',
  SettingsHospitals: 'Settings-Hospitals',
  SettingsICD10: 'Settings-ICD10',
  SettingsMedicalProcedures: 'Settings-MedicalProcedures',
  // Amanshop
  AmanShopMarketplace: 'AmanShop-Marketplace',
  AmanShopMerchants: 'AmanShop-Merchants',
  AmanShopOrders: 'AmanShop-Orders',
  AmanShopStores: 'AmanShop-Stores',
  // Amansale
  AmanSaleDashboard: 'AmanSale-Dashboard',
  AmanSaleLeads: 'AmanSale-Leads',
  AmanSaleExchange: 'AmanSale-Exchange',
  Goldmine: 'Goldmine',
};

export const PERMISSION = {
  // Employer
  Employers_Overview: {
    View: 'Employers_Overview_View',
    Edit: 'Employers_Overview_Edit',
  },
  Employers_Detail: {
    View: 'Employers_Detail_View',
    Edit: 'Employers_Detail_Edit',
  },
  Employers_SaaSFee: {
    View: 'Employers_SaaSFee_View',
    Edit: 'Employers_SaaSFee_Edit',
  },
  Employers_SubPlans: {
    View: 'Employers_SubPlans_View',
    Edit: 'Employers_SubPlans_Edit',
  },
  // Members
  Members_Overview: {
    View: 'Members_Overview_View',
    Edit: 'Members_Overview_Edit',
  },
  Members_Detail: {
    View: 'Members_Detail_View',
    Edit: 'Members_Detail_Edit',
  },
  // Analytics
  Analytics_Claimants: {
    View: 'Analytics_Claimants_View',
  },
  Analytics_Utilization: {
    View: 'Analytics_Utilization_View',
  },
  Analytics_Claims: {
    View: 'Analytics_Claims_View',
  },
  Analytics_ActivatedUsers: {
    View: 'Analytics_ActivatedUsers_View',
  },
  Analytics_Revenue: {
    View: 'Analytics_Revenue_View',
  },
  // User Management
  UserManagement_Overview: {
    View: 'UserManagement_Overview_View',
  },
  // Claims
  Claims_Overview: {
    View: 'Claims_Overview_View',
    Edit: 'Claims_Overview_Edit',
  },
  Claims_Detail: {
    View: 'Claims_Detail_View',
    Edit: 'Claims_Detail_Edit',
  },
  // Invoices
  Invoices_Overview: {
    View: 'Invoices_Overview_View',
  },
  // Communications Messages
  Communications_Messages_Overview: {
    View: 'Communications_Messages_Overview_View',
  },
  // Communications Streams
  Communications_Streams_Overview: {
    View: 'Communications_Streams_Overview_View',
  },
  // Settings ClaimTemplate
  Settings_ClaimTemplate_Overview: {
    View: 'Settings_ClaimTemplate_Overview_View',
  },
  // Settings Insurers
  Settings_Insurers_Overview: {
    View: 'Settings_Insurers_Overview_View',
  },
  // Settings Brokers
  Settings_Brokers_Overview: {
    View: 'Settings_Brokers_Overview_View',
  },
  // Settings FamilyRelationships
  Settings_FamilyRelationships_Overview: {
    View: 'Settings_FamilyRelationships_Overview_View',
  },
  // Settings InsuranceProducts
  Settings_InsuranceProducts_Overview: {
    View: 'Settings_InsuranceProducts_Overview_View',
  },
  // Settings CountriesCurrenciesHolidays
  Settings_CountriesCurrenciesHolidays_Overview: {
    View: 'Settings_CountriesCurrenciesHolidays_Overview_View',
  },
  // Settings Hospitals
  Settings_Hospitals_Overview: {
    View: 'Settings_Hospitals_Overview_View',
  },
  // Settings ICD10
  Settings_ICD10_Overview: {
    View: 'Settings_ICD10_Overview_View',
  },
  // Settings MedicalProcedures
  Settings_MedicalProcedures_Overview: {
    View: 'Settings_MedicalProcedures_Overview_View',
  },
  // AmanShop Marketplace
  AmanShop_Marketplace_Overview: {
    View: 'AmanShop_Marketplace_Overview_View',
  },
  // AmanShop Merchants
  AmanShop_Merchants_Overview: {
    View: 'AmanShop_Merchants_Overview_View',
  },
  // AmanShop Orders
  AmanShop_Orders_Overview: {
    View: 'AmanShop_Orders_Overview_View',
  },
  // AmanShop Stores
  AmanShop_Stores_Overview: {
    View: 'AmanShop_Stores_Overview_View',
  },
  // AmanSale Dashboard
  AmanSale_Dashboard_Overview: {
    View: 'AmanSale_Dashboard_Overview_View',
  },
  // AmanSale Leads
  AmanSale_Leads_Overview: {
    View: 'AmanSale_Leads_Overview_View',
  },
  // AmanSale Exchange
  AmanSale_Exchange_Overview: {
    View: 'AmanSale_Exchange_Overview_View',
  },
  // Goldmine
  Goldmine_Overview: {
    View: 'Goldmine_Overview_View',
  },
};

export const PLATFORMS = [
  {
    id: 'web',
    value: 1,
  },
  {
    id: 'mobile',
    value: 2,
  },
];

export const USER_STATUSES = [
  {
    id: 'active',
    value: false,
  },
  {
    id: 'inactive',
    value: true,
  },
];

export const MEMBER_STATUSES = [
  { id: 'blank', value: '' },
  { id: 'open_enrollment', value: '-1' },
  { id: 'awaiting_activation', value: '0' },
  { id: 'activating', value: '1' },
  { id: 'active', value: '2' },
  { id: 'deactivated', value: '3' },
  { id: 'expired', value: '4' },
];

export const CONTACT_TYPE_ONBOARD = [
  {
    Id: 1,
    Name: 'HRM',
  },
  {
    Id: 2,
    Name: 'Finance',
  },
  {
    Id: 3,
    Name: 'Others',
  },
];

export const COMPANY_TYPE = {
  ALL: 0,
  INSURER: 1,
  BROKER: 2,
  EMPLOYER: 3,
};

export const POLICY_STATUS = {
  AwaitingOpenEnroll: -2,
  OpenEnroll: -1,
  Expired: 1,
  AwaitingActivation: 2,
  Active: 0,
};

export const SAAS_FREQUENCY = [
  { Id: 1, Name: 'Monthly' },
  { Id: 2, Name: 'Quarterly' },
  { Id: 3, Name: 'Annual' },
];

export const GetGroupParams = (names: string[]) => {
  return names.map((name) => [
    { selector: name, groupInterval: 'year', isExpanded: true },
    { selector: name, groupInterval: 'month', isExpanded: true },
    { selector: name, groupInterval: 'day', isExpanded: false },
  ]);
};

export const STORE_STATUS = [
  {
    id: 1,
    value: 'amanshop.stores.status_list.open',
    defaultName: 'Open',
    des: 'amanshop.stores.status_list_des.open',
    show_add_form: true,
  },
  {
    id: 2,
    value: 'amanshop.stores.status_list.close',
    defaultName: 'Close',
    des: 'amanshop.stores.status_list_des.close',
    show_add_form: false,
  },
  {
    id: 3,
    value: 'amanshop.stores.status_list.tempclose',
    defaultName: 'Temporary Close',
    des: 'amanshop.stores.status_list_des.tempclose',
    show_add_form: true,
  },
  {
    id: 4,
    value: 'amanshop.stores.status_list.permanentclose',
    defaultName: 'Permanently Close',
    des: 'amanshop.stores.status_list_des.permanentclose',
    show_add_form: true,
  },
  {
    id: 5,
    value: 'amanshop.stores.status_list.holidayclose',
    defaultName: 'Holiday Close',
    des: 'amanshop.stores.status_list_des.holidayclose',
    show_add_form: false,
  },
];

export const STORE_STATUS_TEXT = [
  { id: 1, value: 'amanshop.stores.status_list.open' },
  { id: 2, value: 'amanshop.stores.status_list.closed' },
  { id: 3, value: 'amanshop.stores.status_list.tempclosed' },
  { id: 4, value: 'amanshop.stores.status_list.permanentclosed' },
  { id: 5, value: 'amanshop.stores.status_list.holidayclosed' },
];

export const HOSPITAL_STATUS = [
  {
    id: 1,
    value: 'settings.hospitals.status_list.open',
    defaultName: 'Open',
    des: 'settings.hospitals.status_list_des.open',
    show_add_form: true,
  },
  {
    id: 2,
    value: 'settings.hospitals.status_list.close',
    defaultName: 'Close',
    des: 'settings.hospitals.status_list_des.close',
    show_add_form: false,
  },
  {
    id: 3,
    value: 'settings.hospitals.status_list.tempclose',
    defaultName: 'Temporary Close',
    des: 'settings.hospitals.status_list_des.tempclose',
    show_add_form: true,
  },
  {
    id: 4,
    value: 'settings.hospitals.status_list.permanentclose',
    defaultName: 'Permanently Close',
    des: 'settings.hospitals.status_list_des.permanentclose',
    show_add_form: true,
  },
  {
    id: 5,
    value: 'settings.hospitals.status_list.holidayclose',
    defaultName: 'Holiday Close',
    des: 'settings.hospitals.status_list_des.holidayclose',
    show_add_form: false,
  },
];

export const HOSPITAL_STATUS_TEXT = [
  { id: 1, value: 'settings.hospitals.status_list.open' },
  { id: 2, value: 'settings.hospitals.status_list.closed' },
  { id: 3, value: 'settings.hospitals.status_list.tempclosed' },
  { id: 4, value: 'settings.hospitals.status_list.permanentclosed' },
  { id: 5, value: 'settings.hospitals.status_list.holidayclosed' },
];

export const DAYOFWEEKS = [
  { day: 0, value: 'settings.hospitals.dayofweek.sunday' },
  { day: 1, value: 'settings.hospitals.dayofweek.monday' },
  { day: 2, value: 'settings.hospitals.dayofweek.tuesday' },
  { day: 3, value: 'settings.hospitals.dayofweek.wednesday' },
  { day: 4, value: 'settings.hospitals.dayofweek.thursday' },
  { day: 5, value: 'settings.hospitals.dayofweek.friday' },
  { day: 6, value: 'settings.hospitals.dayofweek.saturday' },
  { day: 7, value: 'settings.hospitals.dayofweek.emergency_dept' },
];

export const PERSONNEL_STATUS = [
  { id: 1, value: 'settings.hospitals.personnel_onduty' },
  { id: 2, value: 'settings.hospitals.personnel_offduty' },
];

export const STORE_PAYMENT_TYPE = [
  { id: 1, value: 'Cashless' },
  { id: 2, value: 'COD (Cash on Delivery)' },
];

export const PAYMENT_METHOD = [
  { id: 1, value: 'amanshop.payment.virtual_account' },
  { id: 2, value: 'amanshop.payment.card' },
  { id: 3, value: 'amanshop.payment.cash' },
];

export const PAYMENT_METHOD_NAME = {
  1: 'amanshop.payment.virtual_account',
  2: 'amanshop.payment.card',
  3: 'amanshop.payment.cash',
};

export const PAYMENT_METHOD_DESCRIPTION = {
  1: 'amanshop.payment.virtual_account_desc',
  2: 'amanshop.payment.card_desc',
  3: 'amanshop.payment.cash_desc',
};
