const typography = {
  h1: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: '-0.24px',
    color: '#454550',
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 500,
  },
  colors: {
    orange: '#FFA756',
    gray: '#C1C1C1',
    pink: '#F77',
    blue: '#1C81DF',
    green: '#33C630',
  },
  fontFamily: ['Poppins', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
};

export default typography;
