import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import { PREFIX } from './apiUrls';
import { IEmployeeChangeHistoryResponse } from './interface';

export const fetchEmployeeChangeHistoryByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/search`, { params: data });
};

export const fetchEmployeeChangeHistory = createAsyncThunk<IEmployeeChangeHistoryResponse, any, { rejectValue: string }>(
  'Employees/change-history-fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchEmployeeChangeHistoryByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const exportEmployeeChangeHistory = (data: any) => {
  return httpRequest.insurance.post(`${PREFIX}/extract`, data);
};
