import { Table } from '@devexpress/dx-react-grid-material-ui';
import { memo } from 'react';

const TableRow = ({ row, ...restProps }: any) => {
  if (row.IsAdminAdjustPercentage) {
    return (
      <Table.Row id={`row-${row.Id}`} {...restProps} className="MuiTableRow-custom" style={{ backgroundColor: '#ffd9d9' }} />
    );
  }
  return <Table.Row id={`row-${row.Id}`} {...restProps} className="MuiTableRow-custom" />;
};

export default memo(TableRow);
