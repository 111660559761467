import { forwardRef } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';

interface CustomNumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<NumberFormat<InputAttributes>, CustomNumberFormatProps>(function NumberFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      allowNegative={false}
      isNumericString
    />
  );
});

export default NumberFormatCustom;
